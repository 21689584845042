import React, { useState, useEffect } from "react";
import { Card, Grid, Icon, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom"; 
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import CustomPagination from "components/Pagination";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import DataTableProyect from "components/Tables/DataTableProyect"; 
import ModalNewProyecto from "./ModalNew"; 
import { useQuery, useMutation, gql } from "@apollo/client";
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const GET_PROYECTOS = gql`
  query proyectos($pagination: PaginationInput) {
    paginationInfo {
      pages
      total
    }
    proyectos(pagination: $pagination) {
      id
      nombre
      cliente{
        nombre
        apellido
      }
      fechaInicio
      fechaFinal
      monto
    }
  }
`;



function Proyectos() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [pagesInfo, setPagesInfo] = useState({ pages: 0, total: 0 });
  const [notification, setNotification] = useState({ open: false, message: '' });

  const { loading, data, error, refetch } = useQuery(GET_PROYECTOS, {
    variables: { pagination: { page, limit: 10 } },
    fetchPolicy: "network-only",
  });

  const columns = [
    { name: "nombre", desc: "Proyecto", align: "left" },
    {
      name: "cliente",
      desc: "Cliente",
      align: "left",
      render: (row) => {
        const { cliente } = row;
        return cliente 
          ? `${cliente.nombre} ${cliente.apellido || ''}` || "Sin Cliente"
          : "Sin Cliente";
      },
    },
    { name: "fechaInicio", desc: "Fecha de Inicio", align: "left" },
    { name: "fechaFinal", desc: "Fecha Final", align: "left" },
    { name: "monto", desc: "Monto", align: "left" },
    
  ];

  
  const handleAdd = () => {
    refetch({
      pagination: { page, limit: 10 },
    });
    setNotification({ open: true, message: 'Proyecto agregado correctamente' });
  };

  useEffect(() => {
    if (data) {
      setRows(data.proyectos);
      setPagesInfo(data.paginationInfo || { pages: 0, total: 0 });
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">Proyectos</SoftTypography>
                  <Tooltip title="Agregar nuevo proyecto" placement="top">
                    <SoftButton color="primary" onClick={() => setModalOpen(true)}>
                      <Icon>add</Icon>&nbsp;Agregar Proyecto
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox>
              {loading ? (
                <Loading />
              ) : error ? (
                <div>Error al cargar proyectos</div>
              ) : rows.length > 0 ? (
                <DataTableProyect columns={columns} rows={rows} refetch={refetch} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              total={pagesInfo.total}
              pages={pagesInfo.pages}
              page={page}
              setPage={setPage}
            />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
      <ModalNewProyecto open={modalOpen} onClose={() => setModalOpen(false)} onAdd={handleAdd} />
      <Snackbar
        open={notification.open}
        autoHideDuration={1000}
        onClose={() => setNotification({ ...notification, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={() => setNotification({ ...notification, open: false })} severity="success">
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Proyectos;
