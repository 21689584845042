// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { addDocumento, addIncidente, getModulos } from "layouts/soporte/querys";
import ModalDocumentacion from "../ModalDocumentacion";
import dayjs from "dayjs";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "50%" },
  overflow: "auto",
  py: 4,
};

export default function ModalAddIncidente({ open, handleClose }) {
  const { user} = useContext(UserContext);

  const [data, setData] = useState({
    idProyecto: "13",
    idSolicitante: 15,
    tarea: "",
    descripcionTarea: "",
    idPantalla: -1,
    prioridadSolicitanteUser: "Baja",
    estadoTarea: "Incidente",
    estadoTicketUser: "Nuevo",
    idUsuarioInterno: user?.id,
    nombreUsuarioInterno: user?.username,
    emailInterno: user?.email,
    fechaSprint: dayjs().format("YYYY-MM-DD"),
    dateAdd: dayjs().format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [modulos, setModulos] = useState([]);
  const [documentacion, setDocumentacion] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const [openDocumentacion, setOpenDocumentacion] = useState(false);

  const handleOpenDocumentacion = () => {
    setOpenDocumentacion(true);
  };

  const handleCloseDocumentacion = () => {
    setOpenDocumentacion(false);
  };

  const handleChanges = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const closeAndClean = () => {
    handleClose();
    setData({
      idProyecto: "13",
      idSolicitante: 15,
      tarea: "",
      descripcionTarea: "",
      idPantalla: -1,
      prioridadSolicitanteUser: "Baja",
      estadoTarea: "Incidente",
      estadoTicketUser: "Nuevo",
      idUsuarioInterno: user?.id,
      nombreUsuarioInterno: user?.empleado?.nombre + " " + user?.empleado?.apellido,
      emailInterno: user?.empleado?.emailInterno,
    });
    setDocumentacion([]);
  };

  const handleAddIncidente = async () => {
    setLoading(true);
    await addIncidente(data)
      .then((response) => {
        if (documentacion.length > 0) {
          documentacion.forEach((doc) => {
            addDocumento(doc, response.tarea.id);
          });
        }

        handleSnackbar(response.mensaje, response.estado ? "success" : "error");
        setLoading(false);
        closeAndClean();
      })
      .catch((error) => {
        handleSnackbar("Error al agregar el incidente, intente nuevamente más tarde", "error");
        setLoading(false);
      });
  };

  const filteredModulos = modulos.filter(modulo => 
    [" ","clientes", "proveedores", "usuarios"].includes(modulo.nombre.toLowerCase())
  );

  useEffect(() => {
    getModulos().then((response) => {
      setModulos(response);
    });
  }, []);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
              <SoftTypography variant="h6">Agregar incidente</SoftTypography>
              <Tooltip title="Cerrar" placement="top">
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </Tooltip>
            </SoftBox>
            <SoftBox px={3} py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre del incidente
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Inserte el nombre del incidente"
                    type="text"
                    name="tarea"
                    error={data.tarea.length > 250}
                    value={data.tarea}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="modulo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Módulo
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="modulo"
                    name="idPantalla"
                    sx={{ cursor: "pointer" }}
                    value={data.idPantalla}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione un módulo
                    </MenuItem>
                    {filteredModulos.map((modulo) => (
                      <MenuItem key={modulo.id} value={modulo.id}>
                        {modulo.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="prioridad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Prioridad
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="prioridad"
                    name="prioridadSolicitanteUser"
                    sx={{ cursor: "pointer" }}
                    value={data.prioridadSolicitanteUser}
                    onChange={handleChanges}
                  >
                    <MenuItem value={"Baja"}>Baja</MenuItem>
                    <MenuItem value={"Media"}>Media</MenuItem>
                    <MenuItem value={"Alta"}>Alta</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="descripcion">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Descripción
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="descripcion"
                    placeholder="Describa el incidente..."
                    type="text"
                    name="descripcionTarea"
                    value={data.descripcionTarea}
                    onChange={handleChanges}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
              alignItems="center"
              px={3}
            >
              <SoftBox>
              <SoftButton
                circular
                color="primary"
                fullWidth={{
                  xs: true,
                  sm: false,
                }}
                onClick={handleOpenDocumentacion}
              >
                <Icon>link</Icon>&nbsp;
                {documentacion?.length > 0 && documentacion.length < 2
                  ? `${documentacion.length} Documento`
                  : documentacion.length > 1
                  ? `${documentacion.length} Documentos`
                  : "Documentación"}
              </SoftButton>
              </SoftBox>
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    circular
                    color="primary"
                    onClick={closeAndClean}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox mt={{
                    xs: 2,
                    sm: 0,
                  }}>
                  <SoftButton
                    color="sistemasGris"
                    onClick={handleAddIncidente}
                    circular
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                    disabled={
                      !data.tarea ||
                      !data.idPantalla ||
                      data.idPantalla === -1 ||
                      !data.prioridadSolicitanteUser ||
                      !data.descripcionTarea ||
                      data.tarea.length > 250
                    }
                  >
                    {loading ? <CircularProgress size={17} color="inherit" /> : "Agregar"}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
      <ModalDocumentacion
        open={openDocumentacion}
        handleClose={handleCloseDocumentacion}
        item={documentacion}
        setItem={setDocumentacion}
      />
    </>
  );
}

ModalAddIncidente.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
