import React from 'react';
import PropTypes from 'prop-types'; 
import { Modal, Button } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const PdfModal = ({ open, handleClose, pdfUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <SoftBox
        p={2}
        sx={{
          backgroundColor: 'white',
          width: '90%',
          height: '90vh', 
          margin: 'auto',
          marginTop: '50px',
          overflow: 'hidden', 
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SoftTypography id="modal-title" variant="h6">Vista Previa del PDF</SoftTypography>
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%" 
          title="PDF Preview"
          style={{ border: 'none' }} 
        />
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </SoftBox>
    </Modal>
  );
};

PdfModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
};

export default PdfModal;
