import * as React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Modal,
  Fade,
  Card,
  Grid,
  InputLabel,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";

// Componentes de Kaizen Dashboard
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Contexto
import { useContext } from "react";
import { MessageManager } from "context";
import SoftInput from "components/SoftInput";
import { useState, useEffect } from "react";

const GET_ESPECIES = gql`
  query GetEspecies {
    especies(filter: { tipo: "ENDOP" }) {
      id
      nombre
      tipo
    }
  }
`;

const CREATE_ESPECIE = gql`
  mutation CreateEspecie($input: EspecieInput!) {
    createEspecie(input: $input) {
      id
      nombre
    }
  }
`;

const UPDATE_ESPECIE = gql`
  mutation UpdateEspecie($id: ID!, $nombre: String!) {
    updateEspecie(id: $id, nombre: $nombre, tipo: "ENDOP") {
      id
      nombre
    }
  }
`;

const DELETE_ESPECIE = gql`
  mutation DeleteEspecie($id: ID!) {
    deleteEspecie(id: $id) {
      id
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "50%", md: "40%" },
  py: 4,
};

const initialEspecie = {
  id: "",
  nombre: "",
};

export default function ModalNewEspecie({
  open,
  handleClose, // Esta función viene del componente principal
  handleCloseCancel,
  handleSelectEspecie,
}) {
  const { handleSnackbar } = useContext(MessageManager);
  const [especie, setEspecie] = useState(initialEspecie);
  const [cargando, setCargando] = useState(false);
  const [especieSeleccionada, setEspecieSeleccionada] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);

  // Consulta para obtener especies
  const { data, loading, error, refetch } = useQuery(GET_ESPECIES);

  const [createEspecie] = useMutation(CREATE_ESPECIE);
  const [updateEspecie] = useMutation(UPDATE_ESPECIE);
  const [deleteEspecie] = useMutation(DELETE_ESPECIE);

  // if (loading) return <CircularProgress />;
  // if (error) {
  //   handleSnackbar("Error al cargar las especies", "error");
  //   return null;
  // }

  const especies = data?.especies || [];

  // Validar si el nombre de la especie es válido
  useEffect(() => {
    setIsValid(especie.nombre.trim() !== "");
  }, [especie]);

  const handleSubmit = async () => {
    setCargando(true);
    setErrorMessage(""); // Reinicia el mensaje de error
    try {
      let result;
      if (especie.id) {
        // Editar especie
        result = await updateEspecie({
          variables: { id: especie.id, nombre: especie.nombre },
        });
        handleSnackbar("Especie actualizada correctamente", "success");
      } else {
        // Crear especie
        result = await createEspecie({
          variables: { input: { nombre: especie.nombre, tipo: "ENDOP" } },
        });
        handleSnackbar("Especie creada correctamente", "success");
      }

      // Si la creación o actualización fue exitosa, actualiza el formulario
      const createdEspecie = result?.data?.createEspecie || result?.data?.updateEspecie;
      if (createdEspecie) {
        handleSelectEspecie(createdEspecie); // Pasa la especie seleccionada al formulario
      }

      setEspecie(initialEspecie); // Limpiar el formulario
      handleClose(); // Cierra el modal
      // Refresca la lista de especies
      await refetch(); // Esto actualiza la lista de especies en el front-end
    } catch (error) {
      console.log(error);
      setErrorMessage("Hubo un error al guardar la especie. Intenta nuevamente.");
      handleSnackbar("Error al guardar la especie", "error");
    } finally {
      setCargando(false);
    }
  };

  const handleEspecieChange = (event) => {
    const selectedValue = event.target.value;
    const selectedEspecie = especies.find((esp) => esp.id === selectedValue);

    if (selectedEspecie) {
      setEspecie({ id: selectedEspecie.id, nombre: selectedEspecie.nombre });
      setEspecieSeleccionada(selectedEspecie.id);
    } else {
      setEspecie(initialEspecie);
      setEspecieSeleccionada("");
    }
  };

  const handleDeleteEspecie = async () => {
    if (!especieSeleccionada) {
      handleSnackbar("Selecciona una especie para eliminar", "warning");
      return;
    }

    const especieAEliminar = especies.find((e) => e.id === especieSeleccionada);

    // Verificar que la especie seleccionada es de tipo ENDOP
    if (especieAEliminar && especieAEliminar.tipo === "ENDOP") {
      setCargando(true);
      try {
        await deleteEspecie({ variables: { id: especieSeleccionada } });
        handleSnackbar("Especie eliminada correctamente", "success");
        setEspecieSeleccionada("");
        setEspecie(initialEspecie); // Reiniciar el formulario
        // Refresca la lista de especies
        await refetch(); // Esto actualiza la lista de especies después de la eliminación
      } catch (error) {
        console.log(error);
        handleSnackbar("Error al eliminar la especie", "error");
      } finally {
        setCargando(false);
      }
    } else {
      handleSnackbar("Solo se pueden eliminar especies de tipo ENDOP", "warning");
    }
  };

  // Aquí estamos creando una función de cierre interna para el modal
  const handleCloseCancelInternal = () => {
    setEspecie(initialEspecie); // Reinicia el formulario
    handleClose(); // Llama a la función que cierra el modal
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseCancelInternal} // Utilizamos la función interna
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3} display="flex" justifyContent="space-between">
            <SoftTypography variant="h6">Gestionar Especie</SoftTypography>
          </SoftBox>
          <Grid container spacing={2} px={2}>
            <Grid item xs={12} display="flex" alignItems="center">
              <InputLabel htmlFor="nombre" sx={{ marginRight: 2 }}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Agregar
                </SoftTypography>
              </InputLabel>
              <SoftInput
                label="Nombre"
                name="nombre"
                value={especie.nombre}
                onChange={(e) => setEspecie({ ...especie, nombre: e.target.value })}
                type="text"
                sx={{ flex: 1, marginLeft: 2, marginRight: 2, maxWidth: 550 }}
              />
              <SoftButton
                variant="gradient"
                color="primary"
                onClick={handleSubmit}
                sx={{ minWidth: 120 }}
                disabled={!isValid || cargando}
              >
                {!cargando ? "GUARDAR" : <CircularProgress sx={{ margin: "0 15px" }} size={18} color="white" />}
              </SoftButton>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <InputLabel htmlFor="especieSeleccionada" sx={{ marginRight: 2 }}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Eliminar
                </SoftTypography>
              </InputLabel>
              <Select
                value={especieSeleccionada}
                onChange={handleEspecieChange}
                displayEmpty
                fullWidth
                sx={{ flex: 1, marginLeft: 2, marginRight: 2, maxWidth: 550 }}
              >
                <MenuItem value="" disabled>
                  Selecciona una Especie
                </MenuItem>
                {especies.map((esp) => (
                  <MenuItem key={esp.id} value={esp.id}>
                    {esp.nombre}
                  </MenuItem>
                ))}
              </Select>
              <SoftButton variant="gradient" color="error" onClick={handleDeleteEspecie} sx={{ minWidth: 120 }}>
                Eliminar
              </SoftButton>
            </Grid>
          </Grid>

          {errorMessage && (
            <SoftTypography color="error" sx={{ padding: 2, textAlign: "center" }}>
              {errorMessage}
            </SoftTypography>
          )}

          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={2} pt={3}>
            <SoftBox>
              <SoftButton variant="gradient" color="dark" onClick={handleCloseCancelInternal} sx={{ minWidth: 120 }}>
                Cancelar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNewEspecie.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  handleSelectEspecie: PropTypes.func.isRequired,
};
