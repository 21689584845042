import {
  Backdrop,
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { useCallback } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import Loading from "components/Carteles/Loading";
import { formatDate } from "utils/formatters";
import SoftInputNumber from "components/SoftInputNumberUpgrade";

const Row = ({ index, data, handleChange, handleDelete, dataProps, setPropsErrors }) => {
  const [selectedProp, setSelectedProp] = useState(null);
  const [error, setError] = useState({
    valid: true,
    msg: null,
  });
  useEffect(() => {
    let prop = dataProps?.find((item) => item.name === data.name);
    let validation = () => ({ valid: true, message: null });
    if (prop?.validation) {
      validation = eval(prop.validation);
    }
    setSelectedProp({
      ...prop,
      validation: validation,
    });
    if (prop?.defaultValue && !data.value) {
      handleChange(index, "value", prop.defaultValue);
    }
  }, [data.name]);

  useEffect(() => {
    if (dataProps) {
      let error = selectedProp?.validation(data.value) || { valid: true, msg: null };
      error.error = !error.valid;

      if (selectedProp?.required) {
        error.valid = false;
      }

      setError(error);
      if (!error.valid) {
        setPropsErrors((prev) => {
          if (prev.includes(index)) return prev;
          return [...prev, index];
        });
      } else {
        setPropsErrors((prev) => {
          if (!prev.includes(index)) return prev;
          return prev.filter((e) => e != index);
        });
      }
    }
  }, [data.value]);

  return (
    <Grid
      item
      container
      p={1}
      px={0}
      xs={12}
      borderBottom="solid 1px #e9ecef"
      sx={{ "&:hover": { backgroundColor: "#f2f6fa" } }}
    >
      <Grid item xs={5} pr={1}>
        <Select
          variant="outlined"
          name="name"
          value={data.name || ""}
          disabled={selectedProp?.required}
          sx={{
            ".MuiInputBase-input.Mui-disabled": {
              color: "#495057 !important",
              "-webkit-text-fill-color": "#495057 !important",
            },
          }}
          onChange={(e) => handleChange(index, "name", e.target.value)}
        >
          {dataProps?.map((item, index) => (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6} px={1}>
        {selectedProp?.inputType === "select" ? (
          <Select
            variant="outlined"
            value={data.value || ""}
            onChange={(e) => {
              handleChange(index, "value", e.target.value);
            }}
          >
            {selectedProp?.inputProps?.options?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Tooltip title={error?.msg || ""} open={true}>
            <SoftInput
              type={selectedProp?.inputType || "text"}
              value={data.value || ""}
              inputProps={selectedProp?.inputProps || {}}
              placeholder={
                (selectedProp?.name || "Valor") + (selectedProp?.required ? " (Requerido)" : "")
              }
              error={(data.value && !error?.valid) || false}
              onChange={(e) => {
                handleChange(index, "value", e.target.value);
              }}
            />
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={1} pr={2}>
        {!selectedProp?.required && (
          <SoftBox textAlign="right">
            <Icon
              color="error"
              sx={{ cursor: "pointer", verticalAlign: "middle" }}
              fontSize="medium"
              onClick={() => handleDelete(index)}
            >
              delete
            </Icon>
          </SoftBox>
        )}
      </Grid>
    </Grid>
  );
};

Row.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  setPropsErrors: PropTypes.func,
  dataProps: PropTypes.array,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", md: "60%", lg: "50%" },
  py: 4,
  px: 4,
  overflow: "auto",
};

export default function ModalProducto({
  open,
  handleClose,
  refetch,
  id,
  idSucursal,
  tipo,
  onCreate,
}) {
  const { handleSnackbar } = useContext(MessageManager);
  const [data, setCurrentData] = useState({});
  const [dataToSave, setDataToSave] = useState({});
  const setData = (newData) => {
    setCurrentData(newData);
    setDataToSave(newData);
  };
  const [errors, setErrors] = useState({});
  const [openPropiedades, setOpenPropiedades] = useState(false);

  const [dataProps, setDataProps] = useState([]);
  const [propsErrors, setPropsErrors] = useState([]);
  const get = (arr, prop) => (arr || []).find((p) => p.prop == prop)?.value;
  const { data: dataCategorias } = useQuery(gql`
    query CategoriasProducto {
      categoriasProducto {
        tipoProducto
        nombre
      }
    }
  `);
  const [getDataPropiedades] = useLazyQuery(
    gql`
      query Propiedades($type: String) {
        props(type: $type) {
          id
          type
          name
          description
          recomendList
          inputType
          defaultValue
          required
          inputProps
          validation
        }
      }
    `,
    {
      onCompleted: (res) => {
        if (!res) return;
        setDataProps(res.props);
        let defaultProps = res.props.filter(
          (p) =>
            (p.required || p.recomendList?.includes(data?.categoria)) &&
            !data?.propiedades?.some((prop) => prop.name == p.name)
        );

        if (defaultProps.length > 0) {
          let newProps = data?.propiedades?.slice() || [];
          defaultProps.forEach((prop) => {
            newProps.push({ name: prop.name, value: prop.defaultValue || "" });
          });
          setData((prev) => ({ ...prev, propiedades: newProps }));
        }
      },
    }
  );

  let tipos = ["Material", "Consumible", "Recurso"];
  let icons = ["category", "recycling", "layers"];
  let colors = ["sistemasAmarillo", "sistemasGris", "sistemasAmarillo"];

  const [getData, { loading }] = useLazyQuery(
    gql`
      query getProducto($id: ID!, $idSucursal: ID) {
        productos(filter: { id: $id }) {
          id
          codigo
          tipo
          nombre
          disabled
          categoria
          descripcion
          precio
          propiedades {
            name
            value
          }
          stock(idSucursal: $idSucursal) {
            pasillo
            fila
            columna
            cantidad
            stockMinimo
          }
          updatedAt
          updatedBy {
            username
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      cachePolicy: "no-cache",
      onCompleted: (data) => {
        if (!data?.productos[0]) return null;
        let producto = data?.productos[0];
        producto = JSON.stringify(producto);
        producto = producto.replace(/"__typename":"[a-zA-Z]+",?/g, "");
        producto = JSON.parse(producto);
        setCurrentData(producto);
      },
    }
  );

  const [getLastID] = useLazyQuery(gql`
    query getLastID {
      productos(order: { field: "id", order: DESC }, pagination: { limit: 1, page: 1}) {
        id
      }
    }
  `)

  const [mutate, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveProducto($input: ProductoInput!) {
        saveProducto(input: $input) {
          id
        }
      }
    `,
    {
      onCompleted: (data) => {
        refetch();
        if (onCreate) {
          handleClose();
          onCreate(data.saveProducto.id);
        }
        handleSnackbar("Producto guardado correctamente", "success");
        if (!id) {
          if (tipo) setData({ tipo });
          setErrors({
            codigo: {
              message: "requerido",
              color: "secondary",
            },
            nombre: {
              message: "requerido",
              color: "secondary",
            },
          });
          document.querySelector("input[name='codigo']").focus();
        }
      },
    }
  );

  const recomendarCodigo = async () => {
    let newCodigo = null;
    if(id){
      newCodigo = `P${id}`
      setData((prev) => ({ ...prev, codigo: newCodigo }));
    }else{
      let lastID = await getLastID().then((res) => res.data.productos?.[0]?.id);
      lastID = parseInt(lastID || 0);
      newCodigo = `P${lastID + 1}`;
      setData((prev) => ({ ...prev, codigo: newCodigo }));
    }
    validate("codigo", newCodigo);
  }

  useEffect(() => {
    if (open) {
      if (id) {
        getData({ variables: { id, idSucursal } });
      } else {
        if (tipo) setData({ tipo });
        setErrors({
          nombre: {
            message: "requerido",
            color: "secondary",
          },
        });
        recomendarCodigo();
      }
    } else {
      setData({});
      setErrors({});
    }
  }, [id, open, tipo]);

  useEffect(() => {
    if (data) {
      getDataPropiedades({ variables: { type: data.tipo } });
    }
  }, [data?.tipo]);

  const validate = (name, value) => {
    let error = null;
    switch (name) {
      case "codigo":
        if (!value) {
          error = {
            message: "requerido",
            color: "secondary",
          };
        }
        break;
      case "nombre":
        if (!value) {
          error = {
            message: "requerido",
            color: "secondary",
          };
        }
        break;
      case "stockMinimo":
        if (value < 0 || value > 1000) {
          error = {
            message: "El valor debe estar entre 0 y 1000",
            color: "error",
          };
        }
        break;
      default:
        break;
    }
    if (error) {
      setErrors((prev) => ({ ...prev, [name]: error }));
    } else if (errors[name]) {
      let err = { ...errors };
      delete err[name];
      setErrors(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    validate(name, value);
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeStock = (e) => {
    let { name, value, type } = e.target;
    if (type == "number") value = parseInt(value) || null;
    validate(name, value);
    setData((prev) => ({ ...prev, stock: { ...prev.stock, [name]: value } }));
  };

  const handleSave = () => {
    let input = { ...dataToSave };
    if (id) {
      input.id = id;
    }
    if (input.stock && idSucursal) {
      input.stock.idSucursal = idSucursal;
    }
    mutate({ variables: { input: input } });
  };

  useEffect(() => {
    if (data?.categoria) {
      let recomendedProps = dataProps.filter(
        (p) =>
          p.recomendList?.includes(data?.categoria) &&
          !data?.propiedades?.some((prop) => prop.name == p.name)
      );

      if (recomendedProps.length > 0) {
        let newProps = data?.propiedades?.slice() || [];
        recomendedProps.forEach((prop) => {
          newProps.push({ name: prop.name, value: prop.defaultValue || "" });
        });
        setData((prev) => ({ ...prev, propiedades: newProps }));
      }
    }
  }, [data?.categoria]);

  useEffect(() => {
    if (data.propiedades) {
      let errors = [];
      data.propiedades.forEach((item, index) => {
        if (!item.name && !errors.includes(index)) {
          errors.push(index);
        }
      });
      setPropsErrors(errors);
    }
  }, [data?.propiedades]);

  const handleAdd = () => {
    const newProps = data?.propiedades?.slice() || [];
    newProps.push({});
    setData((prev) => ({ ...prev, propiedades: newProps }));
  };

  const handleDelete = (index) => {
    const newProps = data?.propiedades?.slice() || [];
    newProps.splice(index, 1);
    setPropsErrors((prev) => prev.filter((e) => e != index));

    setData((prev) => ({ ...prev, propiedades: newProps }));
  };

  const handleChangeProp = (index, field, value) => {
    const newProps = data?.propiedades.slice();
    if (newProps[index][field] == value) return;
    newProps[index][field] = value;
    if (field == "name") newProps[index].value = null;
    setData((prev) => ({ ...prev, propiedades: newProps }));
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (
              !(
                Object.keys(dataToSave).length < 1 ||
                Object.keys(errors).length > 0 ||
                propsErrors.length > 0 ||
                loadingSave
              )
            ) {
              handleSave();
            }
          }
        }}
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox display="flex" gap={1}>
                <Icon sx={{ color: getUproColor(colors[tipos.indexOf(tipo)]) }} fontSize="medium">
                  {icons[tipos.indexOf(tipo)] || "layers"}
                </Icon>
                <SoftTypography variant="h6" fontWeight="bold">
                  {id ? "Editar" : "Nuevo"} {tipo} {id ? `Nº${id}` : ""}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" sx={{
              zIndex: 1,
            }}>
                <SoftTypography component="label" variant="caption" color="secondary">
                  (*) Campos obligatorios
                </SoftTypography>
                {data?.updatedAt && (
                  <Tooltip title={formatDate(data?.updatedAt, "dd/MM/yyyy a las hh:mm")}>
                    <SoftTypography
                      component="label"
                      variant="caption"
                      color="secondary"
                      sx={{ cursor: "pointer" }}
                    >
                      Modificado {formatDate(data?.updatedAt, "XXXX")} por{" "}
                      {data?.updatedBy?.username || "un administrador"}
                    </SoftTypography>
                  </Tooltip>
                )}
              </SoftBox>
            <SoftBox>
              {loading ? (
                <Loading />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="nombre">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Nombre
                          </SoftTypography>
                          <SoftTypography
                            pl={0.5}
                            component="label"
                            variant="caption"
                            color="secondary"
                          >
                            (*)
                          </SoftTypography>
                        </InputLabel>
                        <SoftInput
                          name="nombre"
                          value={data?.nombre || ""}
                          onChange={handleChange}
                          placeholder="Nombre"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                          <InputLabel htmlFor="codigo">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Código
                            </SoftTypography>
                            <SoftTypography
                              pl={0.5}
                              component="label"
                              variant="caption"
                              color="secondary"
                            >
                              (*)
                            </SoftTypography>
                          </InputLabel>
                          {open && !data?.codigo ? (
                            <Tooltip title="Recomendar código" placement="top">
                              <Icon 
                                sx={{cursor: "pointer"}}
                                color="primary"
                                fontSize="small"
                                onClick={recomendarCodigo}
                              >auto_awesome</Icon>
                            </Tooltip>
                          ): null}
                        </SoftBox>
                        <SoftInput
                          name="codigo"
                          value={data?.codigo || ""}
                          onChange={handleChange}
                          placeholder="Código"
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="precio">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Precio
                          </SoftTypography>
                        </InputLabel>
                        <SoftInputNumber
                          placeholder="Precio"
                          name="precio"
                          value={data?.precio || ""}
                          error={data?.precio < 0}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="categoria">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Rubro
                          </SoftTypography>
                        </InputLabel>
                        <Select
                          variant="outlined"
                          name="categoria"
                          value={data?.categoria || ""}
                          onChange={handleChange}
                        >
                          {dataCategorias?.categoriasProducto
                            ?.filter((c) => c.tipoProducto == tipo || c.tipoProducto == null)
                            .map((item, index) => (
                              <MenuItem key={index} value={item.nombre}>
                                {item.nombre}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="descripcion">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Descripción
                          </SoftTypography>
                        </InputLabel>
                        <SoftInput
                          name="descripcion"
                          value={data?.descripcion || ""}
                          onChange={handleChange}
                          multiline
                          rows={3}
                          placeholder="Descripción"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox height="100%" bgColor="grey-100" borderRadius="lg" p={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Icon fontSize="small">warehouse</Icon>&nbsp;&nbsp;En esta sede
                      </SoftTypography>
                      <SoftBox>
                        <SoftTypography variant="caption" color="secondary">
                          Ubicación en el pañol
                        </SoftTypography>
                      </SoftBox>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                          <InputLabel htmlFor="pasillo">
                            <SoftTypography component="label" variant="caption">
                              Pasillo
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            name="pasillo"
                            value={data?.stock?.pasillo || ""}
                            onChange={handleChangeStock}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputLabel htmlFor="fila">
                            <SoftTypography component="label" variant="caption">
                              Fila
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            name="fila"
                            value={data?.stock?.fila || ""}
                            onChange={handleChangeStock}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <InputLabel htmlFor="columna">
                            <SoftTypography component="label" variant="caption">
                              Columna
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            name="columna"
                            value={data?.stock?.columna || ""}
                            onChange={handleChangeStock}
                          />
                        </Grid>
                      </Grid>
                      <SoftBox pt={1}>
                        <SoftTypography variant="caption" color="secondary">
                          Stock en el pañol
                        </SoftTypography>
                      </SoftBox>
                      <Grid container columnSpacing={1}>
                        <Grid item xs={6}>
                          <InputLabel htmlFor="stock">
                            <SoftTypography component="label" variant="caption">
                              Unidades
                            </SoftTypography>
                          </InputLabel>
                          <SoftBox>
                            <SoftBox display="flex" alignItems="center" gap={1}>
                              <SoftTypography variant="h4" fontWeight="bold">
                                {data?.stock?.cantidad || 0}
                              </SoftTypography>
                              <SoftTypography variant="caption">en stock</SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel htmlFor="stockMinimo">
                            <SoftTypography component="label" variant="caption">
                              Mínimo
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            type="number"
                            name="stockMinimo"
                            value={data?.stock?.stockMinimo || ""}
                            inputProps={{ min: 0, max: 1000 }}
                            onChange={handleChangeStock}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Grid>
                </Grid>
              )}
            </SoftBox>
            <SoftBox>
              <SoftBox display="flex" alignItems="center" mt={2}>
                <Icon>{openPropiedades ? "keyboard_arrow_down" : "keyboard_arrow_right"}</Icon>
                <SoftTypography
                  component="label"
                  variant="button"
                  fontWeight="bold"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                  onClick={() => setOpenPropiedades((prev) => !prev)}
                >
                  Propiedades
                </SoftTypography>
              </SoftBox>
              <Collapse in={openPropiedades} timeout="auto" unmountOnExit>
                <Grid item container>
                  <Grid item container xs={12} borderBottom="solid 1px #e9ecef" py={1}>
                    <Grid item xs={5} px={1}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        color="secondary"
                        textTransform="uppercase"
                      >
                        Propiedad
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={6} px={1}>
                      <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        color="secondary"
                        textTransform="uppercase"
                      >
                        Valor
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={1} textAlign="right" px={2}></Grid>
                  </Grid>
                  <Grid item container xs={12} sx={{ maxHeight: 57 * 4, overflowY: "scroll" }}>
                    {dataProps?.length
                      ? data?.propiedades?.map((item, index) => (
                          <Row
                            key={index}
                            index={index}
                            data={item}
                            handleChange={handleChangeProp}
                            handleDelete={handleDelete}
                            setPropsErrors={setPropsErrors}
                            dataProps={dataProps.filter(
                              (d) =>
                                !data?.propiedades.some((p, i) => d.name == p.name && i != index)
                            )}
                          />
                        ))
                      : null}
                  </Grid>
                  {dataProps?.length ? (
                    <Grid
                      item
                      xs={12}
                      sx={{ "&:hover": { backgroundColor: "#f2f6fa", cursor: "pointer" } }}
                    >
                      <SoftBox display="flex" alignItems="center" p={2} onClick={handleAdd}>
                        <Icon fontSize="small">add</Icon>
                        <SoftTypography variant="button" color="dark" fontWeight="regular">
                          &nbsp;Agregar
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <SoftBox display="flex" alignItems="center" p={2}>
                        <Icon fontSize="small">search_off</Icon>
                        <SoftTypography variant="button" color="dark" fontWeight="regular">
                          &nbsp;No hay propiedades disponibles para este nodo
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  )}
                </Grid>
              </Collapse>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                md: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
              mt={2}
            >
              <SoftBox>
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    md: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  md: 0,
                }}
              >
                <SoftButton
                  color="sistemasGris"
                  circular
                  disabled={
                    loadingSave ||
                    Object.keys(dataToSave).length < 1 ||
                    Object.keys(errors).length > 0 ||
                    propsErrors.length > 0 ||
                    loadingSave
                  }
                  fullWidth={{
                    xs: true,
                    md: false,
                  }}
                  onClick={handleSave}
                >
                  {loadingSave ? (
                    <>
                      <CircularProgress size={15} color="white" />
                      &nbsp;
                    </>
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalProducto.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  id: PropTypes.string,
  idSucursal: PropTypes.string,
  tipo: PropTypes.oneOf(["Material", "Consumible", "Recurso"]),
  onCreate: PropTypes.func,
};
