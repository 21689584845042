// React
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SinResultados from "components/Carteles/SinResultados";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import ModalConfirmation from "./ModalConfirmarImportacion";
import TableItem from "./TableItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "stock", desc: "stock actual", align: "left" },
  { name: "cantidad", desc: "nuevo stock", align: "center", width: "200px" },
  { name: "eliminar", desc: " ", align: "left", width: "1%", noOrder: true },
];

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidad", desc: "Movimiento", align: "center", width: "1%" },
];

const GET_PROVEEDORES = gql`
  query getProveedores {
    proveedores {
      id
      tipoProveedor
      razonSocial
      nombre
      apellido
      tipoDocumento
      documento
      tipoResponsable
    }
  }
`;

export default function ModalAjustes({
  open,
  handleClose,
  selectedAjuste,
  idSucursal,
  refetchProductos,
  selectedMovimiento,
}) {
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [productoToAdd, setProductoToAdd] = useState();
  const [sucursales, setSucursales] = useState([]);
  const [remito, setRemito] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [productosRemito, setProductosRemito] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);

  const { data: proveedores } = useQuery(GET_PROVEEDORES);
  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON, $idSucursal: ID) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          nombre
          categoria
          disabled
          precio
          stock(idSucursal: $idSucursal) {
            cantidad
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  const [saveRemito, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const { data: sucursalesData } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `
  );

  const handleChanges = (event) => {
    setRemito({ ...remito, [event.target.name]: event.target.value });
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _or: [
              {
                id: { _like: `%${event.target.value}%` },
              },
              {
                nombre: { _like: `%${event.target.value}%` },
              },
              {
                categoria: { _like: `%${event.target.value}%` },
              },
            ],
            _stock:
              selectedAjuste === "Merma" || selectedAjuste === "Devolucion Proveedor"
                ? {
                    cantidad: { _gt: 0 },
                    idSucursal: remito?.idSucursal,
                  }
                : undefined,
          },
          idSucursal: remito?.idSucursal,
        },
      });
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleUpdateProductoRemito = (material) => {
    setProductosRemito(
      productosRemito.map((item) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const handleAddProduct = (product) => {
    setProductosRemito([...productosRemito, product]);
  };

  const handleRemoveProduct = (product) => {
    setProductosRemito(productosRemito.filter((item) => item.id !== product.id));
  };

  const renderColumnsBase = columnsBase.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsBase.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const handleSave = () => {
    saveRemito({
      variables: {
        input:
          selectedAjuste === "Ajuste"
            ? {
                tipo: remito.tipoAjuste,
                observaciones: remito.observaciones,
                idSucursalOrigen: remito.idSucursal,
                idSucursalDestino: remito.idSucursal,
                movimientoDetalle: productosRemito.map((item) => ({
                  idProducto: item.id,
                  cantidad: item?.cantidad - (item?.stock?.cantidad || 0),
                  idSucursal: remito?.idSucursal,
                })),
              }
            : selectedAjuste === "Merma"
            ? {
                tipo: "Merma",
                observaciones: remito.observaciones,
                idSucursalOrigen: remito.idSucursal,
                movimientoDetalle: productosRemito.map((item) => ({
                  idProducto: item.id,
                  cantidad: item?.cantidad - (item?.stock?.cantidad || 0),
                  idSucursal: remito?.idSucursal,
                })),
              }
            : {
                tipo: "Devolucion Proveedor",
                observaciones: remito.observaciones,
                idSucursalOrigen: remito.idSucursal,
                idProveedor: remito.proveedor,
                movimientoDetalle: productosRemito.map((item) => ({
                  idProducto: item.id,
                  cantidad: item?.cantidad - (item?.stock?.cantidad || 0),
                  idSucursal: remito?.idSucursal,
                })),
              },
      },
    })
      .then(() => {
        handleSnackbar("El ajuste se ha realizado correctamente", "success");
        setOpenConfirmModal(false);
        handleClose();
        refetchProductos();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al realizar el ajuste ", "error");
      });
  };

  useEffect(() => {
    setRemito({
      idSucursal: idSucursal,
    });
    setProductosRemito([]);
    if (selectedMovimiento && open) {
      let productosCargados = selectedMovimiento.movimientoDetalles.map((item) => ({
        ...item.producto,
        cantidad: item.cantidad,
        stock: { cantidad: item.cantidad },
      }));

      setProductosRemito(productosCargados);
    }
  }, [open]);

  useEffect(() => {
    setProductosRemito([]);
  }, [remito?.idSucursal]);

  useEffect(() => {
    if (productoToAdd && productos?.productos?.length > 0) {
      if (productos.productos.find((producto) => producto.id === productoToAdd)) {
        handleAddProduct(productos.productos.find((producto) => producto.id === productoToAdd));
      }
      setProductoToAdd();
    }
  }, [productos]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      const thisSede = sucursalesData.sucursales.find((sucursal) => sucursal.id === idSucursal);
      setSucursales(
        sucursalesData.sucursales.filter((sucursal) => sucursal.sede.id === thisSede.sede.id)
      );
    }
  }, [sucursalesData, idSucursal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftBox>
                    <SoftTypography variant="h6">
                      {selectedMovimiento?.tipo || selectedAjuste}
                    </SoftTypography>
                    {selectedMovimiento && (
                      <SoftTypography component="h6" variant="caption" fontWeight="bold">
                        <SoftBox display="flex">
                          <Icon>person</Icon>
                          &nbsp;
                          {`Creado por ${
                            selectedMovimiento?.user?.nombre
                              ? `${selectedMovimiento?.user?.nombre} ${selectedMovimiento?.user?.apellido}`
                              : selectedMovimiento?.user?.username
                          }`}
                        </SoftBox>
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  {selectedAjuste === "Ajuste" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="tipoAjuste">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Tipo de Ajuste
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento.tipo}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="tipoAjuste"
                            value={remito?.tipoAjuste || ""}
                            onChange={handleChanges}
                          >
                            <MenuItem value={"Ajuste - Stock Inicial"}>Stock Inicial</MenuItem>
                            <MenuItem value={"Ajuste - Diferencia Física"}>
                              Diferencia Física
                            </MenuItem>
                          </Select>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="idSucursal">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Sucursal
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento?.sucursalOrigen?.nombre +
                              " - " +
                              selectedMovimiento?.sucursalOrigen?.sede?.nombre}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="idSucursal"
                            value={remito?.idSucursal || ""}
                            onChange={handleChanges}
                          >
                            {sucursales.map((sucursal) => (
                              <MenuItem key={sucursal.id} value={sucursal.id}>
                                {sucursal.nombre + " - " + sucursal.sede.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="observaciones">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Observaciones
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento.observaciones}
                          </SoftTypography>
                        ) : (
                          <SoftInput
                            label="observaciones"
                            placeholder="Inserte un comentario"
                            type="text"
                            name="observaciones"
                            value={remito?.observaciones || ""}
                            onChange={handleChanges}
                            multiline
                            rows={4}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {selectedAjuste === "Merma" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="idSucursal">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Sucursal
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento?.sucursalOrigen?.nombre +
                              " - " +
                              selectedMovimiento?.sucursalOrigen?.sede?.nombre}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="idSucursal"
                            value={remito?.idSucursal || ""}
                            onChange={handleChanges}
                          >
                            {sucursales.map((sucursal) => (
                              <MenuItem key={sucursal.id} value={sucursal.id}>
                                {sucursal.nombre + " - " + sucursal.sede.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="observaciones">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Observaciones
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento.observaciones}
                          </SoftTypography>
                        ) : (
                          <SoftInput
                            label="observaciones"
                            placeholder="Inserte un comentario"
                            type="text"
                            name="observaciones"
                            value={remito?.observaciones || ""}
                            onChange={handleChanges}
                            multiline
                            rows={4}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {selectedAjuste === "Devolucion Proveedor" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="proveedor">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Proveedor
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento.proveedor.razonSocial ||
                              selectedMovimiento.proveedor.nombre +
                                " " +
                                selectedMovimiento.proveedor.apellido}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="proveedor"
                            value={remito?.proveedor || ""}
                            onChange={handleChanges}
                          >
                            {proveedores?.proveedores.map((proveedor) => (
                              <MenuItem key={proveedor.id} value={proveedor.id}>
                                {proveedor.razonSocial ||
                                  proveedor.nombre + " " + proveedor.apellido}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="idSucursal">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Sucursal
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento?.sucursalOrigen?.nombre +
                              " - " +
                              selectedMovimiento?.sucursalOrigen?.sede?.nombre}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="idSucursal"
                            value={remito?.idSucursal || ""}
                            onChange={handleChanges}
                          >
                            {sucursales.map((sucursal) => (
                              <MenuItem key={sucursal.id} value={sucursal.id}>
                                {sucursal.nombre + " - " + sucursal.sede.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="observaciones">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Observaciones
                            {!selectedMovimiento && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {selectedMovimiento ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedMovimiento.observaciones}
                          </SoftTypography>
                        ) : (
                          <SoftInput
                            label="observaciones"
                            placeholder="Inserte un comentario"
                            type="text"
                            name="observaciones"
                            value={remito?.observaciones || ""}
                            onChange={handleChanges}
                            multiline
                            rows={4}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                  {!selectedMovimiento && (
                    <SoftBox display="flex" justifyContent="end" alignItems="flex-end">
                      <SoftBox
                        sx={{
                          width: "400px",
                        }}
                      >
                        <SoftInput
                          placeholder="Busca aquí materiales..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                          onKeyPress={() => {
                            setOpenSelectionMenu(true);
                          }}
                          onClick={() => {
                            getProductos({
                              variables: {
                                page: 1,
                                filter: {
                                  disabled: false,
                                  _stock:
                                    selectedAjuste === "Merma" ||
                                    selectedAjuste === "Devolucion Proveedor"
                                      ? {
                                          cantidad: { _gt: 0 },
                                          idSucursal: remito?.idSucursal,
                                        }
                                      : undefined,
                                },
                                idSucursal: remito?.idSucursal,
                              },
                            });
                            setOpenSelectionMenu(true);
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (e.relatedTarget?.tagName !== "LI") {
                              setOpenSelectionMenu(false);
                            } else {
                              e.target.focus();
                            }
                          }}
                        />
                        {openSelectionMenu && (
                          <SoftBox sx={{ position: "fixed", zIndex: 99 }}>
                            <Card>
                              <SoftBox
                                p={
                                  !loading &&
                                  productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0
                                    ? 3
                                    : 0
                                }
                                sx={{
                                  width: "400px",
                                  maxHeight: "30vh",
                                  overflowY: "auto",
                                }}
                              >
                                {openSelectionMenu && loading ? (
                                  <Loading />
                                ) : productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0 &&
                                  openSelectionMenu ? (
                                  productos?.productos
                                    .filter(
                                      (producto) =>
                                        !productosRemito.find((item) => item.id === producto.id)
                                    )
                                    .map((producto) => (
                                      <MenuItem
                                        key={producto.id}
                                        onClick={() => {
                                          handleAddProduct(producto);
                                          setOpenSelectionMenu(false);
                                        }}
                                      >
                                        <SoftBox mr={2}>
                                          <Icon
                                            sx={{
                                              color:
                                                producto.tipo === "Material"
                                                  ? getUproColor("sistemasAmarillo")
                                                  : producto.tipo === "Consumible"
                                                  ? getUproColor("sistemasGris")
                                                  : getUproColor("sistemasAmarillo"),
                                            }}
                                            fontSize="small"
                                          >
                                            {producto.tipo === "Material"
                                              ? "category"
                                              : producto.tipo === "Consumible"
                                              ? "recycling"
                                              : "layers"}
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox>
                                          &nbsp;{`${producto.id} - ${producto.nombre}`}
                                        </SoftBox>
                                      </MenuItem>
                                    ))
                                ) : (
                                  <SinResultados />
                                )}
                              </SoftBox>
                            </Card>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </SoftBox>
                  )}
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRemito.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          {selectedMovimiento ? (
                            <TableRow>{renderColumnsSelect}</TableRow>
                          ) : (
                            <TableRow>{renderColumnsBase}</TableRow>
                          )}
                        </SoftBox>
                        <TableBody>
                          {productosRemito.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                handleUpdateMaterial={handleUpdateProductoRemito}
                                handleRemoveMaterial={() => handleRemoveProduct(material)}
                                tipoAjuste={selectedAjuste}
                                disabled={selectedMovimiento ? true : false}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              {!selectedMovimiento && (
                <Card sx={{ marginTop: 2 }}>
                  <SoftBox display="flex" justifyContent="end" alignItems="center" p={2}>
                    <SoftBox mr={2}>
                      <SoftButton color="primary" circular onClick={handleClose}>
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                      </SoftButton>
                    </SoftBox>
                    <SoftBox>
                      <SoftButton
                        color="sistemasGris"
                        circular
                        disabled={
                          loadingSave ||
                          (selectedAjuste === "Ajuste" &&
                            (!remito?.tipoAjuste ||
                              !remito?.observaciones ||
                              productosRemito.length === 0)) ||
                          ((selectedAjuste === "Merma" ||
                            selectedAjuste === "Devolucion Proveedor") &&
                            (!remito?.observaciones || productosRemito.length === 0)) ||
                          productosRemito.length === 0 ||
                          (selectedAjuste === "Merma" || selectedAjuste === "Devolucion Proveedor"
                            ? productosRemito.some(
                                (item) =>
                                  item.cantidad === null ||
                                  (item?.stock?.cantidad &&
                                    item.cantidad >= item?.stock?.cantidad) ||
                                  item?.cantidad?.toString().includes(".")
                              )
                            : productosRemito.some(
                                (item) =>
                                  item.cantidad === null ||
                                  (item?.stock?.cantidad &&
                                    item.cantidad == item?.stock?.cantidad) ||
                                  item?.cantidad?.toString().includes(".")
                              ))
                        }
                        onClick={() => setOpenConfirmModal(true)}
                      >
                        {loadingSave ? (
                          <CircularProgress size={15} color="white" />
                        ) : (
                          <Icon sx={{ fontWeight: "light" }}>save</Icon>
                        )}
                        &nbsp;Guardar
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
            </SoftBox>
            <ModalConfirmation
              open={openConfirmModal}
              handleClose={() => setOpenConfirmModal(false)}
              handleSave={handleSave}
              list={productosRemito}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAjustes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedAjuste: PropTypes.string,
  selectedMovimiento: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
