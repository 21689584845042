/* eslint-disable react/prop-types */
import { Divider, Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleOpenMenu) => {
  let tipos = ["Material", "Consumible", "Recurso"];
  let icons = ["category", "recycling", "layers"];
  let colors = ["sistemasAmarillo", "sistemasGris", "sistemasAmarillo"];
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: item?.disabled
                    ? getUproColor()
                    : getUproColor(colors[tipos.indexOf(item?.tipo)]),
                }}
                fontSize="lg"
              >
                {icons[tipos.indexOf(item?.tipo)] || "layers"}
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip
                title={item?.disabled ? "Recurso deshabilitado" : item?.tipo}
                placement="top"
              >
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: item?.disabled
                      ? getUproColor()
                      : getUproColor(colors[tipos.indexOf(item?.tipo)]),
                  }}
                  fontSize="lg"
                >
                  {icons[tipos.indexOf(item?.tipo)] || "layers"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        // codigo: (
        //   <SoftBox
        //     sx={{
        //       cursor: "pointer",
        //     }}
        //     data={item?.codigo}
        //     onClick={(e) => handleOpenMenu(e, item)}
        //   >
        //     <SoftTypography
        //       verticalAlign="text-top"
        //       variant="caption"
        //       color="secondary"
        //       fontWeight="medium"
        //     >
        //       {item?.codigo}
        //     </SoftTypography>
        //   </SoftBox>
        // ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.nombre}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nombre.length > 70
                ? makeNiceText(item?.nombre).slice(0, 70) + "..."
                : makeNiceText(item?.nombre)}
              {item?.disabled && (
                <Tooltip title={item?.motivoBaja}>
                  <SoftTypography
                    verticalAlign="text-top"
                    variant="caption"
                    color="error"
                    fontWeight="medium"
                    sx={{ cursor: "pointer" }}
                  >
                    &nbsp; (Deshabilitado -{" "}
                    {item?.motivoBaja?.length > 50
                      ? item?.motivoBaja.slice(0, 50) + "..."
                      : item?.motivoBaja}
                    )
                  </SoftTypography>
                </Tooltip>
              )}
            </SoftTypography>
          </SoftBox>
        ),
        categoria: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.categoria}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.categoria}
            </SoftTypography>
          </SoftBox>
        ),
        pasillo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.stock?.pasillo}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.stock?.pasillo}
            </SoftTypography>
          </SoftBox>
        ),
        fila: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.stock?.fila}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.stock?.fila}
            </SoftTypography>
          </SoftBox>
        ),
        columna: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.stock?.columna}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.stock?.columna}
            </SoftTypography>
          </SoftBox>
        ),
        stock: (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  p: 1,
                  bgcolor: 'common.white',
                  '& .MuiTooltip-arrow': {
                    color: 'common.white',
                  },
                  boxShadow: 5,
                },
              },
            }}
            title={(
              <>
                <SoftBox display="flex" justifyContent="space-between" gap={2}>
                  <SoftBox display="flex" flexDirection="column" textAlign="left">
                    <SoftTypography variant="caption">Disponible:</SoftTypography>
                    {item?.stock?.enPrestamo > 0 && (
                      <SoftTypography variant="caption">En préstamo:</SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column" textAlign="right">
                    <SoftTypography variant="caption">{formatMoneyPunto(item?.stock?.cantidad || 0)}</SoftTypography>
                    {item?.stock?.enPrestamo > 0 && (
                      <SoftTypography variant="caption">{formatMoneyPunto(item?.stock?.enPrestamo || 0)}</SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
                <Divider sx={{m:1}}/>
                <SoftBox display="flex" justifyContent="space-between" gap={2}>
                  <SoftBox display="flex" flexDirection="column" textAlign="left">
                    <SoftTypography variant="caption">Total:</SoftTypography>
                    {item?.stock?.stockMinimo > 0 && (
                      <SoftTypography variant="caption">Mínimo:</SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column" textAlign="right">
                    <SoftTypography variant="caption" fontWeight="bold">{formatMoneyPunto(item?.stock?.cantidad + item?.stock?.enPrestamo || 0)}</SoftTypography>
                    {item?.stock?.stockMinimo > 0 && (
                      <SoftTypography variant="caption" sx={
                        (item?.stock?.cantidad + item?.stock?.enPrestamo) <= item?.stock?.stockMinimo ? {
                          color: "red",
                          fontWeight: "bold"
                        }:{}
                      }>({formatMoneyPunto(item?.stock?.stockMinimo || 0)})</SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              </>
            )}
          >
            <SoftBox
              sx={{
                cursor: "pointer",
              }}
              data={item?.stock?.cantidad}
              onClick={(e) => handleOpenMenu(e, item)}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SoftTypography variant="caption" color="dark" fontWeight="medium">
                {item?.stock?.enPrestamo > 0 ? (<>
                  {formatMoneyPunto(item?.stock?.cantidad || 0)}
                  &nbsp;
                  ({formatMoneyPunto(item?.stock?.cantidad + item?.stock?.enPrestamo || 0)})
                </>): (
                  formatMoneyPunto(item?.stock?.cantidad || 0)
                )}
              </SoftTypography>
              {item?.stock?.stockMinimo && (item?.stock?.cantidad + item?.stock?.enPrestamo) <= item?.stock?.stockMinimo && (
                <>
                  &nbsp;&nbsp;
                    <Icon fontSize="small" color="error">
                      error_outline
                    </Icon>
                </>
              )}
            </SoftBox>
          </Tooltip>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            data={(e) => handleOpenMenu(e, item)}
            onClick={(e) => handleOpenMenu(e, item)}
            display="flex"
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "id", width: "2%", align: "left" },
  // { name: "codigo", desc: "Código", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  //{ name: "estado", desc: "Estado", align: "left" },
  //{ name: "areaEncargada", desc: "Área encargada", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "pasillo", desc: "pasillo", align: "center", width: "1%", noOrder: true },
  { name: "fila", desc: "fila", align: "center", width: "1%", noOrder: true },
  { name: "columna", desc: "columna", align: "center", width: "1%", noOrder: true },
  { name: "stock", desc: "Stock", align: "center", noOrder: true },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
