import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom'; 
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import ModalDelete from "components/Modals/Delete";
import { Card, Grid, Icon, Menu, MenuItem } from "@mui/material";
import Loading from "components/Carteles/Loading";
import DataTableFrc from "components/Tables/DataTableFrc";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { MessageManager } from "context";
import { UserContext } from "context/user";

const GET_FORMULARIOS = gql`
  query getFrcForm($proyectoId: ID!, $search: String, $page: Int) {
    paginationInfo {
      pages
      total
    }
    frcForms(proyectoId: $proyectoId, search: $search, pagination: { page: $page, limit: 10 }) {
      id
      proyectoId
      profesional
      contacto
      eog_fechaInicio
      eog_fechaAlta
    }
  }
`;

const GET_PROYECTO = gql`
  query getProyecto($id: ID!) {
    proyectos(id: $id) {
      id
      nombre
    }
  }
`;

const DELETE_FORMULARIO = gql`
  mutation deleteFrcFormFile($id: ID!) {
    deleteFormulario(id: $id) {
      id
    }
  }
`;

function FormulariosFRC() {
  const { proyectoId } = useParams(); 
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFormulario, setSelectedFormulario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({ pages: 0, total: 0 });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedFormulario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteFormulario] = useMutation(DELETE_FORMULARIO);

  const { loading: loadingProyecto, data: proyectoData } = useQuery(GET_PROYECTO, {
    variables: { id: proyectoId },
  });
  
  const { loading, data, refetch } = useQuery(GET_FORMULARIOS, {
    variables: {
      proyectoId, 
      search: search || null,
      page: page,
    },
    fetchPolicy: "network-only",
  });console.log('Proyecto ID:', proyectoId);


  const handleDeleteFormulario = async (id) => {
    try {
      await deleteFormulario({ variables: { id } });
      refetch();
      handleSnackbar("Formulario eliminado correctamente", "success");
    } catch (error) {
      handleSnackbar("Error al eliminar formulario", "error");
    }
  };

  useEffect(() => {
    if (data && data.frcForms) {
      console.log('Datos de formularios:', data.frcForms); 
      setRows(data.frcForms.map(row => ({
        id: row.id,
        profesional: row.profesional || "",
        contacto: row.contacto || "",
        eog_fechaInicio: row.eog_fechaInicio || "",
        eog_fechaAlta: row.eog_fechaAlta || "",
      })));
      setPagesInfo(data.paginationInfo);
    } else {
      setRows([]);
      setPagesInfo({ pages: 0, total: 0 });
    }
  }, [data]);

  const handleAddClick = () => {
    navigate('/form-frc');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftTypography variant="h6">
                  FRC - Proyecto ID: {proyectoId} {loadingProyecto ? 'Cargando...' : proyectoData?.proyectos?.nombre}
                    </SoftTypography>
                  
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox>
              {loading ? (
                <Loading />
              ) : rows.length > 0 ? (
                <DataTableFrc
                  columns={[
                    { id: "id", label: "ID" },
                    { id: "profesional", label: "Profesional" },
                    { id: "contacto", label: "Contacto" },
                    { id: "eog_fechaInicio", label: "Fecha de Inicio" },
                    { id: "eog_fechaAlta", label: "Fecha de Alta" },
                  ]}
                  rows={rows}
                />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
            />
          </Card>
          <Menu anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
            <MenuItem
              onClick={() => {
                setOpenModalDelete(true);
                handleCloseMenu();
              }}
            >
              Eliminar
            </MenuItem>
          </Menu>
          <ModalDelete
            open={openModalDelete}
            handleClose={() => setOpenModalDelete(false)}
            nombre={`el formulario con código ${selectedFormulario?.id}`} 
            functionDelete={() => {
              handleDeleteFormulario(selectedFormulario.id);
              setOpenModalDelete(false);
            }}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FormulariosFRC;
