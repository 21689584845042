import React, { useRef } from "react"; // Asegúrate de incluir useRef aquí
import jsPDF from "jspdf";
import { Button, Container, Typography } from "@mui/material";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import Footer from "components/Footer";

import logo from "./logo_Invet.png";

// const ContractPDF = () => {
//   const generatePDF = () => {
//     const doc = new jsPDF();

//     // Función para agregar encabezado
//     const addHeader = (doc) => {
//       doc.setFontSize(14);
//       doc.text("Veterinaria [Nombre]", 20, 10);
//       doc.setFontSize(10);
//       doc.text("Contrato de Obra", 20, 16);
//       doc.line(20, 18, 190, 18); // línea debajo del encabezado
//     };

//     // Función para agregar pie de página
//     const addFooter = (doc, pageNumber) => {
//       doc.setFontSize(10);
//       doc.line(20, 280, 190, 280); // línea encima del pie de página
//       doc.text(`Página ${pageNumber}`, 180, 285);
//       doc.text("Veterinaria [Nombre] - Todos los derechos reservados", 20, 285);
//     };

//     // Variables de posición y páginas
//     let pageNumber = 1;
//     let yOffset = 40; // posición inicial para el contenido centrado

//     // Llamar a la función del encabezado
//     addHeader(doc);

//     // Texto centrado
//     doc.setFontSize(16); // Tamaño de fuente más grande
//     const centeredText = [
//       "CONTRATO DE OBRA",
//       "ENTRE",
//       "LA VETERINARIA [………………………………….……………………]",
//       "Y",
//       "LA EMPRESA AAA S.R.L",
//       "PROTOCOLO [……………………………………..]"
//     ];
    
//     centeredText.forEach((text) => {
//       const textWidth = doc.getTextWidth(text);
//       const centeredX = (doc.internal.pageSize.width - textWidth) / 2;
//       doc.text(text, centeredX, yOffset);
//       yOffset += 10; // Incremento de espacio entre líneas
//     });

//     yOffset += 20; // Espacio después del bloque centrado

//     // Contenido del contrato
//     doc.setFontSize(10);
//     const contractText = `Ciudad de [……………………………………….] – Provincia de […………......................], a los
// [………] días del mes de [……………………..] de 20[…….].
// Por una parte, La Veterinaria [………………………………………………………………] (en adelante LA
// VETERINARIA), CUIT [……………………………], con domicilio en
// [……………………………………………………], representada en éste acto por su Propietario/a,
// [……………………….………..…..]; DNI […………………..……];
// Y por otra parte, <b>AAAA S.R.L.<b> (en adelante LA EMPRESA), CUIT […………………………], 
// con domicilio en […………………………………..………………………], representado en este acto por el
// Dr./ Dra. [………………………………………………………], DNI [………………………………]`;

//     // Dividir el texto en líneas para evitar que sobrepase el margen inferior
//     const lines = doc.splitTextToSize(contractText, 175); // Ajusta el ancho del párrafo

//     // Agregar cada línea y manejar la paginación
//     lines.forEach((line) => {
//       if (yOffset > 270) { // Cuando se supera el límite de página
//         addFooter(doc, pageNumber); // Añadir pie de página antes de cambiar de página
//         doc.addPage();
//         pageNumber++;
//         addHeader(doc); // Agregar encabezado en nueva página
//         yOffset = 30; // Restablecer el offset para el contenido de la nueva página
//       }
//       doc.text(line, 20, yOffset);
//       yOffset += 10; // Incrementar la posición para la siguiente línea
//     });

//     const contractText2 = `EXPONEN 
//     - Que LA VETERINARIA es un espacio organizado económicamente y que desarrolla
//     actividades de clínica veterinaria de animales de compañía, interesada en colaborar con la
//     ciencia; 
//     - Que LA EMPRESA brinda servicios de investigación por contrato para para el registro de
//     medicamentos veterinarios, demandados por empresas farmacéuticas;\n
//     - Que tanto EL ESTABLECIMIENTO como LA EMPRESA están de acuerdo en la participación
//     conjunta de proyectos tanto de ámbito nacional como internacional.

// Teniendo en cuenta lo anteriormente expuesto, CONVIENEN estrechar sus relaciones, aunar
// esfuerzos y establecer normas amplias y específicas de actuación que encaucen e incrementen,
// dentro de un marco preestablecido, los contactos y colaboraciones.

// A tal fin, se redacta el siguiente acuerdo de colaboración según las siguientes CLAUSULAS:

// PRIMERA: Finalidad.
// LA EMPRESA contrata a LA VETERINARIA con el objeto de ejecutar un proyecto de investigación
// clínica veterinaria correspondiente al protocolo […………………………………………]. La formulación
// en estudio pertenece al laboratorio patrocinante [……….…………………………………..], quien delega
// la ejecución del estudio en LA EMPRESA.
// La investigación consiste en un estudio de [………………………………………………………………………],
// de una formulación de prueba a base de
// […………………………………………………………………………………………….…], en la especie
// [………………………………………………………]. El proyecto de Investigación Clínica Veterinaria tiene
// asociado un plan de trabajo que detalla el diseño, las distintas fases a desarrollar y la cronología,
// como se indica en el protocolo adjunto.

// SEGUNDA: Precio.
// LA EMPRESA pagará a LA VETERINARIA, el valor de [………]GaVet por animal primario reclutado
// (Colegio de Med. Vet. 1° circunscripción), equivalente al canon según el precio del GaVet al
// momento de terminar el estudio completo y con toda la información requerida para ese animal,
// conforme al protocolo experimental. En el caso de animales que, por causa ajena a la voluntad
// del Veterinario, no pudieran terminar el estudio, se abonará a LA VETERINARIA, el valor
// prorrateado de los tiempos consecutivos cumplidos del estudio, con toda la información requerida
// para ese animal. Se proveerá, además, del total de materiales y recursos que sean necesarios
// para cumplimentar con el citado programa. Aquellos que no lo incluyan deberán ser aclarados
// por escrito.

// TERCERA: Responsables.
// Por parte de la empresa patrocinante [………………………………………………….………………], se
// designa al Dr./Dra[.………………….………………………………….] como Investigador/a principal y 
// contacto a cualquier efecto, siendo su correo electrónico [……………………………………………]y su
// teléfono [………………..………………].
// Por parte de LA VETERINARIA se designa al Dr./Dra. [……………………………………………………….];
// siendo sus datos de contacto
// de:……………………………………………………………………………………………………
// Mail:……………………………………………………teléfono:………………………………………………………

// CUARTA: Duración.
// El presente Acuerdo Marco entrará en vigor en el momento de su firma y su vigencia será por el
// tiempo que dure la etapa clínica en LA VETERINARIA.

// QUINTA: Terminación y rescisión.
// El presente convenio puede ser rescindido sin expresión de causa por cualquiera de las partes
// mediante un aviso previo, por medio fehaciente, realizado con una anticipación no menor a los
// treinta (30) días de la fecha en que se pretende su terminación.
// La terminación comunicada en tiempo y forma conforme lo dispuesto en la presente cláusula no
// dará derecho a reclamar indemnización de ninguna naturaleza a ninguna de las partes.
// En caso de existir trabajos en curso de ejecución las partes se comprometen a mantener en
// vigencia el convenio específico hasta la finalización de las actividades previstas. En caso de no
// ser posible por causas verificables, se tratará de llegar a un acuerdo de finalización anticipada de
// proyectos.
// LA EMPRESA se compromete a pagar por los servicios contratados a LA VETERINARIA, conforme
// a lo establecido en el apartado “PRECIO” al momento de rescindir el acuerdo, en contraprestación
// a lo ya realizado.

// SEXTA: Controversias.
// La VETERINARIA y LA EMPRESA se comprometen a resolver de manera amistosa cualquier
// desacuerdo que pueda surgir en el desarrollo del presente contrato.
// En caso de conflicto, se acuerda el sometimiento de ambos a los tribunales federales de la ciudad
// de locación de la VETERINARIA y las cámaras de mediación dispuestas a tales efectos.

// SEPTIMA: Confidencialidad.
// Las partes reconocen que toda la documentación e información relacionada y generada como
// consecuencia de este acuerdo de colaboración como así también toda la información que se
// intercambie por el hecho o en ocasión del presente, la que se obtenga durante la ejecución de
// proyectos y el resultado del mismo (en adelante LA INFORMACION CONFIDENCIAL) es de
// exclusiva propiedad de las partes y se considerará confidencial. En tal sentido, se comprometen
// a:
//     7.1. No usar LA INFORMACION CONFIDENCIAL para otro propósito o fin diferente a la realización
//     del proyecto.
//     7.2. No utilizar LA INFORMACION CONFIDENCIAL para cualquier otro fin sin el previo
//     consentimiento escrito de la otra parte.
//     7.3. Mantener en estricto secreto LA INFORMACION CONFIDENCIAL y restringir el acceso a LA
//     INFORMACION sólo a aquellas personas a su cargo y por ellos designadas para realizar el
//     proyecto, siendo exclusivos responsables de garantizar la confidencialidad de LA
//     INFORMACION CONFIDENCIAL por parte de dichas personas.
// Quedarán exceptuados de la obligación de confidencialidad respecto de LA INFORMACION
// CONFIDENCIAL sólo en los siguientes supuestos:
//     a) Se encuentre en el dominio público al tiempo de la celebración del presente acuerdo;
//     b) Sea publicado o se torne de dominio público por causas no imputables a las partes;
//     c) Esté en poder de algunas de las partes al tiempo de la celebración del presente acuerdo;
//     d) Medie requerimiento judicial o de organismo público competente.
// Las partes reconocen expresamente que las funciones que desarrollarán en relación con las
// obligaciones consignadas en el presente acuerdo, los pondrán en contacto con los secretos
// industriales, técnicos, operacionales y comerciales de cada una de ellas, así como de aquellos
// pertenecientes a sus socios, asociados, accionistas, filiales, subsidiarias, controladoras, clientes,
// contratistas y cualquiera otra persona relacionada, además del resto de información privilegiada
// de sus operaciones. Por lo anterior, serán considerados “secretos”, incluyendo, de manera
// enunciativa más no limitativa, todos aquellos conocimientos industriales, técnicos, comerciales y
// operacionales, conceptos creativos, propuestas, estrategias de comunicación y publicidad,
// marcas, diseños, modelos, base de datos, listas de precios, registros, datos, materiales, planes y
// proyectos de comercialización y ventas, publicidad e información de cualquier tipo relacionada
// con los negocios y operaciones de las empresas y/o personas relacionadas directa o
// indirectamente con ésta.
// La obligación de confidencialidad asumida en este apartado es a perpetuidad. El incumplimiento
// de la obligación de confidencialidad, hará responsable a la parte divulgadora de responder
// integralmente por los daños y perjuicios sufridos por la otra parte como consecuencia de cualquier
// acto o hecho violatorio de tal obligación.
// En prueba de conformidad, firman por duplicado el presente acuerdo.`;

//     const lines2 = doc.splitTextToSize(contractText2, 175);
//     lines2.forEach((line) => {
//       if (yOffset > 270) {
//         addFooter(doc, pageNumber);
//         doc.addPage();
//         pageNumber++;
//         addHeader(doc);
//         yOffset = 30;
//       }
//       doc.text(line, 20, yOffset);
//       yOffset += 10;
//     });

//     // Añadir pie de página en la última página
//     addFooter(doc, pageNumber);

//     // Descargar el PDF
//     // doc.save("Contrato_de_Obra.pdf");

//     // abrir en nueva pestaña
//     doc.output('dataurlnewwindow');
//   };

//   return (
    
//     // <div>
//     //   <button onClick={generatePDF}>Generar Contrato PDF</button>
//     // </div>

//     <DashboardLayout>
//       <DashboardNavbar/>
//       <SoftBox py={3}>
//         <Container>
//           <Typography variant="h4" gutterBottom>
//             Contrato de Prestación de Servicios
//           </Typography>
//           <Button onClick={generatePDF} variant="contained" color="primary">
//             Generar Contrato PDF
//           </Button>
//         </Container>
//       </SoftBox>
//       <Footer/>
//     </DashboardLayout>
//   );
// };

// const ContractPDF = () => {
//   const pdfRef = useRef();


//   const generatePDF = () => {
//     const doc = new jsPDF("p", "pt", "a4");
    
//     const pageWidth = doc.internal.pageSize.getWidth();
//     const pageHeight = doc.internal.pageSize.getHeight();
//     // Código para generar el PDF...
//     // doc.save("Contrato_de_Obra.pdf");
//     // abrir en nueva pestaña
//     doc.output('dataurlnewwindow');
//   };

//   // Función para agregar encabezado y pie de página
//   const addHeaderAndFooter = (doc, pageNumber) => {
//     const header = "ENCABEZADO DEL CONTRATO"; // Cambia esto por el texto que quieras en el encabezado
//     const footer = "PIE DE PÁGINA - Página " + pageNumber; // Cambia esto por el texto que quieras en el pie de página

//     doc.setFontSize(12); // Tamaño de fuente del encabezado
//     doc.text(header, 10, 10); // Posición del encabezado (x, y)

//     // Pie de página
//     doc.text(footer, 10, doc.internal.pageSize.getHeight() - 10); // Posición del pie de página (x, y)
//   };

//   return (

//     <div>
//       <div ref={pdfRef} 
//       style={{
//         width: "595px", // Equivalente al ancho A4 en puntos (210 mm)
//         padding: "20px",
//         margin: "0 auto",
//         border: "1px solid #ddd",
//         fontFamily: "Arial, sans-serif"
//       }}>
//         <h2 style={{ textAlign: "center", fontSize: "18px" }}><strong>CONTRATO DE OBRA</strong></h2>
//         <p style={{ textAlign: "center", fontSize: "18px" }} >Entre LA VETERINARIA  [………………………………….……………………] y LA EMPRESA AAA S.R.L</p>
//         <p style={{ textAlign: "center", fontSize: "18px" }} >PROTOCOLO [……………………………………..]</p>
//         <br />
//         <p style={{ textAlign: "left", fontSize: "12px" }} >Ciudad de [……………………………………….] – Provincia de […………………......................], a los
//           [………] días del mes de [……………………..] de 20[…….].
//           Por una parte, La Veterinaria [………………………………………………………………] (en adelante LA
//           VETERINARIA), CUIT [……………………………], con domicilio en
//           [……………………………………………………], representada en éste acto por su Propietario/a,
//           [……………………….………..…..]; DNI […………………..……];
//           Y por otra parte, ALIANZA INVET S.R.L. (en adelante LA EMPRESA), CUIT […………………………],
//           con domicilio en […………………………………..………………………], representado en este acto por el
//           Dr./ Dra. [………………………………………………………], DNI [………………………………]
//         </p>

//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />EXPONEN</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >- Que LA VETERINARIA es un espacio organizado económicamente y que desarrolla
//           actividades de clínica veterinaria de animales de compañía, interesada en colaborar con la
//           ciencia;<br />
//           - Que LA EMPRESA brinda servicios de investigación por contrato para para el registro de
//           medicamentos veterinarios, demandados por empresas farmacéuticas;<br />
//           - Que tanto EL ESTABLECIMIENTO como LA EMPRESA están de acuerdo en la participación
//           conjunta de proyectos tanto de ámbito nacional como internacional.<br />
//           </p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >Teniendo en cuenta lo anteriormente expuesto, CONVIENEN estrechar sus relaciones, aunar
//           esfuerzos y establecer normas amplias y específicas de actuación que encaucen e incrementen,
//           dentro de un marco preestablecido, los contactos y colaboraciones.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >A tal fin, se redacta el siguiente acuerdo de colaboración según las siguientes CLAUSULAS:</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />PRIMERA: Finalidad.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >LA EMPRESA contrata a LA VETERINARIA con el objeto de ejecutar un proyecto de investigación
//           clínica veterinaria correspondiente al protocolo […………………………………………]. La formulación
//           en estudio pertenece al laboratorio patrocinante [……….…………………………………..], quien delega
//           la ejecución del estudio en LA EMPRESA.
//           La investigación consiste en un estudio de [………………………………………………………………………],
//           de una formulación de prueba a base de
//           […………………………………………………………………………………………….…], en la especie
//           [………………………………………………………]. El proyecto de Investigación Clínica Veterinaria tiene
//           asociado un plan de trabajo que detalla el diseño, las distintas fases a desarrollar y la cronología,
//           como se indica en el protocolo adjunto.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />SEGUNDA: Precio.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >LA EMPRESA pagará a LA VETERINARIA, el valor de [………]GaVet por animal primario reclutado
//           (Colegio de Med. Vet. 1° circunscripción), equivalente al canon según el precio del GaVet al
//           momento de terminar el estudio completo y con toda la información requerida para ese animal,
//           conforme al protocolo experimental. En el caso de animales que, por causa ajena a la voluntad
//           del Veterinario, no pudieran terminar el estudio, se abonará a LA VETERINARIA, el valor
//           prorrateado de los tiempos consecutivos cumplidos del estudio, con toda la información requerida
//           para ese animal. Se proveerá, además, del total de materiales y recursos que sean necesarios
//           para cumplimentar con el citado programa. Aquellos que no lo incluyan deberán ser aclarados
//           por escrito.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />TERCERA: Responsables.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >Por parte de la empresa patrocinante [………………………………………………….………………], se
//           designa al Dr./Dra[.………………….………………………………….] como Investigador/a principal y
//           contacto a cualquier efecto, siendo su correo electrónico [……………………………………………]y su
//           teléfono [………………..………………].
//           Por parte de LA VETERINARIA se designa al Dr./Dra. [……………………………………………………….];
//           siendo sus datos de contacto
//           de:……………………………………………………………………………………………………
//           Mail:……………………………………………………teléfono:………………………………………………………</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />CUARTA: Duración.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >El presente Acuerdo Marco entrará en vigor en el momento de su firma y su vigencia será por el
//           tiempo que dure la etapa clínica en LA VETERINARIA.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />QUINTA: Terminación y rescisión.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >El presente convenio puede ser rescindido sin expresión de causa por cualquiera de las partes
//           mediante un aviso previo, por medio fehaciente, realizado con una anticipación no menor a los
//           treinta (30) días de la fecha en que se pretende su terminación.
//           La terminación comunicada en tiempo y forma conforme lo dispuesto en la presente cláusula no
//           dará derecho a reclamar indemnización de ninguna naturaleza a ninguna de las partes.
//           En caso de existir trabajos en curso de ejecución las partes se comprometen a mantener en
//           vigencia el convenio específico hasta la finalización de las actividades previstas. En caso de no
//           ser posible por causas verificables, se tratará de llegar a un acuerdo de finalización anticipada de
//           proyectos.
//           LA EMPRESA se compromete a pagar por los servicios contratados a LA VETERINARIA, conforme
//           a lo establecido en el apartado “PRECIO” al momento de rescindir el acuerdo, en contraprestación
//           a lo ya realizado.</p>

//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />SEXTO: Controversias.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >La VETERINARIA y LA EMPRESA se comprometen a resolver de manera amistosa cualquier
//           desacuerdo que pueda surgir en el desarrollo del presente contrato.
//           En caso de conflicto, se acuerda el sometimiento de ambos a los tribunales federales de la ciudad
//           de locación de la VETERINARIA y las cámaras de mediación dispuestas a tales efectos.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} ><br />SÉPTIMA: Confidencialidad.</p>
//         <p style={{ textAlign: "left", fontSize: "12px" }} >Las partes reconocen que toda la documentación e información relacionada y generada como
//           consecuencia de este acuerdo de colaboración como así también toda la información que se
//           intercambie por el hecho o en ocasión del presente, la que se obtenga durante la ejecución de
//           proyectos y el resultado del mismo (en adelante LA INFORMACION CONFIDENCIAL) es de
//           exclusiva propiedad de las partes y se considerará confidencial. En tal sentido, se comprometen
//           a:
//           7.1. No usar LA INFORMACION CONFIDENCIAL para otro propósito o fin diferente a la realización
//           del proyecto.
//           7.2. No utilizar LA INFORMACION CONFIDENCIAL para cualquier otro fin sin el previo
//           consentimiento escrito de la otra parte.
//           7.3. Mantener en estricto secreto LA INFORMACION CONFIDENCIAL y restringir el acceso a LA
//           INFORMACION sólo a aquellas personas a su cargo y por ellos designadas para realizar el
//           proyecto, siendo exclusivos responsables de garantizar la confidencialidad de LA
//           INFORMACION CONFIDENCIAL por parte de dichas personas.
//           Quedarán exceptuados de la obligación de confidencialidad respecto de LA INFORMACION
//           CONFIDENCIAL sólo en los siguientes supuestos:
//           a) Se encuentre en el dominio público al tiempo de la celebración del presente acuerdo;
//           b) Sea publicado o se torne de dominio público por causas no imputables a las partes;
//           c) Esté en poder de algunas de las partes al tiempo de la celebración del presente acuerdo;
//           d) Medie requerimiento judicial o de organismo público competente.
//           Las partes reconocen expresamente que las funciones que desarrollarán en relación con las
//           obligaciones consignadas en el presente acuerdo, los pondrán en contacto con los secretos
//           industriales, técnicos, operacionales y comerciales de cada una de ellas, así como de aquellos
//           pertenecientes a sus socios, asociados, accionistas, filiales, subsidiarias, controladoras, clientes,
//           contratistas y cualquiera otra persona relacionada, además del resto de información privilegiada
//           de sus operaciones. Por lo anterior, serán considerados “secretos”, incluyendo, de manera
//           enunciativa más no limitativa, todos aquellos conocimientos industriales, técnicos, comerciales y
//           operacionales, conceptos creativos, propuestas, estrategias de comunicación y publicidad,
//           marcas, diseños, modelos, base de datos, listas de precios, registros, datos, materiales, planes y
//           proyectos de comercialización y ventas, publicidad e información de cualquier tipo relacionada
//           con los negocios y operaciones de las empresas y/o personas relacionadas directa o
//           indirectamente con ésta.
//           La obligación de confidencialidad asumida en este apartado es a perpetuidad. El incumplimiento
//           de la obligación de confidencialidad, hará responsable a la parte divulgadora de responder
//           integralmente por los daños y perjuicios sufridos por la otra parte como consecuencia de cualquier
//           acto o hecho violatorio de tal obligación.
//           En prueba de conformidad, firman por duplicado el presente acuerdo.</p>
//         {/* Más contenido aquí */}


//       </div>
//       <button onClick={generatePDF}>Generar Contrato PDF</button>
//     </div>
//   );
// };

const loadImageBase64 = async (imagePath) => {
  const response = await fetch(imagePath);
  const blob = await response.blob();

  return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob); // Convierte la imagen a base64
  });
};

const ContractPDF = () => {

  const generatePDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const lineHeight = 14;
    const margin = 40;
    const headerHeight = 120; // Ajusta la altura del encabezado según necesites
    let y = headerHeight; // Comienza el contenido debajo del encabezado

    const imgData = await loadImageBase64(logo);

    // const imgData = await loadImageBase64(logo); // Cambia el nombre de la imagen según corresponda
    //imprimir ubicacion de la imagen

    //url de la imagen

    // console.log 

    // console.log(imgData);

    const addHeaderAndFooter = (pageNumber) => {
        const imgWidth = 200;
        const imgHeight = 100;
        const imgX = (pageWidth - imgWidth) / 2;
        const imgY = 10;
        doc.setFontSize(12);

        doc.addImage(imgData, "JPEG", imgX, imgY, imgWidth, imgHeight);

        // Pie de página con número de página
        doc.setFontSize(10);
        doc.text(`Página ${pageNumber}`, pageWidth - margin, pageHeight - 20);
        doc.setFontSize(12);
    };

    let pageNumber = 1;
    addHeaderAndFooter(pageNumber);

    // Texto del contrato dividido en secciones
    const sections = [
      { text: "CONTRATO DE OBRA", style: { bold: true, fontSize: 16 } },
      { text: "Entre LA VETERINARIA [………………………………….……………………] y LA EMPRESA AAA S.R.L", style: { fontSize: 12 } },
      { text: "PROTOCOLO [……………………………………..]", style: { fontSize: 12 } },
      { text: "Ciudad de [……………………………………….] – Provincia de […………………......................], a los [………] días del mes de [……………………..] de 20[…….].", style: { fontSize: 12 } },
      { text: "Por una parte, La Veterinaria [………………………………………………………………]", style: { fontSize: 12 } },
      { text: "EXPONEN", style: { bold: true, fontSize: 14 } },
      { text: "- Que LA VETERINARIA es un espacio organizado económicamente y que desarrolla actividades de clínica veterinaria de animales de compañía, interesada en colaborar con la ciencia;", style: { fontSize: 12 } },
      { text: "- Que LA EMPRESA brinda servicios de investigación por contrato para para el registro de medicamentos veterinarios, demandados por empresas farmacéuticas;", style: { fontSize: 12 } },
      { text: "- Que tanto EL ESTABLECIMIENTO como LA EMPRESA están de acuerdo en la participación conjunta de proyectos tanto de ámbito nacional como internacional.", style: { fontSize: 12 } },
      { text: "Teniendo en cuenta lo anteriormente expuesto, CONVIENEN estrechar sus relaciones, aunar esfuerzos y establecer normas amplias y específicas de actuación que encaucen e incrementen, dentro de un marco preestablecido, los contactos y colaboraciones.", style: { fontSize: 12 } },
      { text: "A tal fin, se redacta el siguiente acuerdo de colaboración según las siguientes CLAUSULAS:", style: { fontSize: 12 } },
      { text: "PRIMERA: Finalidad.", style: { bold: true, fontSize: 14 } },
      { text: "LA EMPRESA contrata a LA VETERINARIA con el objeto de ejecutar un proyecto de investigación clínica veterinaria correspondiente al protocolo […………………………………………]. La formulación en estudio pertenece al laboratorio patrocinante [……….…………………………………..], quien delega la ejecución del estudio en LA EMPRESA.", style: { fontSize: 12 } },
      { text: "La investigación consiste en un estudio de [………………………………………………………………………], de una formulación de prueba a base de […………………………………………………………………………………………….…], en la especie [………………………………………………………]. El proyecto de Investigación Clínica Veterinaria tiene asociado un plan de trabajo que detalla el diseño, las distintas fases a desarrollar y la cronología, como se indica en el protocolo adjunto.", style: { fontSize: 12 } },
      { text: "SEGUNDA: Precio.", style: { bold: true, fontSize: 14 } },
      { text: "LA EMPRESA pagará a LA VETERINARIA, el valor de [………]GaVet por animal primario reclutado (Colegio de Med. Vet. 1° circunscripción), equivalente al canon según el precio del GaVet al momento de terminar el estudio completo y con toda la información requerida para ese animal, conforme al protocolo experimental. En el caso de animales que, por causa ajena a la voluntad del Veterinario, no pudieran terminar el estudio, se abonará a LA VETERINARIA, el valor prorrateado de los tiempos consecutivos cumplidos del estudio, con toda la información requerida para ese animal. Se proveerá, además, del total de materiales y recursos que sean necesarios para cumplimentar con el citado programa. Aquellos que no lo incluyan deberán ser aclarados por escrito.", style: { fontSize: 12 } },
      { text: "TERCERA: Responsables.", style: { bold: true, fontSize: 14 } },
      { text: "Por parte de la empresa patrocinante [………………………………………………….………………], se designa al Dr./Dra[.………………….………………………………….] como Investigador/a principal y contacto a cualquier efecto, siendo su correo electrónico [……………………………………………]y su teléfono [………………..………………].", style: { fontSize: 12 } },
      { text: "Por parte de LA VETERINARIA se designa al Dr./Dra. [……………………………………………………….]; siendo sus datos de contacto de:…………………………………………………………………………………………………… Mail:……………………………………………………teléfono:………………………………………………………", style: { fontSize: 12 } },
      { text: "CUARTA: Duración.", style: { bold: true, fontSize: 14 } },
      { text: "El presente Acuerdo Marco entrará en vigor en el momento de su firma y su vigencia será por el tiempo que dure la etapa clínica en LA VETERINARIA.", style: { fontSize: 12 } },
      { text: "QUINTA: Terminación y rescisión.", style: { bold: true, fontSize: 14 } },
      { text: "El presente convenio puede ser rescindido sin expresión de causa por cualquiera de las partes mediante un aviso previo, por medio fehaciente, realizado con una anticipación no menor a los treinta (30) días de la fecha en que se pretende su terminación.", style: { fontSize: 12 } },
      { text: "La terminación comunicada en tiempo y forma conforme lo dispuesto en la presente cláusula no dará derecho a reclamar indemnización de ninguna naturaleza a ninguna de las partes.", style: { fontSize: 12 } },
      { text: "En caso de existir trabajos en curso de ejecución las partes se comprometen a mantener en vigencia el convenio específico hasta la finalización de las actividades previstas. En caso de no ser posible por causas verificables, se tratará de llegar a un acuerdo de finalización anticipada de proyectos.", style: { fontSize: 12 } },
      { text: "LA EMPRESA se compromete a pagar por los servicios contratados a LA VETERINARIA, conforme a lo establecido en el apartado “PRECIO” al momento de rescindir el acuerdo, en contraprestación a lo ya realizado.", style: { fontSize: 12 } },
      { text: "SEXTO: Controversias.", style: { bold: true, fontSize: 14 } },
      { text: "La VETERINARIA y LA EMPRESA se comprometen a resolver de manera amistosa cualquier desacuerdo que pueda surgir en el desarrollo del presente contrato.", style: { fontSize: 12 } },
      { text: "En caso de conflicto, se acuerda el sometimiento de ambos a los tribunales federales de la ciudad de locación de la VETERINARIA y las cámaras de mediación dispuestas a tales efectos.", style: { fontSize: 12 } },
      { text: "SÉPTIMA: Confidencialidad.", style: { bold: true, fontSize: 14 } },
      { text: "Las partes reconocen que toda la documentación e información relacionada y generada como consecuencia de este acuerdo de colaboración como así también toda la información que se intercambie por el hecho o en ocasión del presente, la que se obtenga durante la ejecución de proyectos y el resultado del mismo (en adelante LA INFORMACION CONFIDENCIAL) es de exclusiva propiedad de las partes y se considerará confidencial. En tal sentido, se comprometen a:", style: { fontSize: 12 } },
      { text: "7.1. No usar LA INFORMACION CONFIDENCIAL para otro propósito o fin diferente a la realización del proyecto.", style: { fontSize: 12 } },
      { text: "7.2. No utilizar LA INFORMACION CONFIDENCIAL para cualquier otro fin sin el previo consentimiento escrito de la otra parte.", style: { fontSize: 12 } },
      { text: "7.3. Mantener en estricto secreto LA INFORMACION CONFIDENCIAL y restringir el acceso a LA INFORMACION sólo a aquellas personas a su cargo y por ellos designadas para realizar el proyecto, siendo exclusivos responsables de garantizar la confidencialidad de LA INFORMACION CONFIDENCIAL por parte de dichas personas.", style: { fontSize: 12 } },
      { text: "Quedarán exceptuados de la obligación de confidencialidad respecto de LA INFORMACION CONFIDENCIAL sólo en los siguientes supuestos:", style: { fontSize: 12 } },
      { text: "a) Se encuentre en el dominio público al tiempo de la celebración del presente acuerdo;", style: { fontSize: 12 } },
      { text: "b) Sea publicado o se torne de dominio público por causas no imputables a las partes;", style: { fontSize: 12 } },
      { text: "c) Esté en poder de algunas de las partes al tiempo de la celebración del presente acuerdo;", style: { fontSize: 12 } },
      { text: "d) Medie requerimiento judicial o de organismo público competente.", style: { fontSize: 12 } },
      { text: "Las partes reconocen expresamente que las funciones que desarrollarán en relación con las obligaciones consignadas en el presente acuerdo, los pondrán en contacto con los secretos industriales, técnicos, operacionales y comerciales de cada una de ellas, así como de aquellos pertenecientes a sus socios, asociados, accionistas, filiales, subsidiarias, controladoras, clientes, contratistas y cualquiera otra persona relacionada, además del resto de información privilegiada de sus operaciones. Por lo anterior, serán considerados “secretos”, incluyendo, de manera enunciativa más no limitativa, todos aquellos conocimientos industriales, técnicos, comerciales y operacionales, conceptos creativos, propuestas, estrategias de comunicación y publicidad, marcas, diseños, modelos, base de datos, listas de precios, registros, datos, materiales, planes y proyectos de comercialización y ventas, publicidad e información de cualquier tipo relacionada con los negocios y operaciones de las empresas y/o personas relacionadas directa o indirectamente con ésta.", style: { fontSize: 12 } },
      { text: "La obligación de confidencialidad asumida en este apartado es a perpetuidad. El incumplimiento de la obligación de confidencialidad, hará responsable a la parte divulgadora de responder integralmente por los daños y perjuicios sufridos por la otra parte como consecuencia de cualquier acto o hecho violatorio de tal obligación.", style: { fontSize: 12 } },
      { text: "En prueba de conformidad, firman por duplicado el presente acuerdo.", style: { fontSize: 12 } },

      { text: "Firma: ___________________           Firma:_____________________", style: { fontSize: 12 } },
    ];

    // // Función para agregar secciones al documento
    // sections.forEach((section) => {
    //   // Configura estilo de texto según la sección
    //   doc.setFontSize(section.style.fontSize || 12);
    //   if (section.style.bold) {
    //     doc.setFont("helvetica", "bold");
    //   } else {
    //     doc.setFont("helvetica", "normal");
    //   }

    //   const textLines = doc.splitTextToSize(section.text, doc.internal.pageSize.width - 2 * margin);
    //   textLines.forEach((line) => {
    //     if (y + lineHeight > pageHeight - margin) {
    //       doc.addPage();
    //       pageNumber++;
    //       addHeaderAndFooter(pageNumber);
    //       y = margin;
    //     }
    //     doc.text(line, margin, y);
    //     y += lineHeight;
    //   });
    //   y += lineHeight; // Espacio entre secciones
    // });

    sections.forEach((section) => {
        doc.setFontSize(section.style.fontSize || 12);
        if (section.style.bold) {
            doc.setFont("helvetica", "bold");
        } else {
            doc.setFont("helvetica", "normal");
        }

        const textLines = doc.splitTextToSize(section.text, pageWidth - 2 * margin);
        textLines.forEach((line) => {
            if (y + lineHeight > pageHeight - margin) {
                doc.addPage();
                pageNumber++;
                addHeaderAndFooter(pageNumber);
                y = headerHeight; // Reinicia `y` justo debajo del encabezado en cada nueva página
            }
            doc.text(line, margin, y);
            y += lineHeight;
        });
        y += lineHeight;
      });

    doc.save("Contrato_de_Obra.pdf");
  };

  return (
    <div>
      <button onClick={generatePDF}>Generar Contrato PDF</button>
    </div>
  );
};

export default ContractPDF;
