// React
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import transferenciasData from "layouts/productos/data/transferenciasData";
import ModalNuevaTransferencia from "./ModalNuevaTransferencia";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
};

export default function ModalTransferencias({ open, handleClose, idSucursal, refetchProductos }) {
  const [openModalNuevaTransferencia, setOpenModalNuevaTransferencia] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedMovimiento, setSelectedMovimiento] = useState();
  const [sucursales, setSucursales] = useState([]);
  const { columns, getRows } = transferenciasData;
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "createdAt",
    orden: "DESC",
  });

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { data: sucursalesData } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `
  );

  const {
    data: movimientos,
    loading,
    refetch,
  } = useQuery(
    gql`
      query getMovimientos($filter: JSON, $search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        movimientos(
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          tipo
          ordenPago
          user {
            id
            nombre
            apellido
            username
          }
          sucursalDestino {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          sucursalOrigen {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          observaciones
          fechaEntrega
          fechaOrdenPago
          numeroExpediente
          recepcion {
            id
            createdAt
            observaciones
            movimientoDetalles {
              id
              precio
              cantidad
              producto {
                id
              }
            }
          }
          vencimiento
          responsable {
            id
            nombre
            apellido
            username
          }
          proveedor {
            id
            razonSocial
            nombre
            apellido
          }
          movimientoDetalles {
            id
            precio
            cantidad
            producto {
              id
              codigo
              tipo
              nombre
              categoria
              precio
            }
          }
          createdAt
          userConformidad {
            id
            nombre
            apellido
            username
          }
          statusConformidad
          fechaConformidad
        }
      }
    `,
    {
      variables: {
        filter: {
          tipo: ["Transferencia", "Prestamo"],
          _or: [{ idSucursalOrigen: sucursales }, { idSucursalDestino: sucursales }],
        },
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const handleOpenTransferencia = (transferencia) => {
    setSelectedMovimiento(transferencia);
    setOpenModalNuevaTransferencia(true);
  };

  useEffect(() => {
    if (movimientos && movimientos?.movimientos) {
      setRows(getRows(movimientos.movimientos, handleOpenTransferencia));
      setPagesInfo(movimientos?.paginationInfo);
    }
  }, [movimientos]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      const thisSede = sucursalesData.sucursales.find((sucursal) => sucursal.id === idSucursal);
      setSucursales(
        sucursalesData.sucursales
          .filter((sucursal) => sucursal.sede.id === thisSede.sede.id)
          .map((sucursal) => sucursal.id)
      );
    }
  }, [sucursalesData, idSucursal]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, openModalNuevaTransferencia]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3}>
              <SoftBox>
                <SoftTypography variant="h6">Transferencias y prestamos</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox mr={2}>
                  <SoftInput
                    placeholder="Escribe aquí..."
                    icon={{ component: "search", direction: "left" }}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                maxHeight: "50vh",
                overflow: "auto",
              }}
            >
              {loading ? (
                <Loading />
              ) : movimientos?.movimientos?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={movimientos?.movimientos.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </SoftBox>
          <SoftBox>
            <ModalNuevaTransferencia
              open={openModalNuevaTransferencia}
              handleClose={() => {
                setOpenModalNuevaTransferencia(false);
                setSelectedMovimiento();
              }}
              selectedTransferencia={selectedMovimiento}
              idSucursal={idSucursal}
              refetchProductos={refetchProductos}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalTransferencias.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
