import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const intensidadPulgas = (intensidad) => {
  if (intensidad >= 20) return "Alta Intensidad";
  if (intensidad >= 6) return "Moderada Intensidad";
  return "Baja Intensidad";
};

const PdfFormularioRC = ({ data }) => {
  const generatePdf = () => {
    const doc = new jsPDF();

    // Encabezado
    doc.setFontSize(12);
    doc.text(`FORMULARIO DE REGISTRO CLÍNICO Nro: ${data.idFormulario || 'N/A'}`, 70, 10);
    doc.setFontSize(10);
    doc.text("Código: FRC-EFI-AC-001-V03", 10, 20);
    doc.text("Fecha de vigencia: 11/11/2020", 10, 25);
    doc.text("Próxima Revisión: 11/11/2022", 10, 30);
    doc.text(`ID Proyecto: ${data.proyectoId || 'N/A'}`, 10, 35);  

    // Datos generales
    autoTable(doc, {
      startY: 40,
      head: [["Profesional Actuante", "M.P.", "Tenedor Responsable", "Contacto (tel/mail)", "Dirección"]],
      body: [[data.profesional, data.mp, data.propietario, data.contacto, data.direccion]],
      theme: "grid",
      styles: { halign: "center" },
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Información del animal
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Nombre del Animal", "Especie", "Sexo", "Raza", "Edad"]],
      body: [[data.nombre, data.especie, data.sexo, data.raza, data.edad]],
      theme: "grid",
      styles: { halign: "center" },
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Ectoparásitos
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Ectoparásitos", "Cantidad", "Intensidad de Infestación"]],
      body: [
        ["Pulgas", data.ecto_nroPulgas, intensidadPulgas(data.ecto_intensidadPulgas)],
        ["Garrapatas", data.ecto_nroGarrapatas, data.ecto_intensidadGarrapatas],
      ],
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Endoparásitos
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Endoparásitos - Especies", "Valor de HPG", "Formulación Recibida", "Fecha Tratamiento"]],
      body: [
        [data.endo_idEspecie, data.endo_hpg, data.endo_formulacionRecibida, data.endo_fechaTratamiento],
      ],
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Examen Objetivo General
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Examen Objetivo General", "Valor", "Unidad"]],
      body: [
        ["Peso", data.eog_peso, "kg"],
        ["Mucosas", data.eog_mucosas, "tllc"],
        ["Linfonódulos", data.eog_linfodulos, "-"],
        ["Temperatura", data.eog_temperatura, "°C"],
        ["Sensorio", data.eog_sensorio, "-"],
        ["Frecuencia Respiratoria", data.eog_frecuenciaRespiratoria, "Resp./min"],
        ["Frecuencia Cardíaca", data.eog_frecuenciaCardiaca, "Lat/min"],
        ["Hidratación", data.eog_hidratacion, "Seg."],
        ["Consumo (agua/alimento)", data.eog_consumo, "-"],
        ["Celo/Gestación", data.eog_celo, "-"],
        ["Fecha Inicio", data.eog_fechaInicio, ""],
        ["Fecha Alta", data.eog_fechaAlta, ""],
        
      ],
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Observaciones"]],
      body: [
        [data.eog_obs || ''], // Asegúrate de que no sea undefined, o pasa un string vacío si no tiene valor
      ],
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9, cellPadding: 5 },
      columnStyles: {
        0: { cellWidth: 'auto', valign: 'top' } // Ajusta el tamaño de la celda
      }
    });
    
    

    // Medicaciones Recibidas
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Medicaciones Recibidas", "Antecedentes", "Signo Dermatitis"]],
      body: [[data.mr_medicacionRecibida, data.mr_antecedentes, data.mr_signoDermatitis]],
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Animales Convivientes
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [["Nombre", "Sexo", "Edad", "Raza", "Peso (kg)", "Observaciones"]],
      body: (data.convivientes || []).map(animal => [
        animal.nombre, animal.sexo, animal.edad, animal.raza, animal.peso, animal.observaciones,
      ]),
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Eventos Adversos
    autoTable(doc, {
      startY: doc.lastAutoTable.finalY + 5,
      head: [
        ["Fecha de Ocurrencia", "Descripción", "Severidad", "Acción Tomada", "Notificador"],
      ],
      body: (data.eventosAdversos || []).map(evento => [
        evento.fecha, evento.descripcion, evento.severidad, evento.accionTomada, evento.notificador,
      ]),
      theme: "grid",
      headStyles: { fillColor: [230, 230, 230], textColor: 0, fontSize: 10 },
      bodyStyles: { fontSize: 9 },
    });

    // Firmas
    doc.text("Firma: Investigador Secundario", 10, doc.lastAutoTable.finalY + 15);
    doc.text("Firma: Investigador Principal", 80, doc.lastAutoTable.finalY + 15);
    doc.text("Firma: Monitor del Ensayo", 150, doc.lastAutoTable.finalY + 15);

    // Guardar o mostrar el PDF
    const pdfBlob = doc.output("blob");
    return URL.createObjectURL(pdfBlob);
  };

  return { generatePdf };
};

export default PdfFormularioRC;
