// React
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SinResultados from "components/Carteles/SinResultados";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import TableItem from "../TableItem";
import ModalProveedores from "../../ModalProveedores";
import { MessageManager } from "context";
import ModalProducto from "../../ModalProducto";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { UserContext } from "context/user";
import { ConfirmarContext } from "context/ConfirmarContext";
import ModalAddPlantilla from "../../ModalAddPlantilla";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "precio", desc: "precio", align: "center", width: "200px" },
  { name: "cantidad", desc: "cantidad", align: "center", width: "100px" },
  { name: "subtotal", desc: "subtotal", align: "center" },
  { name: "eliminar", desc: " ", align: "left", width: "1%", noOrder: true },
];

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "precio", desc: "precio", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "left" },
  { name: "subtotal", desc: "subtotal", align: "center" },
];

const GET_PROVEEDORES = gql`
  query getProveedores {
    proveedores {
      id
      tipoProveedor
      razonSocial
      nombre
      apellido
      tipoDocumento
      documento
      tipoResponsable
    }
  }
`;

export default function ModalNuevoRemito({
  open,
  handleClose,
  selectedRemito,
  idSucursal,
  refetchProductos,
}) {
  const [openModalProveedores, setOpenModalProveedores] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const [productoToAdd, setProductoToAdd] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [remito, setRemito] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [productosRemito, setProductosRemito] = useState([]);
  const [openModalProducto, setOpenModalProducto] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [openAddMenu, setOpenAddMenu] = useState(false);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const [openModalAddPlantiilla, setOpenModalAddPlantiilla] = useState(false);
  const [openMenuPlantilla, setOpenMenuPlantilla] = useState(false);
  const { confirmar } = useContext(ConfirmarContext);
  const [administrarPlantillas, setAdministrarPlantillas] = useState(false);

  const { data: proveedores, refetch: refetchProveedores } = useQuery(GET_PROVEEDORES);
  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          categoria
          nombre
          disabled
          precio
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  const [saveRemito, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const [getSucursal, { data: sucursal }] = useLazyQuery(
    gql`
      query getSucursal($id: ID!) {
        sucursal(id: $id) {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `
  );

  const [
    getPlantillas,
    { data: plantillas, refetch: refetchPlantillas, loading: loadingPlantillas },
  ] = useLazyQuery(
    gql`
      query getPlantillas($page: Int, $order: Order, $filter: JSON) {
        plantillas(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          nombre
          user {
            id
            nombre
            apellido
            username
          }
          plantillaDetalles {
            id
            cantidad
            producto {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [deletePlantilla, { loading: loadingDeletePlantilla }] = useMutation(
    gql`
      mutation deletePlantilla($id: ID!) {
        deletePlantilla(id: $id) {
          id
        }
      }
    `
  );

  const handleOpenPlantillaMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuPlantilla(true);
  };

  const handleClosePlantillaMenu = () => {
    setAnchorEl(null);
    setOpenMenuPlantilla(false);
  };

  const handleApplyPlantilla = (plantilla) => {
    setProductosRemito([]);
    let idsProductos = plantilla.plantillaDetalles.map((detalle) => detalle?.producto?.id);
    getProductos({
      variables: {
        page: 1,
        filter: {
          id: { _in: idsProductos },
          disabled: false,
        },
        idSucursal: idSucursal,
      },
    }).then((data) => {
      let newProductosRemito = [];
      data.data.productos.forEach((producto) => {
        newProductosRemito.push({
          ...producto,
          cantidad: plantilla.plantillaDetalles.find(
            (detalle) => detalle?.producto?.id === producto.id
          ).cantidad,
        });
      });
      if (newProductosRemito.length === 0) {
        handleSnackbar("Los productos de la plantilla ya no existen en el sistema", "error");
      } else if (
        newProductosRemito.length > 0 &&
        newProductosRemito.length !== plantilla.plantillaDetalles.length
      ) {
        setProductosRemito(newProductosRemito);
        handleSnackbar(
          "La plantilla contiene algunos productos que ya no existen en el sistema",
          "info"
        );
      } else {
        setProductosRemito(newProductosRemito);
        handleSnackbar("Plantilla aplicada correctamente", "success");
      }
    });
    handleClosePlantillaMenu();
  };

  const handleDeletePlantilla = (plantilla) => {
    confirmar({
      title: "Eliminar plantilla",
      message: "¿Está seguro que desea eliminar esta plantilla?",
      icon: "warning",
      func: () => {
        deletePlantilla({ variables: { id: plantilla.id } })
          .then(() => {
            handleSnackbar("Plantilla eliminada correctamente", "success");
            refetchPlantillas();
          })
          .catch(() => {
            handleSnackbar("Ha ocurrido un error al eliminar la plantilla", "error");
          });
      },
    });
  };

  const renderMenuPlantillas = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openMenuPlantilla)}
      onClose={handleClosePlantillaMenu}
    >
      {plantillas?.plantillas?.length > 0 ? (
        plantillas?.plantillas?.map((plantilla) => (
          <Tooltip
            key={plantilla.id}
            title={`${administrarPlantillas ? "Eliminar" : ""} ${plantilla.nombre} (${
              plantilla?.plantillaDetalles?.length
            } productos)`}
            placement="left"
          >
            <MenuItem
              onClick={() => {
                if (administrarPlantillas) handleDeletePlantilla(plantilla);
                else if (productosRemito.length > 0) {
                  confirmar({
                    title: "Sobreescribir productos",
                    message: "Esta acción sobreescrbirá los productos actuales, ¿desea continuar?",
                    icon: "warning",
                    func: () => {
                      handleApplyPlantilla(plantilla);
                    },
                  });
                } else {
                  handleApplyPlantilla(plantilla);
                }
              }}
              disabled={loadingPlantillas || loadingDeletePlantilla}
            >
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                }}
              >
                <SoftBox display="flex" mr={2}>
                  <SoftBox mr={1}>
                    <SoftTypography variant="p" fontWeight="bold">
                      {plantilla.nombre.length > 20
                        ? `${plantilla.nombre.substring(0, 20)}...`
                        : plantilla.nombre}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography
                      variant="caption"
                      color="sistemasGris"
                    >{`${plantilla?.plantillaDetalles?.length} productos`}</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <Icon
                    fontSize="small"
                    sx={{
                      verticalAlign: "middle",
                      color: administrarPlantillas
                        ? getUproColor("sistemasAmarillo")
                        : getUproColor("sistemasGris"),
                    }}
                  >
                    {administrarPlantillas ? "delete" : "playlist_add"}
                  </Icon>
                </SoftBox>
              </SoftBox>
            </MenuItem>
          </Tooltip>
        ))
      ) : (
        <SinDatos />
      )}
      {plantillas?.plantillas?.length > 0 && (
        <MenuItem
          onClick={() => setAdministrarPlantillas(!administrarPlantillas)}
          disabled={loadingPlantillas || loadingDeletePlantilla}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox display="flex" mr={2}>
              <SoftBox mr={1}>
                <SoftTypography variant="p" fontWeight="bold">
                  {administrarPlantillas ? "Cancelar" : "Administrar"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("sistemasAmarillo"),
                }}
              >
                {administrarPlantillas ? "arrow_back" : "settings"}
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  const handleChanges = (event) => {
    setRemito({ ...remito, [event.target.name]: event.target.value });
  };

  const handleOpenAddMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAddMenu(true);
  };

  const handleCloseAddMenu = () => {
    setAnchorEl(null);
    setOpenAddMenu(false);
  };

  const renderAddMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openAddMenu)}
      onClose={handleCloseAddMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setOpenModalProducto(true);
          setSelectedItem({ tipo: "Material" });
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Material</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              category
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalProducto(true);
          setSelectedItem({ tipo: "Consumible" });
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Consumible</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasGris"),
              }}
            >
              recycling
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalProducto(true);
          setSelectedItem({ tipo: "Recurso" });
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Recurso</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              layers
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _or: [
              {
                id: { _like: `%${event.target.value}%` },
              },
              {
                nombre: { _like: `%${event.target.value}%` },
              },
              {
                categoria: { _like: `%${event.target.value}%` },
              },
            ],
          },
        },
      });
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleUpdateProductoRemito = (material) => {
    setProductosRemito(
      productosRemito.map((item) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const handleAddProduct = (product) => {
    setProductosRemito([...productosRemito, product]);
  };

  const handleRemoveProduct = (product) => {
    setProductosRemito(productosRemito.filter((item) => item.id !== product.id));
  };

  const renderColumnsBase = columnsBase.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsBase.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const handleSave = () => {
    saveRemito({
      variables: {
        input: {
          tipo: "Remito Proveedor",
          movimientoDetalle: productosRemito.map((item) => ({
            idProducto: item.id,
            precio: item.precio,
            cantidad: item.cantidad,
            idSucursal: idSucursal,
          })),
          idSucursalDestino: idSucursal,
          ordenPago: remito.op,
          idProveedor: remito.proveedor,
          numeroExpediente: remito.expediente,
          fechaOrdenPago: remito.fechaOP,
          fechaEntrega: remito.fechaEntrega,
        },
      },
    })
      .then(() => {
        handleSnackbar("El remito se ha guardado correctamente", "success");
        handleClose();
        refetchProductos();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al guardar el remito", "error");
      });
  };

  useEffect(() => {
    setRemito();
    setProductosRemito([]);
    setAdministrarPlantillas(false);
    if (selectedRemito && open) {
      let productosCargados = selectedRemito.movimientoDetalles.map((item) => ({
        ...item.producto,
        cantidad: item.cantidad,
        precio: item.precio,
      }));
      setProductosRemito(productosCargados);
    }
  }, [open]);

  useEffect(() => {
    if (productoToAdd && productos?.productos?.length > 0) {
      if (productos.productos.find((producto) => producto.id === productoToAdd)) {
        handleAddProduct(productos.productos.find((producto) => producto.id === productoToAdd));
      }
      setProductoToAdd();
    }
  }, [productos]);

  useEffect(() => {
    refetchProveedores();
    if (open) getPlantillas({ variables: { page: 1, filter: { tipo: "Movimiento" } } });
  }, [openModalProveedores, open]);

  useEffect(() => {
    if (idSucursal && open) getSucursal({ variables: { id: idSucursal } });
  }, [idSucursal, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">Datos del remito</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="sede">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sucursal de destino
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {`${sucursal?.sucursal?.sede?.nombre} - ${sucursal?.sucursal?.nombre}`}
                        </SoftTypography>
                      ) : (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {sucursal?.sucursal?.nombre ? (
                            `${sucursal?.sucursal?.sede?.nombre} - ${sucursal?.sucursal?.nombre}`
                          ) : (
                            <Skeleton width={100} />
                          )}
                        </SoftTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="op">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Orden de Pago
                          {!selectedRemito && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.ordenPago}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="op"
                          placeholder="Inserte la orden de pago"
                          type="text"
                          name="op"
                          value={remito?.op || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel htmlFor="proveedor">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Proveedor
                            {!selectedRemito && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        {verificarAcceso([42,43,44,45], "or") && !selectedRemito?.id && (
                          <InputLabel htmlFor="proveedor">
                            <SoftTypography
                              variant="caption"
                              color="text"
                              onClick={() => setOpenModalProveedores(true)}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              Administrar proveedores &nbsp;
                              <Icon
                                sx={{
                                  verticalAlign: "middle",
                                }}
                                color="primary"
                              >
                                settings
                              </Icon>
                            </SoftTypography>
                          </InputLabel>
                        )}
                      </SoftBox>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.proveedor.razonSocial ||
                            selectedRemito.proveedor.nombre +
                              " " +
                              selectedRemito.proveedor.apellido}
                        </SoftTypography>
                      ) : (
                        <Autocomplete
                          options={proveedores?.proveedores || []}
                          getOptionLabel={(proveedor) =>
                            proveedor.razonSocial || proveedor.nombre + " " + proveedor.apellido
                          }
                          value={
                            proveedores?.proveedores?.find(
                              (proveedor) => proveedor.id === remito?.proveedor
                            ) || null
                          }
                          onChange={(e, value) =>
                            setRemito({ ...remito, proveedor: value ? value.id : "" })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Buscar proveedor..."
                            />
                          )}
                          noOptionsText="No hay resultados"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="expediente">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Nº de Expediente
                          {!selectedRemito && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.numeroExpediente}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="expediente"
                          placeholder="Inserte el número de expediente"
                          type="text"
                          name="expediente"
                          value={remito?.expediente || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="fechaOP">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Fecha de Orden de Pago
                          {!selectedRemito && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.fechaOrdenPago}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="fechaOP"
                          type="date"
                          name="fechaOP"
                          value={remito?.fechaOP || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="fechaEntrega">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Fecha de Entrega
                          {!selectedRemito && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.fechaEntrega}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="fechaEntrega"
                          type="date"
                          name="fechaEntrega"
                          value={remito?.fechaEntrega || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                  {!selectedRemito ? (
                    <SoftBox display="flex" justifyContent="end" alignItems="flex-end">
                      <SoftBox mr={2}>
                        <Tooltip title="Plantillas" placement="top">
                          <SoftButton
                            circular
                            color="sistemasGris"
                            onClick={handleOpenPlantillaMenu}
                            disabled={loadingPlantillas || loadingDeletePlantilla}
                          >
                            {(loadingPlantillas || loadingDeletePlantilla) && (
                              <CircularProgress size={15} color="inherit" />
                            )}
                            {!loadingPlantillas && !loadingDeletePlantilla && <Icon>sort</Icon>}
                            &nbsp;Plantillas
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      {renderMenuPlantillas}
                      <SoftBox mr={2}>
                        <Tooltip title="Guardar como plantilla" placement="top">
                          <SoftButton
                            circular
                            iconOnly
                            color="primary"
                            disabled={productosRemito.length === 0}
                            onClick={() => setOpenModalAddPlantiilla(true)}
                          >
                            <Icon>save</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        mr={2}
                        sx={{
                          width: "400px",
                        }}
                      >
                        <SoftInput
                          placeholder="Busca aquí materiales..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                          onKeyPress={() => {
                            setOpenSelectionMenu(true);
                          }}
                          onClick={() => {
                            getProductos({
                              variables: {
                                page: 1,
                                filter: {
                                  disabled: false,
                                },
                              },
                            });
                            setOpenSelectionMenu(true);
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (e.relatedTarget?.tagName !== "LI") {
                              setOpenSelectionMenu(false);
                            } else {
                              e.target.focus();
                            }
                          }}
                        />
                        {openSelectionMenu && (
                          <SoftBox sx={{ position: "fixed", zIndex: 99 }}>
                            <Card>
                              <SoftBox
                                p={
                                  !loading &&
                                  productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0
                                    ? 3
                                    : 0
                                }
                                sx={{
                                  width: "400px",
                                  maxHeight: "30vh",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                }}
                              >
                                {openSelectionMenu && loading ? (
                                  <Loading />
                                ) : productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0 &&
                                  openSelectionMenu ? (
                                  productos?.productos
                                    .filter(
                                      (producto) =>
                                        !productosRemito.find((item) => item.id === producto.id)
                                    )
                                    .map((producto) => (
                                      <MenuItem
                                        key={producto.id}
                                        onClick={() => {
                                          handleAddProduct(producto);
                                          setOpenSelectionMenu(false);
                                        }}
                                      >
                                        <SoftBox mr={2}>
                                          <Icon
                                            fontSize="small"
                                            sx={{
                                              color:
                                                producto.tipo === "Material"
                                                  ? getUproColor("sistemasAmarillo")
                                                  : producto.tipo === "Consumible"
                                                  ? getUproColor("sistemasGris")
                                                  : getUproColor("sistemasAmarillo"),
                                            }}
                                          >
                                            {producto.tipo === "Material"
                                              ? "category"
                                              : producto.tipo === "Consumible"
                                              ? "recycling"
                                              : "layers"}
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox>
                                          &nbsp;{`${producto.id} - ${producto.nombre}`}
                                        </SoftBox>
                                      </MenuItem>
                                    ))
                                ) : (
                                  <SinResultados />
                                )}
                              </SoftBox>
                            </Card>
                          </SoftBox>
                        )}
                      </SoftBox>
                      <SoftBox>
                        <Tooltip title={"Agregar producto"} placement="top">
                          <SoftButton
                            color="sistemasGris"
                            circular
                            iconOnly
                            onClick={handleOpenAddMenu}
                          >
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  ) : (
                    <SoftButton color="sistemasGris" circular>
                      Total:
                      <Icon sx={{ fontWeight: "light" }}>attach_money</Icon>
                      {formatMoneyPunto(
                        productosRemito.reduce((acc, item) => {
                          return acc + item.cantidad * item.precio;
                        }, 0)
                      )}
                    </SoftButton>
                  )}
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRemito.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          {selectedRemito ? (
                            <TableRow>{renderColumnsSelect}</TableRow>
                          ) : (
                            <TableRow>{renderColumnsBase}</TableRow>
                          )}
                        </SoftBox>
                        <TableBody>
                          {productosRemito.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                handleUpdateMaterial={handleUpdateProductoRemito}
                                handleRemoveMaterial={() => handleRemoveProduct(material)}
                                disabled={selectedRemito ? true : false}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              {!selectedRemito && (
                <Card sx={{ marginTop: 2 }}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <SoftBox>
                      <SoftBox>
                        <SoftButton color="sistemasGris" circular>
                          Total:
                          <Icon sx={{ fontWeight: "light" }}>attach_money</Icon>
                          {formatMoneyPunto(
                            productosRemito.reduce((acc, item) => {
                              return acc + item.cantidad * item.precio;
                            }, 0)
                          )}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="end" alignItems="center">
                      <SoftBox mr={2}>
                        <SoftButton color="primary" circular onClick={handleClose}>
                          <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                          &nbsp;Cancelar
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <SoftButton
                          color="sistemasGris"
                          circular
                          disabled={
                            loadingSave ||
                            remito?.estado === "Finalizado" ||
                            productosRemito.length === 0 ||
                            productosRemito.some(
                              (item) =>
                                !item.cantidad ||
                                !item.precio ||
                                item.cantidad == 0 ||
                                item.precio == 0 ||
                                item?.cantidad?.toString().includes(".")
                            ) ||
                            !remito?.op ||
                            !remito?.proveedor ||
                            !remito?.expediente ||
                            !remito?.fechaOP ||
                            !remito?.fechaEntrega
                          }
                          onClick={handleSave}
                        >
                          {loadingSave ? (
                            <CircularProgress size={15} color="white" />
                          ) : (
                            <Icon sx={{ fontWeight: "light" }}>save</Icon>
                          )}
                          &nbsp;Guardar
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
            </SoftBox>
            {renderAddMenu}
            <ModalProveedores
              open={openModalProveedores}
              handleClose={() => setOpenModalProveedores(false)}
            />
            <ModalProducto
              id={selectedItem?.id || null}
              tipo={selectedItem?.tipo || null}
              open={openModalProducto}
              handleClose={() => {
                setOpenModalProducto(false);
                handleCloseMenu();
                handleCloseAddMenu();
              }}
              refetch={() => {}}
              onCreate={(newId) => {
                getProductos({
                  variables: {
                    page: 1,
                    filter: {
                      disabled: false,
                      id: newId,
                    },
                  },
                });
                setProductoToAdd(newId);
              }}
            />
            <ModalAddPlantilla
              open={openModalAddPlantiilla}
              handleClose={() => setOpenModalAddPlantiilla(false)}
              productos={productosRemito}
              refetch={refetchPlantillas}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevoRemito.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedRemito: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
