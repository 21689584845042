import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { Checkbox, InputLabel, Tooltip } from "@mui/material";
import SoftTypography from "components/SoftTypography";

export default function VistasGantt({viewMode,onViewListChange, onViewModeChange, isChecked}) {
    return (
        <SoftBox display="flex" alignItems="center" justifyContent="end" gap={1}>
            <SoftButton
            variant="gradient"
            color={viewMode === ViewMode.Day ? "sistemasAmarillo" : "sistemasGris"}
            onClick={() => onViewModeChange(ViewMode.Day)}>
                Día
            </SoftButton>

            <SoftButton
                variant="gradient"
                color={viewMode === ViewMode.Week ? "sistemasAmarillo" : "sistemasGris"}
                onClick={() => onViewModeChange(ViewMode.Week)}
            >
                Semana
            </SoftButton>

            <SoftButton
                variant="gradient"
                color={viewMode === ViewMode.Month ? "sistemasAmarillo" : "sistemasGris"}
                onClick={() => onViewModeChange(ViewMode.Month)}
            >
                Mes
            </SoftButton>
            <SoftButton
                variant="gradient"
                color={viewMode === ViewMode.Year ? "sistemasAmarillo" : "sistemasGris"}
                onClick={() => onViewModeChange(ViewMode.Year)}
            >
                Año
            </SoftButton>

            <Tooltip title={isChecked ? "Ocultar lista de Tareas" : "Mostrar lista de Tareas"} placement="top">
                <SoftButton
                variant="gradient"
                color={isChecked ? "sistemasAmarillo" : "sistemasGris"}
                onClick={() => onViewListChange(!isChecked)}
                sx={{minWidth: "170px"}}
                >
                    Lista de Tareas
                </SoftButton>
            </Tooltip>
        </SoftBox>
      );
}


VistasGantt.propTypes = {
    viewMode: PropTypes.oneOf([ViewMode.Day, ViewMode.Week, ViewMode.Month, ViewMode.Year]),
    onViewListChange: PropTypes.func,
    onViewModeChange: PropTypes.func,
    isChecked: PropTypes.bool,
};
  