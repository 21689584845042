import PropTypes from "prop-types";
import {
    Grid,
    InputLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    IconButton
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete"; 


export default function FormularioMedicaciones({ proyecto, setProyecto }) {
    const handleAddConviviente = (tipo) => {
        const convivientes = proyecto?.convivientes || [];
        if (convivientes.length < 5) {
            setProyecto({
                ...proyecto,
                convivientes: [
                    ...convivientes,
                    { nombre: '', sexo: '', edad: '', raza: '', peso: '', volumen: '', observaciones: '', tipo },
                ],
            });
        }
    };

    const handleChangeConviviente = (index, field, value) => {
        const newConvivientes = [...(proyecto?.convivientes || [])];
        newConvivientes[index][field] = value;
        setProyecto({ ...proyecto, convivientes: newConvivientes });
    };

    const handleDeleteConviviente = (index) => {
        const convivientes = proyecto?.convivientes || [];
        convivientes.splice(index, 1); // Eliminar conviviente 
        setProyecto({ ...proyecto, convivientes: [...convivientes] });
    };

    const handleSave = async () => {
        // Prepare formData for submission
        const formData = {
            mr_medicacionRecibida: proyecto.mr_medicacionRecibida,
            mr_antecedentes: proyecto.mr_antecedentes,
            mr_signoDermatitis: proyecto.mr_signoDermatitis,
            convivientes: proyecto.convivientes,
            // Add other necessary fields from proyecto
        };

        if (!formData.mr_medicacionRecibida) {
            alert("Por favor, complete todos los campos requeridos.");
            return;
        }

        try {
            const { data } = await saveFrcForm({
                variables: { input: formData },
            });
            console.log("Formulario guardado:", data.saveFrcForm);
        } catch (error) {
            console.error("Error al guardar el formulario:", error);
        }
    };

    return (
        <SoftBox>
            <SoftTypography variant="h6" fontWeight="bold">
                Medicaciones Recibidas en los Últimos 6 Meses
            </SoftTypography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="mr_medicacionRecibida">
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            Medicaciones Recibidas (vacunas/antiparasitarios)
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                        id="mr_medicacionRecibida"
                        type="number"
                        value={proyecto?.mr_medicacionRecibida || ''}
                        onChange={(e) => setProyecto({
                            ...proyecto,
                            mr_medicacionRecibida: e.target.value ? parseInt(e.target.value) : ''
                        })}
                        placeholder="Indicar la cantidad de medicaciones recibidas"
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            ¿Presenta antecedentes de reacciones de hipersensibilidad?
                        </SoftTypography>
                    </InputLabel>
                    <Grid container spacing={1} justifyContent="flex-start">
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="mr_antecedentes"
                                name="mr_antecedentes"
                                value={proyecto?.mr_antecedentes || ''}
                                onChange={(e) => setProyecto({ ...proyecto, mr_antecedentes: e.target.value })}
                                style={{ marginLeft: '20px' }}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="Sí" />
                                <FormControlLabel value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            ¿Presenta signos de dermatitis alérgica por pulgas?
                        </SoftTypography>
                    </InputLabel>
                    <Grid container spacing={1} justifyContent="flex-start">
                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="mr_signoDermatitis"
                                name="mr_signoDermatitis"
                                value={proyecto?.mr_signoDermatitis || ''}
                                onChange={(e) => setProyecto({ ...proyecto, mr_signoDermatitis: e.target.value })}
                                style={{ marginLeft: '20px' }}
                            >
                                <FormControlLabel value="si" control={<Radio />} label="Sí" />
                                <FormControlLabel value="leve" control={<Radio />} label="Leve" />
                                <FormControlLabel value="moderado" control={<Radio />} label="Moderado" />
                                <FormControlLabel value="grave" control={<Radio />} label="Grave" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SoftTypography variant="subtitle1" fontWeight="bold">
                                Animales Convivientes
                            </SoftTypography>

                            <InputLabel>
                                <SoftTypography component="span" variant="caption" fontWeight="bold">
                                    ¿Tiene animales convivientes?
                                </SoftTypography>
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={proyecto?.convivientes ? 'si' : 'no'}
                                    onChange={(e) => {
                                        const value = e.target.value === 'si';
                                        setProyecto({ ...proyecto, convivientes: value ? [] : null });
                                    }}
                                >
                                    <MenuItem value="no">No</MenuItem>
                                    <MenuItem value="si">Sí</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {proyecto?.convivientes && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SoftTypography variant="subtitle1" fontWeight="bold">
                                        Animales Convivientes
                                    </SoftTypography>
                                    <Grid container spacing={1}>
                                        {proyecto?.convivientes.map((conviviente, index) => (
                                            <Grid container item spacing={1} key={index}>
                                                <Grid item xs={4}>
                                                    <SoftInput
                                                        label="Nombre"
                                                        value={conviviente.nombre}
                                                        onChange={(e) => handleChangeConviviente(index, 'nombre', e.target.value)}
                                                        placeholder="Nombre"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftInput
                                                        label="Sexo"
                                                        value={conviviente.sexo}
                                                        onChange={(e) => handleChangeConviviente(index, 'sexo', e.target.value)}
                                                        placeholder="Sexo"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftInput
                                                        label="Edad"
                                                        value={conviviente.edad}
                                                        onChange={(e) => handleChangeConviviente(index, 'edad', e.target.value)}
                                                        placeholder="Edad"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftInput
                                                        label="Raza"
                                                        value={conviviente.raza}
                                                        onChange={(e) => handleChangeConviviente(index, 'raza', e.target.value)}
                                                        placeholder="Raza"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftInput
                                                        label="Peso (kg)"
                                                        value={conviviente.peso}
                                                        onChange={(e) => handleChangeConviviente(index, 'peso', e.target.value)}
                                                        placeholder="Peso"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SoftInput
                                                        label="Vol."
                                                        value={conviviente.volumen}
                                                        onChange={(e) => handleChangeConviviente(index, 'volumen', e.target.value)}
                                                        placeholder="Vol."
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SoftInput
                                                        label="Observaciones"
                                                        value={conviviente.observaciones}
                                                        onChange={(e) => handleChangeConviviente(index, 'observaciones', e.target.value)}
                                                        placeholder="Observaciones"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handleDeleteConviviente(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ))}

                                        {proyecto?.convivientes.length < 5 && (
                                            <Grid item>
                                                <SoftButton
                                                    onClick={() => handleAddConviviente('canino')}
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                >
                                                    Agregar Canino
                                                </SoftButton>
                                                <SoftButton
                                                    onClick={() => handleAddConviviente('felino')}
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    style={{ marginLeft: '8px' }}
                                                >
                                                    Agregar Felino
                                                </SoftButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <SoftButton onClick={handleSave} variant="contained" color="primary">
                        Guardar
                    </SoftButton>
                </Grid> */}
            </Grid>
        </SoftBox>
    );
}

FormularioMedicaciones.propTypes = {
    proyecto: PropTypes.object.isRequired,
    setProyecto: PropTypes.func.isRequired,
};
