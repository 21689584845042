// React
import PropTypes from "prop-types";
import { Grid, InputLabel, FormControl, FormControlLabel, Radio, RadioGroup, IconButton } from "@mui/material";

// Custom Components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete";


export default function FormularioEventosAdversos({ proyecto, setProyecto }) {
    const handleAddEvento = () => {
        const eventos = proyecto?.eventosAdversos || [];
        // Hasta3 eventos
        if (eventos.length >= 3) {
            alert("No se pueden agregar más de 3 eventos.");
            return;
        }
        setProyecto({
            ...proyecto,
            eventosAdversos: [
                ...eventos,
                { fecha: '', descripcion: '', severidad: '', accionTomada: '', notificador: '' }
            ]
        });
    };
    
    const handleDeleteEvento = (index) => {
        const eventos = proyecto?.eventosAdversos || [];
        eventos.splice(index, 1); // Eliminar el evento en el índice especificado
        setProyecto({ ...proyecto, eventosAdversos: [...eventos] });
    };

    const handleChangeEvento = (index, field, value) => {
        const newEventos = [...(proyecto?.eventosAdversos || [])];
        newEventos[index][field] = value;
        setProyecto({ ...proyecto, eventosAdversos: newEventos });
    };

    const handleSave = async () => {
        if (!proyecto.peso || !proyecto.mucosas || !proyecto.eventosAdversos?.length) {
            alert("Por favor, complete todos los campos requeridos.");
            return;
        }
    
        try {
            const { data } = await saveFrcForm({
                variables: { input: { ...proyecto } },
            });
            console.log("Formulario guardado:", data.saveFrcForm);
        } catch (error) {
            console.error("Error al guardar el formulario:", error);
        }
    };
    
    return (
        <SoftBox>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <SoftTypography variant="h6" fontWeight="bold">Registro de Eventos Adversos</SoftTypography>
                    <Grid container spacing={1}>
                        {(proyecto?.eventosAdversos || []).map((evento, index) => (
                            <Grid container item spacing={1} key={index} alignItems="center">
                                <Grid item xs={3}>
                                    <SoftInput
                                        label="Fecha de ocurrencia"
                                        type="date"
                                        value={evento.fecha}
                                        onChange={(e) => handleChangeEvento(index, 'fecha', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <SoftInput
                                        label="Descripción del evento"
                                        value={evento.descripcion}
                                        onChange={(e) => handleChangeEvento(index, 'descripcion', e.target.value)}
                                        placeholder="Descripción"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel>Severidad</InputLabel>
                                    <RadioGroup
                                        row
                                        value={evento.severidad}
                                        onChange={(e) => handleChangeEvento(index, 'severidad', e.target.value)}
                                        style={{ marginLeft: '20px' }} 
                                    >
                                        <FormControlLabel value="L" control={<Radio />} label="Leve" />
                                        <FormControlLabel value="M" control={<Radio />} label="Moderado" />
                                        <FormControlLabel value="G" control={<Radio />} label="Grave" />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={5}>
                                    <SoftInput
                                        label="Acción tomada"
                                        value={evento.accionTomada}
                                        onChange={(e) => handleChangeEvento(index, 'accionTomada', e.target.value)}
                                        placeholder="Acción tomada"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SoftInput
                                        label="Notificador"
                                        value={evento.notificador}
                                        onChange={(e) => handleChangeEvento(index, 'notificador', e.target.value)}
                                        placeholder="Notificador"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => handleDeleteEvento(index)}
                                        color="error"
                                        size="large"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <SoftButton
                                onClick={handleAddEvento}
                                variant="outlined"
                                color="primary"
                                size="small" 
                                style={{ marginTop: '16px' }} 
                            >
                                Agregar Evento
                            </SoftButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            Posible relación con el tratamiento
                        </SoftTypography>
                    </InputLabel>
                    <RadioGroup
                        row
                        value={proyecto?.rea_posibleRelacion || ''}
                        onChange={(e) => setProyecto({ ...proyecto, rea_posibleRelacion: e.target.value })}
                        style={{ marginLeft: '20px' }} 
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Sí" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="span" variant="caption" fontWeight="bold">
                            Exclusión del animal del estudio
                        </SoftTypography>
                    </InputLabel>
                    <RadioGroup
                        row
                        value={proyecto?.rea_exclusion || ''}
                        onChange={(e) => setProyecto({ ...proyecto, rea_exclusion: e.target.value})}
                        style={{ marginLeft: '20px' }} 
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Sí" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Tratamientos Concomitantes
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                        label="Especificar medicaciones recibidas"
                        value={proyecto?.rea_trataConcomitante || ''}
                        onChange={(e) => setProyecto({ ...proyecto, rea_trataConcomitante: e.target.value })}
                        placeholder="Especificar medicaciones"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Alta Clínica
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                        label="Detalles del alta"
                        value={proyecto?.rea_altaClinica || ''}
                        onChange={(e) => setProyecto({ ...proyecto, rea_altaClinica: e.target.value })}
                        placeholder="Detalles del alta"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Observaciones Generales
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                        label="Observaciones"
                        value={proyecto?.rea_observaciones || ''}
                        onChange={(e) => setProyecto({ ...proyecto, rea_observaciones: e.target.value })}
                        placeholder="Observaciones generales"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </SoftBox>
    );
}

FormularioEventosAdversos.propTypes = {
    proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,

};
