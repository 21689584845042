import React, { useContext, useState } from 'react';
import { Modal, Fade, Card, Grid, InputLabel, Select, MenuItem, CircularProgress, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { useQuery, useMutation, gql } from '@apollo/client';
import MuiAlert from '@mui/material/Alert';
import { MessageManager } from 'context';

const GET_CLIENTES = gql`
  query {
    clientes {
      id
      nombre
      apellido
      documento
    }
  }
`;

const SAVE_PROYECTO = gql`
  mutation saveProyecto($input: ProyectoInput!) {
    saveProyecto(input: $input) {
      id
    }
  }
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90%',
  width: { xs: '90%', sm: '70%', xxl: '50%' },
  overflow: 'auto',
  p: 3,
};

export default function ModalNewProyecto({ open, onClose, onAdd }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [formData, setFormData] = useState({
    proyecto: '',
    cliente: '',
    fechaInicio: '',
    fechaFinal: '',
    monto: '',
  });
  const [notification, setNotification] = useState({ open: false, message: '' });

  const { data, loading, error } = useQuery(GET_CLIENTES, { skip: !open });

  const [saveProyecto] = useMutation(SAVE_PROYECTO, {
    onCompleted: (data) => {
      onAdd();
      resetForm();
      showNotification('Proyecto creado correctamente');
      onClose();
    },
    onError: (error) => {
      showNotification(`Error: ${error.message}`);
    },
  });

  const resetForm = () => {
    setFormData({
      proyecto: '',
      cliente: '',
      fechaInicio: '',
      fechaFinal: '',
      monto: '',
    });
  };

  const showNotification = (message) => {
    setNotification({ open: true, message });
    setTimeout(() => setNotification({ open: false, message: '' }), 2000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (!formData.proyecto || !formData.cliente || !formData.fechaInicio || !formData.fechaFinal || !formData.monto) {
      alert("Por favor, complete todos los campos obligatorios.");
      return;
    }

    const montoNumerico = parseFloat(formData.monto);
    if (isNaN(montoNumerico)) {
      alert("El monto debe ser un número válido.");
      return;
    }

    try {
      await saveProyecto({
        variables: {
          input: {
            nombre: formData.proyecto,
            idCliente: parseInt(formData.cliente), 
            fechaInicio: formData.fechaInicio,
            fechaFinal: formData.fechaFinal,
            monto: montoNumerico,
          },
        },
      });
    } catch (error) {
      console.error("Error saving project:", error);
      handleSnackbar("Ocurrió un error al guardar el proyecto. Inténtalo de nuevo.", "error");
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftTypography variant="h6">Agregar Proyecto</SoftTypography>
            </SoftBox>
            {loading ? (
              <SoftBox display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </SoftBox>
            ) : error ? (
              <SoftTypography color="error">Error al cargar los clientes</SoftTypography>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="proyecto">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre del Proyecto
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="proyecto"
                    value={formData.proyecto}
                    onChange={handleChange}
                    placeholder="Ingrese el nombre del proyecto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="cliente">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Cliente
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="cliente"
                    value={formData.cliente}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Seleccione un cliente
                    </MenuItem>
                    {(data?.clientes || []).map((cliente) => (
                      <MenuItem key={cliente.id} value={cliente.id}>
                        {`${cliente.nombre} ${cliente.apellido || ''} - ${cliente.documento}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fechaInicio">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha Inicio
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="fechaInicio"
                    type="date"
                    value={formData.fechaInicio}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="fechaFinal">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha Final
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="fechaFinal"
                    type="date"
                    value={formData.fechaFinal}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="monto">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Monto
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    name="monto"
                    value={formData.monto}
                    onChange={handleChange}
                    placeholder="Ingrese el monto"
                  />
                </Grid>
              </Grid>
            )}
            <SoftBox display="flex" justifyContent="end" mt={2}>
              <SoftButton onClick={onClose} color="secondary" variant="outlined">
                Cancelar
              </SoftButton>
              <SoftButton onClick={handleSubmit} color="primary" variant="contained" sx={{ ml: 2 }}>
                Agregar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNewProyecto.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};
