/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatDate } from "utils/formatters";

const getRows = (data, handleSelect, idSucursal) => {
  const rows = [];
  const estados = ["Pendiente", "Diferencias", "Finalizado", "Revisado"];
  const colors = ["warning", "error", "success", "info"];
  const icons = ["hourglass_empty", "error", "check_circle", "check_circle"];
  if (data) {
    data.forEach((item) => {
      let func = item?.recepcion || item?.sucursalOrigen?.id == idSucursal ? handleSelect : false;
      rows.push({
        type: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <SoftBox pl={3} mr={2} display="flex">
              <Tooltip title={item.tipo} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasGris"),
                  }}
                  fontSize="lg"
                >
                  import_export
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.id}
            </SoftTypography>
          </SoftBox>
        ),
        sucursalOrigen: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalOrigen?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        sucursalDestino: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalDestino?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.username} <Icon>arrow_forward</Icon> {item?.recepcion?.user?.username || "-"}
            </SoftTypography>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <Tooltip title={item?.estado}>
              <Icon
                fontSize="medium"
                color={colors[estados.indexOf(item?.estado)]}
              >
                {icons[estados.indexOf(item?.estado)]}
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: func ? "pointer" : "default",
            }}
            onClick={() => func ? handleSelect(item) : null}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {formatDate(item?.createdAt, "dd/MM/yyyy")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          func ? (
            <SoftBox
              mr={2}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => handleSelect(item)}
            >
              <Tooltip title="Ver detalles" placement="top">
                <Icon
                  sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                  fontSize="small"
                  onClick={() => handleSelect(item)}
                >
                  arrow_forward
                </Icon>
              </Tooltip>
            </SoftBox>
          ) : (<></>)
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "right", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "fecha", desc: "Fecha", align: "left", orderField: "createdAt" },
  { name: "sucursalOrigen", desc: "Sede Origen", align: "left", noOrder: true },
  { name: "sucursalDestino", desc: "Sede Destino", align: "left", noOrder: true },
  { name: "user", desc: "Usuarios", align: "left", noOrder: true },
  { name: "estado", desc: "Estado", align: "center", noOrder: true },
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };
