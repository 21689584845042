// React
import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import ModalDelete from "components/Modals/Delete";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Pagination,
  Skeleton,
  Tooltip,
  Menu,
  MenuItem,
  Collapse,
} from "@mui/material";

// Data
// import dataUsuarios from "layouts/usuarios/data/dataUsuarios";

import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { getUproColor } from "utils/colors";
import SoftInput from "components/SoftInput";
// import ModalUsuarios from "./ModalUsuario";
import { MessageManager } from "context";
import { UserContext } from "context/user";

import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import VistasGantt from "./components/VistasGantt";
import dayjs from "dayjs";

function Progreso() {
    // const tasksInicial = [
    //     {
    //      start: new Date(2020, 1, 1),
    //       end: new Date(2020, 1, 15),
    //       name: "Sprint 1",
    //       id: "ProjectSample",
    //       progress: 25,
    //       type: "project",
    //       hideChildren: false,
    //       displayOrder: 1,
    //     },
    //     {
    //       start: new Date(2020, 1, 1),
    //       end: new Date(
    //         2020, 1,
    //         2,
    //         12,
    //         28
    //       ),
    //       name: "Presentation 1",
    //       id: "Task 0",
    //       progress: 45,
    //       type: "task",
    //       project: "ProjectSample",
    //       displayOrder: 2,
    //     },
    //     {
    //       start: new Date(2020, 1, 1),
    //       end: new Date(2020, 1, 12),
    //       name: "Presentation 2",
    //       id: "Task 1",
    //       progress: 25,
    //       dependencies: ["Task 0"],
    //       type: "task",
    //       project: "ProjectSample",
    //       displayOrder: 3,
    //     },
              

    //     {
    //         start: new Date(2020, 1, 5),
    //        end: new Date(2020, 1, 25),
    //         name: "Sprint 2 ",
    //         id: "ProjectSample1",
    //         progress: 25,
    //         type: "project",
    //         hideChildren: false,
    //         displayOrder: 4,
    //       },
    //     {
    //       start: new Date(2020, 1, 5),
    //       end: new Date(2020, 1, 7),
    //       name: 'Idea 1',
    //       id: 'Task-0',
    //       type:'task',
    //       progress: 45,
    //       project: "ProjectSample1",
    //       isDisabled: true,
    //       styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    //       displayOrder: 5,
    //     },
    //     {
    //       start: new Date(2020, 1, 8),
    //       end: new Date(2020, 1, 12),
    //       name: 'Idea 2',
    //       id: 'Task-1',
    //       type:'task',
    //       progress: 15,
    //       project: "ProjectSample1",
    //       isDisabled: true,
    //       styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    //       displayOrder: 6,
    //     },
    //     {
    //       start: new Date(2020, 1, 12),
    //       end: new Date(2020, 1, 15),
    //       name: 'Idea 3',
    //       id: 'Task-2',
    //       type:'task',
    //       progress: 5,
    //       project: "ProjectSample1",
    //       isDisabled: true,
    //       styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    //       displayOrder: 7,
    //     },
    // ];
    
    const [tasks,setTasks] = useState(null);
    const [view, setView] = useState(ViewMode.Day);
    const [isChecked, setIsChecked] = useState(true);
    const [openFilters, setOpenFilters] = useState(false);
    let columnWidth = 65;
    if (view === ViewMode.Year) {
      columnWidth = 450;
    } else if (view === ViewMode.Month) {
      columnWidth = 300;
    } else if (view === ViewMode.Week) {
      columnWidth = 250;
    }

    const handleExpanderClick = (task) => {
      setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    };

    const toggleFilters = () => {
      if (openFilters) {
        setOpenFilters(false);
      } else {
        setOpenFilters(true);
      }
    };

    const getStartEndDateForProject = (tasksParam, projectId) => {
      const projectTasks = tasksParam.filter(t => t.project === projectId);
      let start = projectTasks[0].start;
      let end = projectTasks[0].end;
    
      for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
          start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
          end = task.end;
        }
      }
      return [start, end];
    }

    const handleTaskChange = (task) => {
      let newTasks = tasks.map(t => (t.id === task.id ? task : t));
      if (task.project) {
        const [start, end] = getStartEndDateForProject(newTasks, task.project);
        const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
        if (
          project.start.getTime() !== start.getTime() ||
          project.end.getTime() !== end.getTime()
        ) {
          const changedProject = { ...project, start, end };
          newTasks = newTasks.map(t =>
            t.id === task.project ? changedProject : t
          );
        }
      }
      setTasks(newTasks);
    };
  

    const [anchorEl, setAnchorEl] = useState(null);
    const [openSedeMenu, setOpenSedeMenu] = useState(false);
    const [openProyectoMenu, setProyectoMenu] = useState(false);
    const [selectedSede, setSelectedSede] = useState(null);
    const [selectedNodo, setSelectedNodo] = useState(null);
    const { user } = useContext(UserContext);

    const { data: dataSedes, loading: loadingSedes } = useQuery(
      gql`
        query getSedes($filter: JSON) {
          sedes(filter: $filter) {
            id
            nombre
          }
        }
      `,
      {
        variables: {
          filter: {
            id: user?.sucursales
              ? [...new Set(user?.sucursales.map((sucursal) => sucursal.sede.id))]
              : undefined,
          },
        },
        onCompleted: (data) => {
          if (data?.sedes?.length > 0) {
            setSelectedSede(data?.sedes[0].id);
          }
        },
      }
    );

    const [getPlanta, { data: dataProyectos, loading: loadingNodos, error, refetch }] = useLazyQuery(
      gql`
        query getPlanta($idSede: ID) {
          nodos(filter: { idSede: $idSede }) {
            ...NodoFields
            hijos {
              ...NodoFields
              hijos {
                ...NodoFields
              }
            }
          }
        }
        fragment NodoFields on Nodo {
          id
          tipo
          subtipo
          nombre
          fechaInicioEstimada
          fechaFinEstimada
          sede {
            id
          }
        }
      `,
      {
        fetchPolicy: "network-only",
      }
    );

    useEffect(() => {
      if (selectedSede) {
        getPlanta({ variables: { idSede: selectedSede } });
      }
    }, [selectedSede]);

    useEffect(() => {
      if(selectedNodo){
        const nodo = dataProyectos.nodos.find(n => n.id === selectedNodo);
        if(nodo.hijos.length > 0){
          generarGantt(nodo.hijos);
        }else{
          setTasks(null);
        }
      }
    }, [selectedNodo]);

    const generarGantt = (nodoTareas) => {
      let estructuraGantt = [];
      let contador = 0;
      nodoTareas.forEach((element,index)=> {
        if(element.tipo === "Actividad"){
          contador++;
          estructuraGantt.push({
            start: element?.fechaInicioEstimada ? dayjs(element?.fechaInicioEstimada).toDate() : new Date(2024, 6, 12),
            end: element?.fechaFinEstimada ? dayjs(element?.fechaFinEstimada).toDate() : new Date(2024, 6, 12),
            name: element.nombre,
            id: element.id,
            progress: 5,
            type: "project",
            hideChildren: false,
            displayOrder: contador,
          });

          if(element?.hijos.length > 0){
            element?.hijos.forEach((tarea, index) => {
              if(tarea.tipo === "Tarea"){
                contador++;
                estructuraGantt.push({
                  start: tarea?.fechaInicioEstimada ? dayjs(tarea?.fechaInicioEstimada).toDate() : new Date(2024, 6, 12),
                  end: tarea?.fechaFinEstimada ? dayjs(tarea?.fechaFinEstimada).toDate() : new Date(2024, 6, 12),
                  name: tarea.nombre,
                  id: tarea.id,
                  progress: 5,
                  type: "task",
                  project: element.id,
                  displayOrder: contador,
                 });
              }
            });
          }
        }
      });

      setTasks(estructuraGantt);
    }

    const handleCloseSedeMenu = () => {
      setAnchorEl(null);
      setOpenSedeMenu(false);
    };

    const handleOpenSedeMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpenSedeMenu(true);
    };

    const handleCloseProyectoMenu = () => {
      setAnchorEl(null);
      setProyectoMenu(false);
    };

    const handleOpenProyectoMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setProyectoMenu(true);
    };

    const renderSedeMenu = (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(openSedeMenu)}
        onClose={handleCloseSedeMenu}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: "300px",
            overflowY: "scroll",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        {dataSedes?.sedes?.map((sede, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setSelectedSede(sede.id);
              handleCloseSedeMenu();
            }}
            sx={{
              minWidth: { xs: "75vw", sm: "auto" },
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox mr={2}>
                <SoftTypography variant="p">{sede.nombre}</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color:
                      sede.id === selectedSede ? getUproColor("sistemasAmarillo") : getUproColor("sistemasGris"),
                  }}
                >
                  location_on
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        ))}
      </Menu>
    );
    const renderProyectoMenu = (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(openProyectoMenu)}
        onClose={handleCloseProyectoMenu}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: "300px",
            overflowY: "scroll",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        {dataProyectos?.nodos?.map((nodo, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setSelectedNodo(nodo.id);
              handleCloseProyectoMenu();
            }}
            sx={{
              minWidth: { xs: "75vw", sm: "auto" },
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox mr={2}>
                <SoftTypography variant="p">{nodo.nombre}</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color:
                      nodo.id === selectedNodo ? getUproColor("sistemasAmarillo") : getUproColor("sistemasGris"),
                  }}
                >
                  account_tree
                </Icon>
              </SoftBox>
            </SoftBox>
          </MenuItem>
        ))}
      </Menu>
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
            <Card>
                <Grid container spacing={2} p={3}>
                    {renderSedeMenu}
                    {renderProyectoMenu}
                    <Grid item xs={12}>
                        <SoftBox
                        display={{
                            xs: "flex-row",
                            sm: "flex",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                        >
                          <SoftBox display="flex" alignItems="center" gap={2}>
                            <SoftBox
                              display="flex"
                              alignItems="center"
                              onClick={dataSedes?.sedes?.length > 0 ? handleOpenSedeMenu : null}
                            >
                              <Icon color="primary">location_on</Icon>
                              <SoftTypography
                                variant="h6"
                                sx={{
                                  cursor: "pointer",
                                }}
                                ml={1}
                              >
                                {loadingSedes ? (
                                  <Skeleton width={100} />
                                ) : dataSedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre ? (
                                  dataSedes?.sedes?.find((sede) => sede.id === selectedSede).nombre
                                ) : (
                                  "No hay sedes disponibles"
                                )}
                              </SoftTypography>
                              <Icon color="dark">{openSedeMenu ? "arrow_drop_up" : "arrow_drop_down"}</Icon>
                            </SoftBox>

                            {/* menu proyectos */}
                            <SoftBox
                              display="flex"
                              alignItems="center"
                              onClick={dataProyectos?.nodos?.length > 0 ? handleOpenProyectoMenu : null}
                            >
                              <Icon color="primary">
                                account_tree
                              </Icon>
                              <SoftTypography
                                variant="h6"
                                sx={{
                                  cursor: "pointer",
                                }}
                                ml={1}
                              >
                                {loadingNodos ? (
                                  <Skeleton width={100} />
                                ) : dataProyectos?.nodos?.find((nodo) => nodo.id === selectedNodo)?.nombre ? (
                                  dataProyectos?.nodos?.find((nodo) => nodo.id === selectedNodo).nombre
                                ) : dataProyectos?.nodos?.length > 0 ? 
                                  "Seleccione un proyecto" 
                                  : "No hay proyectos disponibles"
                                }
                              </SoftTypography>
                              <Icon color="dark">{openProyectoMenu ? "arrow_drop_up" : "arrow_drop_down"}</Icon>
                            </SoftBox>
                          </SoftBox>


                            <SoftBox mr={2} display="flex" alignItems="center" gap={1.5}>
                              <Collapse in={openFilters} 
                              timeout={{enter: 1000, exit: 800}}
                              orientation="horizontal" unmountOnExit>
                                <SoftBox>
                                  <VistasGantt
                                  viewMode={view}
                                  onViewModeChange={viewMode => setView(viewMode)}
                                  onViewListChange={setIsChecked}
                                  isChecked={isChecked}
                                  />
                                </SoftBox>
                              </Collapse>

                              <Tooltip title={openFilters ? "Cerrar filtros" : "Ver filtros"} placement="top">
                                <SoftButton color="sistemasGris" onClick={toggleFilters} circular iconOnly>
                                  <Icon sx={{ fontWeight: "bold" }}>
                                    {openFilters ? "filter_alt_off" : "filter_alt"}
                                  </Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                        </SoftBox>
                    </Grid>
                </Grid>
                <SoftBox p={3} pt={2}>
                  {
                    tasks?.length > 0 ? (
                      <Gantt 
                      tasks={tasks} 
                      viewMode={view}
                      locale="spa"
                      onDateChange={handleTaskChange}
                      onExpanderClick={handleExpanderClick}
                      listCellWidth={isChecked ? "155px" : ""}
                      columnWidth={columnWidth}
                      />
                    ) : (
                      <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                        <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                          {
                            selectedNodo ? "El proyecto no tiene tareas registradas" : "Seleccione un proyecto"
                          }
                        </SoftTypography>
                      </SoftBox>
                    )
                  }
                </SoftBox>
            </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Progreso;
