// React
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SinResultados from "components/Carteles/SinResultados";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import TableItem from "../TableItem";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import { UserContext } from "context/user";
import ModalConfirmarDevolucion from "../../ModalConfirmarDevolucion";
import SoftBadge from "components/SoftBadge";
import dayjs from "dayjs";
import ModalAddPlantilla from "../../ModalAddPlantilla";
import { ConfirmarContext } from "context/ConfirmarContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "stock", desc: "Stock Disponible", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "center", width: "100px" },
  { name: "eliminar", desc: " ", align: "left", width: "1%", noOrder: true },
];

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "left" },
];

const columnsPrestamo = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "stock", desc: "Prestado", align: "left" },
  { name: "cantidad", desc: "Devuelto", align: "left", width: "200px" },
];

export default function ModalNuevaTransferencia({
  open,
  handleClose,
  selectedTransferencia,
  idSucursal,
  refetchProductos,
  refetchMovimientos,
  tipo,
}) {
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const [productoToAdd, setProductoToAdd] = useState();
  const [remito, setRemito] = useState();
  const [devolucion, setDevolucion] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [productosRemito, setProductosRemito] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  const [sucursales, setSucursales] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [sucursalesSede, setSucursalesSede] = useState([]);
  const [openModalConfirmarDevolucion, setOpenModalConfirmarDevolucion] = useState(false);
  const { user } = useContext(UserContext);
  const [fechaNuevaDevolucion, setFechaNuevaDevolucion] = useState();
  const [openModalAddPlantiilla, setOpenModalAddPlantiilla] = useState(false);
  const [openMenuPlantilla, setOpenMenuPlantilla] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { confirmar } = useContext(ConfirmarContext);
  const [administrarPlantillas, setAdministrarPlantillas] = useState(false);

  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON, $idSucursal: ID) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          categoria
          nombre
          disabled
          precio
          stock(idSucursal: $idSucursal) {
            cantidad
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [saveMovimiento, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const [updateVencimientoMovimiento, { loading: loadingUpdMovimiento }] = useMutation(
    gql`
      mutation updateVencimientoMovimiento($id: ID!, $vencimiento: DateTime!) {
        updateVencimientoMovimiento(id: $id, vencimiento: $vencimiento) {
          id
        }
      }
    `
  );

  const { data: sucursalesData } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
          administraStock
        }
      }
    `
  );

  const [addDevolucionPrestamo, { loading: loadingDevolucion }] = useMutation(
    gql`
      mutation addDevolucionPrestamo($idMovimiento: ID!, $idMovimientoPrestamo: ID!) {
        addDevolucionPrestamo(
          idMovimiento: $idMovimiento
          idMovimientoPrestamo: $idMovimientoPrestamo
        ) {
          id
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios(filter: { tipoUser: "Empleado" }, order: { field: "nombre", order: ASC }) {
          id
          nombre
          apellido
          username
        }
      }
    `
  );

  const [
    getPlantillas,
    { data: plantillas, refetch: refetchPlantillas, loading: loadingPlantillas },
  ] = useLazyQuery(
    gql`
      query getPlantillas($page: Int, $order: Order, $filter: JSON) {
        plantillas(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          nombre
          user {
            id
            nombre
            apellido
            username
          }
          plantillaDetalles {
            id
            cantidad
            producto {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [deletePlantilla, { loading: loadingDeletePlantilla }] = useMutation(
    gql`
      mutation deletePlantilla($id: ID!) {
        deletePlantilla(id: $id) {
          id
        }
      }
    `
  );

  const handleChanges = (event) => {
    setRemito({ ...remito, [event.target.name]: event.target.value });
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _or: [
              {
                id: { _like: `%${event.target.value}%` },
              },
              {
                nombre: { _like: `%${event.target.value}%` },
              },
              {
                categoria: { _like: `%${event.target.value}%` },
              },
            ],
            _stock: {
              cantidad: { _gt: 0 },
              idSucursal: tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
            },
          },
          idSucursal: tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
        },
      });
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleUpdateProductoRemito = (material) => {
    setProductosRemito(
      productosRemito.map((item) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const handleAddProduct = (product) => {
    setProductosRemito([...productosRemito, product]);
  };

  const handleRemoveProduct = (product) => {
    setProductosRemito(productosRemito.filter((item) => item.id !== product.id));
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuPlantilla(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenuPlantilla(false);
  };

  const renderColumnsBase = columnsBase.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsBase.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderColumnsPrestamo = columnsPrestamo.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsPrestamo.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const handleSave = () => {
    let detalles = productosRemito.map((item) => ({
      idProducto: item.id,
      idSucursal: remito.sucursalOrigen,
      cantidad: -item.cantidad,
    }));
    detalles = detalles.concat(
      productosRemito.map((item) => ({
        idProducto: item.id,
        idSucursal: remito.sucursalDestino,
        cantidad: item.cantidad,
      }))
    );
    saveMovimiento({
      variables: {
        input: {
          tipo: "Transferencia",
          movimientoDetalle: detalles,
          idSucursalOrigen: remito.sucursalOrigen,
          idSucursalDestino: remito.sucursalDestino,
          observaciones: remito.observaciones,
          idUser: user.id,
        },
      },
    })
      .then(() => {
        handleSnackbar("La transferencia se ha realizado correctamente", "success");
        handleClose();
        refetchProductos();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al realizar la transferencia", "error");
      });
  };

  const handlePrestar = () => {
    let detalles = productosRemito.map((item) => ({
      idProducto: item.id,
      idSucursal: idSucursal,
      cantidad: -item.cantidad,
    }));
    detalles = detalles.concat(
      productosRemito.map((item) => ({
        idProducto: item.id,
        idSucursal: remito.sucursalDestino,
        cantidad: item.cantidad,
      }))
    );
    saveMovimiento({
      variables: {
        input: {
          tipo: "Prestamo",
          movimientoDetalle: detalles,
          idSucursalOrigen: idSucursal,
          idSucursalDestino: remito.sucursalDestino,
          observaciones: remito.observaciones,
          vencimiento: dayjs(remito?.vencimiento).format("YYYY-MM-DD HH:mm:ss"),
          idResponsable: remito.responsable,
          idUser: user.id,
        },
      },
    })
      .then(() => {
        handleSnackbar("El prestamo se ha realizado correctamente", "success");
        handleClose();
        refetchProductos();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al realizar el prestamo", "error");
      });
  };

  const handleCambiarFechaVencimiento = () => {
    updateVencimientoMovimiento({
      variables: {
        id: selectedTransferencia.id,
        vencimiento: dayjs(fechaNuevaDevolucion).format("YYYY-MM-DD HH:mm:ss"),
      },
    })
      .then(() => {
        handleSnackbar("La fecha de vencimiento se ha actualizado correctamente", "success");
        refetchProductos();
        refetchMovimientos();
        setFechaNuevaDevolucion();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al actualizar la fecha de vencimiento", "error");
      });
  };

  const handleDevolucion = () => {
    setOpenModalConfirmarDevolucion(false);
    let detalles = productosRemito.map((item) => ({
      idProducto: item.id,
      idSucursal: selectedTransferencia?.sucursalDestino?.id,
      cantidad: -item.cantidad,
    }));
    detalles = detalles.concat(
      productosRemito.map((item) => ({
        idProducto: item.id,
        idSucursal: selectedTransferencia?.sucursalOrigen?.id,
        cantidad: item.cantidad,
      }))
    );

    let diferencia = productosRemito.map((item) => ({
      idProducto: item.id,
      cantidad: item.cantidad - item.stock?.cantidad,
    }));

    diferencia = diferencia.filter((item) => item.cantidad < 0);

    saveMovimiento({
      variables: {
        input: {
          tipo: "Devolucion",
          movimientoDetalle: detalles,
          idSucursalOrigen: selectedTransferencia?.sucursalDestino?.id,
          idSucursalDestino: selectedTransferencia?.sucursalOrigen?.id,
          observaciones: devolucion.observaciones,
          idResponsable: devolucion.responsable,
          idRecepcion: selectedTransferencia?.id,
          idUser: user.id,
        },
      },
    })
      .then((res) => {
        if (res?.data?.saveMovimiento?.id) {
          addDevolucionPrestamo({
            variables: {
              idMovimiento: selectedTransferencia.id,
              idMovimientoPrestamo: res?.data?.saveMovimiento?.id,
            },
          })
            .then(() => {
              if (diferencia.length > 0) {
                saveMovimiento({
                  variables: {
                    input: {
                      tipo: "Ajuste Devolucion",
                      movimientoDetalle: diferencia.map((item) => ({
                        idProducto: item.idProducto,
                        idSucursal: selectedTransferencia?.sucursalDestino?.id,
                        cantidad: item.cantidad,
                      })),
                      idSucursalOrigen: selectedTransferencia?.sucursalDestino?.id,
                      idSucursalDestino: selectedTransferencia?.sucursalOrigen?.id,
                    },
                  },
                }).then(() => {
                  handleSnackbar("La devolución se ha realizado correctamente", "success");
                  handleClose();
                  refetchProductos();
                });
              } else {
                handleSnackbar("La devolución se ha realizado correctamente", "success");
                handleClose();
                refetchProductos();
              }
            })
            .catch(() => {
              handleSnackbar("Ha ocurrido un error al realizar la devolución", "error");
            });
        }
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al realizar la devolución", "error");
      });
  };

  const handleApplyPlantilla = (plantilla) => {
    setProductosRemito([]);
    let idsProductos = plantilla.plantillaDetalles.map((detalle) => detalle?.producto?.id);
    getProductos({
      variables: {
        page: 1,
        filter: {
          id: { _in: idsProductos },
          disabled: false,
        },
        idSucursal: tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
      },
    }).then((data) => {
      let newProductosRemito = [];
      data.data.productos.forEach((producto) => {
        newProductosRemito.push({
          ...producto,
          stock: { cantidad: producto.stock?.cantidad || 0 },
          cantidad:
            plantilla.plantillaDetalles.find((detalle) => detalle?.producto?.id === producto.id)
              ?.cantidad || 0,
        });
      });
      if (newProductosRemito.length === 0) {
        handleSnackbar("Los productos de la plantilla ya no existen en el sistema", "error");
      } else if (
        newProductosRemito.length > 0 &&
        newProductosRemito.length !== plantilla.plantillaDetalles.length
      ) {
        setProductosRemito(newProductosRemito);
        handleSnackbar(
          "La plantilla contiene algunos productos que ya no existen en el sistema",
          "info"
        );
      } else {
        setProductosRemito(newProductosRemito);
        handleSnackbar("Plantilla aplicada correctamente", "success");
      }
    });
    handleCloseMenu();
  };

  const handleDeletePlantilla = (plantilla) => {
    confirmar({
      title: "Eliminar plantilla",
      message: "¿Está seguro que desea eliminar esta plantilla?",
      icon: "warning",
      func: () => {
        deletePlantilla({ variables: { id: plantilla.id } })
          .then(() => {
            handleSnackbar("Plantilla eliminada correctamente", "success");
            refetchPlantillas();
          })
          .catch(() => {
            handleSnackbar("Ha ocurrido un error al eliminar la plantilla", "error");
          });
      },
    });
  };

  const renderMenuPlantillas = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openMenuPlantilla)}
      onClose={handleCloseMenu}
    >
      {plantillas?.plantillas?.length > 0 ? (
        plantillas?.plantillas?.map((plantilla) => (
          <Tooltip
            key={plantilla.id}
            title={`${administrarPlantillas ? "Eliminar" : ""} ${plantilla.nombre} (${
              plantilla?.plantillaDetalles?.length
            } productos)`}
            placement="left"
          >
            <MenuItem
              onClick={() => {
                if (administrarPlantillas) handleDeletePlantilla(plantilla);
                else if (productosRemito.length > 0) {
                  confirmar({
                    title: "Sobreescribir productos",
                    message: "Esta acción sobreescrbirá los productos actuales, ¿desea continuar?",
                    icon: "warning",
                    func: () => {
                      handleApplyPlantilla(plantilla);
                    },
                  });
                } else {
                  handleApplyPlantilla(plantilla);
                }
              }}
            >
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                }}
              >
                <SoftBox display="flex" mr={2}>
                  <SoftBox mr={1}>
                    <SoftTypography variant="p" fontWeight="bold">
                      {plantilla.nombre.length > 20
                        ? `${plantilla.nombre.substring(0, 20)}...`
                        : plantilla.nombre}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography
                      variant="caption"
                      color="sistemasGris"
                    >{`${plantilla?.plantillaDetalles?.length} productos`}</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <Icon
                    fontSize="small"
                    sx={{
                      verticalAlign: "middle",
                      color: administrarPlantillas
                        ? getUproColor("sistemasAmarillo")
                        : getUproColor("sistemasGris"),
                    }}
                  >
                    {administrarPlantillas ? "delete" : "playlist_add"}
                  </Icon>
                </SoftBox>
              </SoftBox>
            </MenuItem>
          </Tooltip>
        ))
      ) : (
        <SinDatos />
      )}
      {plantillas?.plantillas?.length > 0 && (
        <MenuItem onClick={() => setAdministrarPlantillas(!administrarPlantillas)}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox display="flex" mr={2}>
              <SoftBox mr={1}>
                <SoftTypography variant="p" fontWeight="bold">
                  {administrarPlantillas ? "Cancelar" : "Administrar"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("sistemasAmarillo"),
                }}
              >
                {administrarPlantillas ? "arrow_back" : "settings"}
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    setRemito();
    setProductosRemito([]);
    setAdministrarPlantillas(false);
    if (selectedTransferencia && open) {
      let productosCargados = selectedTransferencia.movimientoDetalles
        .filter((item) => item.cantidad > 0)
        .map((item) => ({
          ...item.producto,
          cantidad: item.cantidad,
          stock: { cantidad: item?.cantidad || 0 },
        }));

      if (selectedTransferencia?.recepcion?.id) {
        productosCargados.forEach((producto) => {
          const productoComplementario = selectedTransferencia?.recepcion.movimientoDetalles.find(
            (detalle) => detalle?.producto?.id === producto.id && detalle.cantidad > 0
          );
          if (productoComplementario) {
            if (selectedTransferencia.tipo === "Prestamo") {
              producto.devuelto = productoComplementario.cantidad;
            } else {
              producto.devuelto = producto.cantidad;
              producto.cantidad = productoComplementario.cantidad;
            }
          }
        });
        setProductosRemito(productosCargados);
      } else {
        setProductosRemito(productosCargados);
      }
    }
    if (open) getPlantillas({ variables: { page: 1, filter: { tipo: "Movimiento" } } });
  }, [open]);

  useEffect(() => {
    if (!selectedTransferencia) {
      setProductosRemito([]);
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _stock: {
              cantidad: { _gt: 0 },
              idSucursal: tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
            },
          },
          idSucursal: tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
        },
      });
    }
  }, [remito?.sucursalOrigen]);

  useEffect(() => {
    if (productoToAdd && productos?.productos?.length > 0) {
      if (productos.productos.find((producto) => producto.id === productoToAdd)) {
        handleAddProduct(productos.productos.find((producto) => producto.id === productoToAdd));
      }
      setProductoToAdd();
    }
  }, [productos]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      const thisSede = sucursalesData.sucursales.find((sucursal) => sucursal.id === idSucursal);
      let sedes = [];
      sucursalesData.sucursales.forEach((sucursal) => {
        if (!sedes.find((sede) => sede.id === sucursal.sede.id)) {
          sedes.push(sucursal.sede);
        }
      });
      setSedes(sedes);
      setSucursales(
        sucursalesData.sucursales.filter((sucursal) => sucursal.sede.id === thisSede.sede.id)
      );
      setSucursalesSede(
        sucursalesData.sucursales.filter((sucursal) => sucursal.sede.id === thisSede.sede.id)
      );
      setRemito({
        ...remito,
        sedeDestino: thisSede.sede.id,
      });
    }
  }, [sucursalesData, idSucursal]);

  useEffect(() => {
    if (sucursalesData && remito?.sedeDestino) {
      const thisSucursal = sucursalesData.sucursales.find((sucursal) => sucursal.id === idSucursal);
      if (thisSucursal.sede.id !== remito.sedeDestino) {
        setSucursalesSede(
          sucursalesData.sucursales.filter(
            (sucursal) => sucursal.sede.id === remito.sedeDestino && sucursal.administraStock
          )
        );
      } else {
        setSucursalesSede(
          sucursalesData.sucursales.filter((sucursal) => sucursal.sede.id === remito.sedeDestino)
        );
      }
    }
  }, [remito?.sedeDestino]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftBox>
                    <SoftTypography variant="h6">
                      {selectedTransferencia
                        ? `${selectedTransferencia?.tipo} N°${selectedTransferencia?.id}`
                        : tipo === "Prestamo"
                        ? "Nuevo prestamo"
                        : "Nueva transferencia"}
                    </SoftTypography>
                    {selectedTransferencia && (
                      <SoftTypography component="h6" variant="caption" fontWeight="bold">
                        <SoftBox display="flex">
                          <Icon>person</Icon>
                          &nbsp;
                          {`Creado por ${
                            selectedTransferencia?.user?.nombre
                              ? `${selectedTransferencia?.user?.nombre} ${selectedTransferencia?.user?.apellido}`
                              : selectedTransferencia?.user?.username
                          }`}
                        </SoftBox>
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={selectedTransferencia ? 4 : tipo === "Prestamo" ? 3 : 6}>
                      <InputLabel htmlFor="sucursalOrigen">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          {tipo === "Prestamo" ? "Fecha de vencimiento" : "Sucursal de origen"}
                          {!selectedTransferencia && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                          {remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && (
                              <SoftTypography variant="caption" color="error">
                                &nbsp;(No se puede transferir a la misma sucursal)
                              </SoftTypography>
                            )}
                        </SoftTypography>
                      </InputLabel>
                      {tipo === "Prestamo" ? (
                        <SoftInput
                          label="Fecha de vencimiento"
                          type="datetime-local"
                          name="vencimiento"
                          value={remito?.vencimiento || ""}
                          onChange={handleChanges}
                          error={dayjs(remito?.vencimiento).isBefore(dayjs())}
                        />
                      ) : selectedTransferencia ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.sucursalOrigen?.nombre +
                            " - " +
                            selectedTransferencia?.sucursalOrigen?.sede?.nombre}
                        </SoftTypography>
                      ) : (
                        <Select
                          name="sucursalOrigen"
                          value={remito?.sucursalOrigen || ""}
                          onChange={handleChanges}
                          sx={
                            remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && {
                              borderColor: "red",
                              color: "red",
                            }
                          }
                        >
                          {sucursales.map((sucursal) => (
                            <MenuItem key={sucursal.id} value={sucursal.id}>
                              {sucursal.nombre + " - " + sucursal.sede.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      display={tipo === "Prestamo" && !selectedTransferencia ? "block" : "none"}
                    >
                      <InputLabel htmlFor="sedeDestino">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sede de destino
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        name="sedeDestino"
                        value={remito?.sedeDestino || ""}
                        onChange={handleChanges}
                      >
                        {sedes.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={selectedTransferencia ? 4 : tipo === "Prestamo" ? 3 : 6}>
                      <InputLabel htmlFor="sucursalDestino">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sucursal de destino
                          {!selectedTransferencia && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                          {remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && (
                              <SoftTypography variant="caption" color="error">
                                &nbsp;(No se puede transferir a la misma sucursal)
                              </SoftTypography>
                            )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedTransferencia ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.sucursalDestino?.nombre +
                            " - " +
                            selectedTransferencia?.sucursalDestino?.sede?.nombre}
                        </SoftTypography>
                      ) : (
                        <Select
                          name="sucursalDestino"
                          value={remito?.sucursalDestino || ""}
                          onChange={handleChanges}
                          sx={
                            remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && {
                              borderColor: "red",
                              color: "red",
                            }
                          }
                        >
                          {tipo === "Prestamo"
                            ? sucursalesSede
                                .filter((sucursal) => sucursal.id !== idSucursal)
                                .map((sucursal) => (
                                  <MenuItem key={sucursal.id} value={sucursal.id}>
                                    {sucursal.nombre}
                                  </MenuItem>
                                ))
                            : sucursales.map((sucursal) => (
                                <MenuItem key={sucursal.id} value={sucursal.id}>
                                  {sucursal.nombre + " - " + sucursal.sede.nombre}
                                </MenuItem>
                              ))}
                        </Select>
                      )}
                    </Grid>
                    {tipo === "Prestamo" && (
                      <Grid item xs={12} sm={3}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Responsable
                            {!selectedTransferencia && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        <Autocomplete
                          options={usuarios?.usuarios || []}
                          getOptionLabel={(user) => `${user.nombre} ${user.apellido}`}
                          value={
                            usuarios?.usuarios?.find((user) => user.id === remito?.responsable) ||
                            null
                          }
                          onChange={(e, value) =>
                            setRemito({ ...remito, responsable: value ? value.id : "" })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Buscar usuario..."
                            />
                          )}
                          noOptionsText="No hay resultados"
                        />
                      </Grid>
                    )}
                    {selectedTransferencia && (
                      <Grid item xs={4}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Fecha de transferencia
                          </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {dayjs(selectedTransferencia?.createdAt).format("DD/MM/YYYY HH:mm")}
                        </SoftTypography>
                      </Grid>
                    )}
                    {selectedTransferencia && selectedTransferencia?.vencimiento && (
                      <Grid item xs={4}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Vencimiento del préstamo
                          </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {dayjs(selectedTransferencia?.vencimiento).format("DD/MM/YYYY HH:mm")}
                        </SoftTypography>
                      </Grid>
                    )}
                    {selectedTransferencia && selectedTransferencia?.tipo === "Prestamo" && (
                      <Grid item xs={4}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Responsable del préstamo
                          </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.responsable?.nombre
                            ? `${selectedTransferencia?.responsable?.nombre} ${selectedTransferencia?.responsable?.apellido}`
                            : selectedTransferencia?.responsable?.username}
                        </SoftTypography>
                      </Grid>
                    )}
                    {selectedTransferencia && selectedTransferencia?.tipo === "Prestamo" && (
                      <Grid item xs={4}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Estado del préstamo
                          </SoftTypography>
                        </InputLabel>
                        <SoftBadge
                          color={
                            selectedTransferencia?.recepcion
                              ? "success"
                              : dayjs(selectedTransferencia?.vencimiento).isBefore(dayjs())
                              ? "error"
                              : dayjs(selectedTransferencia?.vencimiento).isSame(dayjs(), "day")
                              ? "warning"
                              : "success"
                          }
                          size="xs"
                          badgeContent={
                            <SoftTypography
                              verticalAlign="middle"
                              variant="caption"
                              color="white"
                              fontWeight="medium"
                            >
                              {selectedTransferencia?.recepcion
                                ? "Prestamo finalizado"
                                : dayjs(selectedTransferencia?.vencimiento).isBefore(dayjs())
                                ? "Prestamo pendiente de devolución - Atrasado"
                                : dayjs(selectedTransferencia?.vencimiento).isSame(dayjs(), "day")
                                ? "Prestamo pendiente de devolución - Vence hoy"
                                : "Prestamo pendiente de devolución"}
                            </SoftTypography>
                          }
                          container
                        />
                      </Grid>
                    )}
                    {selectedTransferencia?.userConformidad && (
                      <Grid item xs={12}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            {`${
                              selectedTransferencia?.statusConformidad === "Conforme"
                                ? "Conformidad dada por"
                                : "Rechazado por"
                            }`}
                          </SoftTypography>
                        </InputLabel>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox display="flex" alignItems="center" mr={1}>
                            {selectedTransferencia?.statusConformidad === "Conforme" ? (
                              <Icon color="success">verified</Icon>
                            ) : (
                              <Icon color="error">thumb_down</Icon>
                            )}
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedTransferencia?.userConformidad?.nombre
                              ? `${selectedTransferencia?.userConformidad?.nombre} ${
                                  selectedTransferencia?.userConformidad?.apellido
                                } el ${dayjs(selectedTransferencia?.fechaConformidad).format(
                                  "DD/MM/YYYY"
                                )} a las ${dayjs(selectedTransferencia?.fechaConformidad).format(
                                  "HH:mm"
                                )}`
                              : `${selectedTransferencia?.userConformidad?.username} el ${dayjs(
                                  selectedTransferencia?.fechaConformidad
                                ).format("DD/MM/YYYY")} a las ${dayjs(
                                  selectedTransferencia?.fechaConformidad
                                ).format("HH:mm")}`}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    )}
                    {selectedTransferencia?.tipo === "Transferencia" &&
                      !selectedTransferencia?.userConformidad && (
                        <Grid item xs={12}>
                          <InputLabel htmlFor="sucursalDestino">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Conformidad
                            </SoftTypography>
                          </InputLabel>
                          <SoftBox display="flex" alignItems="center">
                            <SoftTypography variant="h6" fontWeight="bold">
                              No se ha dado la conformidad desde la sucursal
                            </SoftTypography>
                          </SoftBox>
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          {selectedTransferencia?.recepcion?.observaciones
                            ? "Observaciones del préstamo"
                            : "Observaciones"}
                          {!selectedTransferencia && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedTransferencia ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.observaciones || "Sin observaciones"}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="observaciones"
                          placeholder="Inserte un comentario"
                          type="text"
                          name="observaciones"
                          value={remito?.observaciones || ""}
                          onChange={handleChanges}
                          multiline
                          rows={4}
                        />
                      )}
                    </Grid>
                    {selectedTransferencia && selectedTransferencia?.recepcion && (
                      <Grid item xs={4}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Fecha de devolución
                          </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {dayjs(selectedTransferencia?.recepcion?.createdAt).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </SoftTypography>
                      </Grid>
                    )}
                    {selectedTransferencia && selectedTransferencia?.recepcion && (
                      <Grid item xs={8}>
                        <InputLabel htmlFor="sucursalDestino">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Observaciones de la devolución
                          </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.recepcion?.observaciones ||
                            "Sin observaciones de devolución"}
                        </SoftTypography>
                      </Grid>
                    )}
                    {selectedTransferencia &&
                      selectedTransferencia?.vencimiento &&
                      !selectedTransferencia?.recepcion && (
                        <Grid item xs={12}>
                          <SoftBox display="flex" justifyContent="end" alignItems="center">
                            {fechaNuevaDevolucion && (
                              <SoftBox mr={2}>
                                <InputLabel htmlFor="sucursalOrigen">
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Nuevo vencimiento
                                  </SoftTypography>
                                </InputLabel>
                                <SoftInput
                                  label="Nueva fecha de vencimiento"
                                  type="datetime-local"
                                  name="nuevoVencimiento"
                                  id="nuevoVencimiento"
                                  value={fechaNuevaDevolucion || ""}
                                  onChange={(e) => setFechaNuevaDevolucion(e.target.value)}
                                  error={dayjs(fechaNuevaDevolucion).isBefore(dayjs())}
                                />
                              </SoftBox>
                            )}
                            {fechaNuevaDevolucion && (
                              <SoftBox mr={2}>
                                <InputLabel htmlFor="sucursalOrigen">
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    &nbsp;
                                  </SoftTypography>
                                </InputLabel>
                                <Tooltip title="Cancelar" placement="top">
                                  <SoftButton
                                    color="error"
                                    circular
                                    iconOnly
                                    onClick={() => setFechaNuevaDevolucion()}
                                  >
                                    <Icon sx={{ fontWeight: "light" }}>close</Icon>
                                  </SoftButton>
                                </Tooltip>
                              </SoftBox>
                            )}
                            <SoftBox>
                              <InputLabel htmlFor="sucursalOrigen">
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  &nbsp;
                                </SoftTypography>
                              </InputLabel>
                              <Tooltip
                                title={
                                  fechaNuevaDevolucion
                                    ? "Confirmar nueva fecha de vencimiento"
                                    : "Cambiar fecha de vencimiento"
                                }
                                placement="top"
                              >
                                <SoftButton
                                  color={fechaNuevaDevolucion ? "success" : "sistemasAmarillo"}
                                  circular
                                  iconOnly={fechaNuevaDevolucion}
                                  onClick={
                                    fechaNuevaDevolucion
                                      ? handleCambiarFechaVencimiento
                                      : (e) => {
                                          e.target.blur();
                                          setFechaNuevaDevolucion(
                                            selectedTransferencia?.vencimiento
                                          );
                                        }
                                  }
                                >
                                  <Icon
                                    sx={{
                                      fontWeight: "light",
                                      marginRight: fechaNuevaDevolucion ? 0 : 1,
                                    }}
                                  >
                                    {fechaNuevaDevolucion ? "done" : "event_repeat"}
                                  </Icon>
                                  {!fechaNuevaDevolucion && "Cambiar fecha de devolución"}
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      )}
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                  {!selectedTransferencia && (
                    <SoftBox display="flex" justifyContent="end" alignItems="flex-end">
                      <SoftBox mr={2}>
                        <Tooltip title="Plantillas" placement="top">
                          <SoftButton
                            circular
                            color="sistemasGris"
                            onClick={handleOpenMenu}
                            disabled={tipo === "Prestamo" ? false : !remito?.sucursalOrigen}
                          >
                            {(loadingPlantillas || loadingDeletePlantilla) && (
                              <CircularProgress size={15} color="inherit" />
                            )}
                            {!loadingPlantillas && !loadingDeletePlantilla && <Icon>sort</Icon>}
                            &nbsp;Plantillas
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      {renderMenuPlantillas}
                      <SoftBox mr={2}>
                        <Tooltip title="Guardar como plantilla" placement="top">
                          <SoftButton
                            circular
                            iconOnly
                            color="primary"
                            disabled={productosRemito.length === 0}
                            onClick={() => setOpenModalAddPlantiilla(true)}
                          >
                            <Icon>save</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        sx={{
                          width: "400px",
                        }}
                      >
                        <SoftInput
                          placeholder="Busca aquí materiales..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                          onKeyPress={() => {
                            setOpenSelectionMenu(true);
                          }}
                          onClick={() => {
                            getProductos({
                              variables: {
                                page: 1,
                                filter: {
                                  disabled: false,
                                  _stock: {
                                    cantidad: { _gt: 0 },
                                    idSucursal:
                                      tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
                                  },
                                },
                                idSucursal:
                                  tipo === "Prestamo" ? idSucursal : remito?.sucursalOrigen,
                              },
                            });
                            setOpenSelectionMenu(true);
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (e.relatedTarget?.tagName !== "LI") {
                              setOpenSelectionMenu(false);
                            } else {
                              e.target.focus();
                            }
                          }}
                        />
                        {openSelectionMenu && (
                          <SoftBox sx={{ position: "fixed", zIndex: 99 }}>
                            <Card>
                              <SoftBox
                                p={
                                  !loading &&
                                  productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0
                                    ? 3
                                    : 0
                                }
                                sx={{
                                  width: "400px",
                                  maxHeight: "30vh",
                                  overflowY: "auto",
                                }}
                              >
                                {openSelectionMenu && loading ? (
                                  <Loading />
                                ) : productos?.productos?.length > 0 &&
                                  (tipo === "Prestamo" || remito?.sucursalOrigen) &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0 &&
                                  openSelectionMenu ? (
                                  productos?.productos
                                    .filter(
                                      (producto) =>
                                        !productosRemito.find((item) => item.id === producto.id)
                                    )
                                    .map((producto) => (
                                      <MenuItem
                                        key={producto.id}
                                        onClick={() => {
                                          handleAddProduct(producto);
                                          setOpenSelectionMenu(false);
                                        }}
                                      >
                                        <SoftBox mr={2}>
                                          <Icon
                                            sx={{
                                              color:
                                                producto.tipo === "Material"
                                                  ? getUproColor("sistemasAmarillo")
                                                  : producto.tipo === "Consumible"
                                                  ? getUproColor("sistemasGris")
                                                  : getUproColor("sistemasAmarillo"),
                                            }}
                                            fontSize="small"
                                          >
                                            {producto.tipo === "Material"
                                              ? "category"
                                              : producto.tipo === "Consumible"
                                              ? "recycling"
                                              : "layers"}
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox>
                                          &nbsp;{`${producto.id} - ${producto.nombre}`}
                                        </SoftBox>
                                      </MenuItem>
                                    ))
                                ) : (
                                  <SinResultados />
                                )}
                              </SoftBox>
                            </Card>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </SoftBox>
                  )}
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRemito.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          {selectedTransferencia &&
                          (selectedTransferencia?.tipo === "Prestamo" ||
                            selectedTransferencia?.tipo === "Devolucion") ? (
                            <TableRow>{renderColumnsPrestamo}</TableRow>
                          ) : selectedTransferencia ? (
                            <TableRow>{renderColumnsSelect}</TableRow>
                          ) : (
                            <TableRow>{renderColumnsBase}</TableRow>
                          )}
                        </SoftBox>
                        <TableBody>
                          {productosRemito.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                handleUpdateMaterial={handleUpdateProductoRemito}
                                handleRemoveMaterial={() => handleRemoveProduct(material)}
                                tipo={selectedTransferencia?.tipo}
                                disabled={
                                  selectedTransferencia &&
                                  selectedTransferencia?.vencimiento &&
                                  !selectedTransferencia?.recepcion
                                    ? false
                                    : selectedTransferencia
                                    ? true
                                    : false
                                }
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              {!selectedTransferencia && (
                <Card sx={{ marginTop: 2 }}>
                  <SoftBox display="flex" justifyContent="end" alignItems="center" p={2}>
                    <SoftBox mr={2}>
                      <SoftButton color="primary" circular onClick={handleClose}>
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                      </SoftButton>
                    </SoftBox>
                    <SoftBox>
                      <SoftButton
                        color="sistemasGris"
                        circular
                        disabled={
                          loadingSave ||
                          productosRemito.length === 0 ||
                          productosRemito.some(
                            (item) =>
                              !item.cantidad ||
                              item.cantidad == 0 ||
                              item.cantidad > item.stock?.cantidad ||
                              item?.cantidad?.toString().includes(".")
                          ) ||
                          (tipo === "Prestamo" &&
                            (!remito?.responsable ||
                              !remito?.vencimiento ||
                              dayjs(remito?.vencimiento).isBefore(dayjs()))) ||
                          (tipo !== "Prestamo" && !remito?.sucursalOrigen) ||
                          !remito?.sucursalDestino ||
                          remito?.sucursalOrigen === remito?.sucursalDestino ||
                          !remito?.observaciones
                        }
                        onClick={tipo === "Prestamo" ? handlePrestar : handleSave}
                      >
                        {loadingSave ? (
                          <CircularProgress size={15} color="white" />
                        ) : (
                          <Icon sx={{ fontWeight: "light" }}>
                            {tipo === "Prestamo" ? "send" : "swap_horiz"}
                          </Icon>
                        )}
                        &nbsp;{tipo === "Prestamo" ? "Prestar" : "Transferir"}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
              {selectedTransferencia &&
                selectedTransferencia?.vencimiento &&
                !selectedTransferencia?.recepcion && (
                  <Card sx={{ marginTop: 2 }}>
                    <SoftBox display="flex" justifyContent="end" alignItems="center" p={2}>
                      <SoftBox>
                        <SoftButton
                          color="sistemasGris"
                          circular
                          onClick={() => setOpenModalConfirmarDevolucion(true)}
                          disabled={
                            loadingDevolucion ||
                            loadingSave ||
                            productosRemito.length === 0 ||
                            productosRemito.some(
                              (item) =>
                                (!item.cantidad && item.cantidad !== 0) ||
                                item.cantidad > item.stock?.cantidad ||
                                item?.cantidad?.toString().includes(".")
                            )
                          }
                        >
                          {loadingDevolucion ? (
                            <CircularProgress size={15} color="white" />
                          ) : (
                            <Icon sx={{ fontWeight: "light" }}>replay</Icon>
                          )}
                          &nbsp;Devolver Prestamo
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                )}
            </SoftBox>
            <ModalConfirmarDevolucion
              open={openModalConfirmarDevolucion}
              handleClose={() => setOpenModalConfirmarDevolucion(false)}
              setDevolucion={setDevolucion}
              handleSave={handleDevolucion}
              productos={productosRemito}
            />
            <ModalAddPlantilla
              open={openModalAddPlantiilla}
              handleClose={() => setOpenModalAddPlantiilla(false)}
              productos={productosRemito}
              refetch={refetchPlantillas}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevaTransferencia.defaultProps = {
  tipo: "Transferencia",
};

ModalNuevaTransferencia.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedTransferencia: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
  refetchMovimientos: PropTypes.func,
  tipo: PropTypes.string,
};
