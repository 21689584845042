// React
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Menu, MenuItem, Modal, Tooltip } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import ModalNuevaTransferencia from "layouts/productos/components/ModalAjustes";
import ajustesData from "layouts/productos/data/ajustesData";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
};

export default function ModalHistorial({ open, handleClose, idSucursal, refetchProductos }) {
  const [openNuevoAjuste, setOpenNuevoAjuste] = useState(false);
  const [openAjusteMenu, setOpenAjusteMenu] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedMovimiento, setSelectedMovimiento] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [sucursales, setSucursales] = useState([]);

  const [selectedAjuste, setSelectedAjuste] = useState();
  const { columns, getRows } = ajustesData;

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "createdAt",
    orden: "DESC",
  });

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleOpenAjusteMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAjusteMenu(true);
  };

  const handleCloseAjusteMenu = () => {
    setAnchorEl(null);
    setOpenAjusteMenu(false);
  };

  const { data: sucursalesData } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `
  );

  const {
    data: movimientos,
    loading,
    refetch,
  } = useQuery(
    gql`
      query getMovimientos($filter: JSON, $search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        movimientos(
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          tipo
          ordenPago
          sucursalDestino {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          sucursalOrigen {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          observaciones
          fechaEntrega
          fechaOrdenPago
          numeroExpediente
          user {
            id
            username
            nombre
            apellido
          }
          proveedor {
            id
            razonSocial
            nombre
            apellido
          }
          movimientoDetalles {
            id
            precio
            cantidad
            producto {
              id
              codigo
              tipo
              nombre
              categoria
              precio
            }
          }
          createdAt
        }
      }
    `,
    {
      variables: {
        filter: {
          tipo: [
            "Ajuste - Stock Inicial",
            "Ajuste - Diferencia Física",
            "Merma",
            "Devolucion Proveedor",
          ],
          _or: [{ idSucursalOrigen: sucursales }, { idSucursalDestino: sucursales }],
        },
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const handleOpenTransferencia = (transferencia) => {
    setSelectedMovimiento(transferencia);
    setSelectedAjuste(
      transferencia.tipo === "Ajuste - Stock Inicial" ||
        transferencia.tipo === "Ajuste - Diferencia Física"
        ? "Ajuste"
        : transferencia.tipo
    );
    setOpenNuevoAjuste(true);
  };

  useEffect(() => {
    if (movimientos && movimientos?.movimientos) {
      setRows(getRows(movimientos.movimientos, handleOpenTransferencia));
      setPagesInfo(movimientos?.paginationInfo);
    }
  }, [movimientos]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      const thisSede = sucursalesData.sucursales.find((sucursal) => sucursal.id === idSucursal);
      setSucursales(
        sucursalesData.sucursales
          .filter((sucursal) => sucursal.sede.id === thisSede.sede.id)
          .map((sucursal) => sucursal.id)
      );
    }
  }, [sucursalesData, idSucursal]);

  const renderAjusteMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openAjusteMenu)}
      onClose={handleCloseAjusteMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setOpenNuevoAjuste(true);
          setSelectedAjuste("Ajuste");
          handleCloseAjusteMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Ajuste</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasGris"),
              }}
            >
              drive_file_rename_outline
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenNuevoAjuste(true);
          setSelectedAjuste("Merma");
          handleCloseAjusteMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Merma</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              arrow_downward
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenNuevoAjuste(true);
          setSelectedAjuste("Devolucion Proveedor");
          handleCloseAjusteMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Devolucion a proveedor</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              undo
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3}>
              <SoftBox>
                <SoftTypography variant="h6">Historial de ajustes</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftBox mr={2}>
                  <SoftInput
                    placeholder="Escribe aquí..."
                    icon={{ component: "search", direction: "left" }}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            {renderAjusteMenu}
            <SoftBox
              sx={{
                maxHeight: "50vh",
                overflow: "auto",
              }}
            >
              {loading ? (
                <Loading />
              ) : movimientos?.movimientos?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={movimientos?.movimientos.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </SoftBox>
          <SoftBox>
            <ModalNuevaTransferencia
              open={openNuevoAjuste}
              handleClose={() => {
                setOpenNuevoAjuste(false);
                setSelectedMovimiento();
                setSelectedAjuste();
              }}
              selectedMovimiento={selectedMovimiento}
              selectedAjuste={selectedAjuste}
              idSucursal={idSucursal}
              refetchProductos={() => {
                refetchProductos();
                refetch();
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalHistorial.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
