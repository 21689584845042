import * as React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Modal,
  Fade,
  Card,
  Grid,
  InputLabel,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";

// Componentes de Kaizen Dashboard
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Contexto
import { useContext } from "react";
import { MessageManager } from "context";
import SoftInput from "components/SoftInput";
import { useState } from "react";

// GraphQL Queries y Mutations
const GET_RAZAS = gql`
  query GetRazas {
    razas {
      id
      nombre
    }
  }
`;

const CREATE_RAZA = gql`
  mutation CreateRaza($input: RazaInput!) {
    createRaza(input: $input) {
      id
      nombre
    }
  }
`;

const UPDATE_RAZA = gql`
  mutation UpdateRaza($id: ID!, $nombre: String!) {
    updateRaza(id: $id, nombre: $nombre) {
      id
      nombre
    }
  }
`;

const DELETE_RAZA = gql`
  mutation DeleteRaza($id: ID!) {
    deleteRaza(id: $id) {
      id
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "50%", md: "40%" },
  py: 4,
};

const initialRaza = {
  id: '',
  nombre: '',
};

export default function ModalNewRaza({ open, handleClose, handleCloseCancel, especieId, refetchRazas }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [raza, setRaza] = useState(initialRaza);
  const [cargando, setCargando] = useState(false);
  const [razaSeleccionada, setRazaSeleccionada] = useState('');

  // Consulta para obtener razas
  const { data, loading, error } = useQuery(GET_RAZAS);
  
  const [createRaza] = useMutation(CREATE_RAZA);
  const [updateRaza] = useMutation(UPDATE_RAZA);
  const [deleteRaza] = useMutation(DELETE_RAZA);

  if (loading) return <CircularProgress />;
  if (error) {
    handleSnackbar("Error al cargar las razas", "error");
    return null;
  }

  const razas = data?.razas || [];

  const handleSubmit = async () => {
    setCargando(true);
    try {
      if (raza.id) {
        
        await updateRaza({ variables: { id: raza.id, nombre: raza.nombre } });
        refetchRazas();
        handleSnackbar("Raza actualizada correctamente", "success");
      } else {
        await createRaza({ variables: {input: { nombre: raza.nombre, idEspecie: especieId }} });
        refetchRazas();
        handleSnackbar("Raza creada correctamente", "success");
      }
      setRaza(initialRaza);
      handleClose(); // Cierra la modal después de guardar
    } catch (error) {
      console.log(error);
      handleSnackbar("Error al guardar la raza", "error");
    } finally {
      setCargando(false);
    }
  };

  const handleRazaChange = (e) => {
    const selectedRaza = razas.find(r => r.id === e.target.value);
    if (selectedRaza) {
      setRaza({ id: selectedRaza.id, nombre: selectedRaza.nombre });
      setRazaSeleccionada(selectedRaza.id);
    } else {
      setRaza(initialRaza);
      setRazaSeleccionada('');
    }
  };

  const handleDeleteRaza = async () => {
    if (!razaSeleccionada) {
      handleSnackbar("Selecciona una raza para eliminar", "warning");
      return;
    }

    setCargando(true);
    try {
      await deleteRaza({ variables: { id: razaSeleccionada } });
      handleSnackbar("Raza eliminada correctamente", "success");
      setRazaSeleccionada('');
      setRaza(initialRaza); // Reiniciar el formulario
    } catch (error) {
      console.log(error);
      handleSnackbar("Error al eliminar la raza", "error");
    } finally {
      setCargando(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleCloseCancel} // Esta función cerrará la modal
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3} display="flex" justifyContent="space-between">
            <SoftTypography variant="h6">Gestionar Raza</SoftTypography>
          </SoftBox>
          <Grid container spacing={2} px={2}>
            <Grid item xs={12} display="flex" alignItems="center">
              <InputLabel htmlFor="nombre" sx={{ marginRight: 2 }}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Agregar
                </SoftTypography>
              </InputLabel>
              <SoftInput
                label="Nombre"
                name="nombre"
                value={raza.nombre}
                onChange={(e) => setRaza({ ...raza, nombre: e.target.value })}
                type="text"
                sx={{ flex: 1, marginLeft: 2, marginRight: 2, maxWidth: 550 }}
              />
              <SoftButton
                variant="gradient"
                color="primary"
                onClick={handleSubmit}
                sx={{ minWidth: 120 }} 
              >
                {!cargando ? "GUARDAR" : <CircularProgress sx={{ margin: "0 15px" }} size={18} color="white" />}
              </SoftButton>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <InputLabel htmlFor="razaSeleccionada" sx={{ marginRight: 2 }}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Eliminar
                </SoftTypography>
              </InputLabel>
              <Select
                value={razaSeleccionada}
                onChange={handleRazaChange}
                displayEmpty
                fullWidth
                sx={{ flex: 1, marginLeft: 2,marginRight: 2, maxWidth: 550 }} 
              >
                <MenuItem value="" disabled>
                  Selecciona una Raza
                </MenuItem>
                {razas.map((raz) => (
                  <MenuItem key={raz.id} value={raz.id}>
                    {raz.nombre}
                  </MenuItem>
                ))}
              </Select>
              <SoftButton
                variant="gradient"
                color="error"
                onClick={handleDeleteRaza}
                sx={{ minWidth: 120 }} 
              >
                Eliminar
              </SoftButton>
            </Grid>
          </Grid>
          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={2} pt={3}>
            <SoftBox>
              <SoftButton variant="gradient" color="dark" onClick={handleCloseCancel} sx={{ minWidth: 120 }}>
                Cancelar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNewRaza.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  especieId: PropTypes.number,
  refetchRazas: PropTypes.any
};
