/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import dayjs from "dayjs";

const getRows = (data, handleSelect, idSucursal) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        tipo: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item.tipo} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: item?.tipo === "Transferencia" ?  getUproColor("sistemasNegro") : getUproColor("sistemasAmarillo"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipo === "Transferencia" ? "import_export" : "autorenew"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox>
              <Tooltip title={item.estado === "Aceptado" ? "Nuevo" : item.estado} placement="top">
              <Icon
                    sx={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color:
                          item?.estado === "Aceptado"
                          ? getUproColor("sistemasAmarillo")
                          : item?.estado === "En curso"
                          ? getUproColor("sistemasNegro")
                          : getUproColor("sistemasGris"),
                    }}
                    fontSize="lg"
                  >
                    {item?.estado === "Aceptado"
                      ? "new_releases"
                      : item?.estado === "En curso"
                      ? "schedule_send"
                      : "done_all"}
                  </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.id}
            </SoftTypography>
          </SoftBox>
        ),
        sucursal: (
            <SoftBox
              sx={{
                cursor: "pointer",
              }}
              onClick={() => handleSelect(item)}
            >
              <SoftTypography
                verticalAlign="middle"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {item.sucursal.nombre + " - " + item.sucursal.sede.nombre}
              </SoftTypography>
            </SoftBox>
          ),
        cantidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.tipo === "Transferencia"
                ? item?.solicitudDetalles.filter((detalle) => detalle.cantidad > 0).length +
                  " productos"
                : item?.solicitudDetalles.length + " productos"}
            </SoftTypography>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.username}
            </SoftTypography>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {dayjs(item?.createdAt).format("DD/MM/YYYY")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <Tooltip title="Ver detalles" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={() => handleSelect(item)}
              >
                arrow_forward
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "tipo", desc: " ", align: "left", width: "1%", noOrder: true},
  { name: "estado", desc: "Estado", align: "center", width: "1%" },
  { name: "id", desc: "Código", align: "center", orderField: "id" },
  { name: "sucursal", desc: "Sucursal", align: "center", orderField: "idSucursal" },
  { name: "cantidad", desc: "Productos", align: "center", noOrder: true },
  { name: "user", desc: "Creado por", align: "left", orderField: "idUser" },
  { name: "fecha", desc: "Fecha", align: "left", orderField: "createdAt" },
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };
