import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Icon, Table as MuiTable, Tooltip, Menu, MenuItem } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function DataTableFrc({ columns, rows, onEdit, onDelete }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const openMenu = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderColumns = columns.map(({ id, label, align, width }, key) => {
    return (
      <TableCell
        key={id}
        width={width || "auto"}
        align={align} // Alineación consistente
        pt={1.5}
        pb={1.25}
        sx={{
          fontSize: size.xs, // Tamaño de fuente
          fontWeight: fontWeightBold,
          color: "secondary",
          opacity: 0.7,
          borderBottom: `${borderWidth[1]} solid ${light.main}`,
        }}
      >
        <SoftTypography variant="p" fontWeight="bold" color="dark">
          {label ? label.toUpperCase() : ""}
        </SoftTypography>
      </TableCell>
    );
  });

  const renderRows = rows.map((row, key) => {
    return (
      <TableRow key={`row-${key}`} hover>
        {columns.map(({ id, align }) => (
          <TableCell key={uuidv4()} align={align}>
            <SoftTypography variant="button" color="secondary" fontSize={size.xs}>
              {row[id] !== undefined ? row[id] : ""}
            </SoftTypography>
          </TableCell>
        ))}
        <TableCell align="center">
          <Tooltip title="Acciones" arrow>
            <Icon onClick={(event) => handleClick(event, row)} style={{ cursor: 'pointer' }}>
              more_vert
            </Icon>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  });

  return useMemo(
    () => (
      <>
        <TableContainer>
          <MuiTable>
            <SoftBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SoftBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>

        <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
          <MenuItem onClick={() => { navigate('/form-frc'); handleClose(); }}>
            <AddIcon style={{ marginRight: '8px' }} /> Agregar Formulario RC
          </MenuItem>
          <MenuItem onClick={() => { onEdit(selectedRow); handleClose(); }}>
            <EditIcon style={{ marginRight: '8px' }} /> Editar
          </MenuItem>
          <MenuItem onClick={() => { onDelete(selectedRow.id); handleClose(); }}>
            <DeleteIcon style={{ marginRight: '8px' }} /> Eliminar
          </MenuItem>
        </Menu>
      </>
    ),
    [columns, rows, anchorEl, selectedRow, navigate]
  );
}

DataTableFrc.defaultProps = {
  columns: [],
  rows: [],
  onEdit: () => {},
  onDelete: () => {},
};

DataTableFrc.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    align: PropTypes.string,
    width: PropTypes.string,
  })),
  rows: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DataTableFrc;
