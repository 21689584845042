import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Table as MuiTable, Tooltip, Menu, MenuItem } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CheckIcon from '@mui/icons-material/Check';
import DescriptionIcon from '@mui/icons-material/Description';
import ModalEditProyecto from "layouts/proyectos/ModalEdit";  // Componente Modal para editar el proyecto

const DELETE_PROYECTO = gql`
  mutation deleteProyecto($id: ID!) {
    deleteProyecto(id: $id) {
      id
    }
  }
`;

function DataTableProyect({ columns, rows, refetch }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalEditOpen, setModalEditOpen] = useState(false);

  const openMenu = Boolean(anchorEl);

  const [deleteProyecto] = useMutation(DELETE_PROYECTO, {
    onCompleted: () => {
      if (refetch) {
        refetch(); // Actualiza la lista de proyectos al eliminar uno
      }
    },
    onError: (error) => {
      console.error("Error al eliminar proyecto:", error);
    },
  });

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    if (selectedRow) {
      setModalEditOpen(true); 
      handleClose();
    }
  };

  const handleCloseModal = () => {
    setModalEditOpen(false);
    setSelectedRow(null); 
    
    if (refetch) {
      refetch(); // Recarga los proyectos después de editar
    }
  };

  const renderColumns = columns.map(({ name, desc, align, width }) => (
    <TableCell
      key={name}
      width={width || "auto"}
      align={align}
      pt={1.5}
      pb={1.25}
      sx={{
        fontSize: typography.size.xxs,
        fontWeight: typography.fontWeightBold,
        color: "secondary",
        opacity: 0.7,
        borderBottom: `${borders.borderWidth[1]} solid ${colors.light.main}`,
      }}
    >
      <SoftTypography variant="p" fontWeight="bold" color="dark">
        {desc ? desc.toUpperCase() : (name ? name.toUpperCase() : '')}
      </SoftTypography>
    </TableCell>
  ));

  const renderRows = rows.map((row) => (
    <TableRow key={row.id} hover>
      {columns.map(({ name, align, render }) => (
        <TableCell key={name} align={align}>
          <SoftTypography variant="button" color="secondary">
            {render ? render(row) : row[name] !== undefined ? row[name] : ""}
          </SoftTypography>
        </TableCell>
      ))}
      <TableCell align="center">
        <Tooltip title="Acciones" arrow>
          <Icon onClick={(event) => handleClick(event, row)} style={{ cursor: "pointer" }}>
            more_vert
          </Icon>
        </Tooltip>
      </TableCell>
    </TableRow>
  ));

  return useMemo(
    () => (
      <>
        <TableContainer>
          <MuiTable>
            <SoftBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SoftBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>

        <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
          <MenuItem onClick={handleEditClick} disabled={!selectedRow}>
            <EditIcon style={{ marginRight: '8px' }} /> Editar
          </MenuItem>
          <MenuItem onClick={() => { deleteProyecto({ variables: { id: selectedRow?.id } }); handleClose(); }} disabled={!selectedRow}>
            <DeleteIcon style={{ marginRight: '8px' }} /> Eliminar
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (selectedRow && selectedRow.id) {
                navigate(`/proyectos/Formulario_Registro_Clinico/${selectedRow.id}`);
              }
              handleClose();
            }}
            disabled={!selectedRow}
          >
            <AddIcon style={{ marginRight: '8px' }} /> Ver Formularios
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (selectedRow && selectedRow.id) {
                navigate(`/proyectos/form-frc/${selectedRow.id}`);
              }
              handleClose();
            }}
            disabled={!selectedRow}
          >
            <AddIcon style={{ marginRight: '8px' }} /> Agregar Formulario RC
          </MenuItem>
          <MenuItem onClick={() => { /* lógica para agregar planilla */ handleClose(); }}>
            <ListAltIcon style={{ marginRight: '8px' }} /> Agregar Planilla Recuento
          </MenuItem>
          <MenuItem onClick={() => { /* lógica para consentimiento */ handleClose(); }}>
            <CheckIcon style={{ marginRight: '8px' }} /> Consentimiento
          </MenuItem>
          <MenuItem onClick={() => { /* lógica para contrato */ handleClose(); }}>
            <DescriptionIcon style={{ marginRight: '8px' }} /> Contrato
          </MenuItem>
        </Menu>

        {/* Modal para editar el proyecto */}
        {modalEditOpen && selectedRow && (
          <ModalEditProyecto
            open={modalEditOpen}
            onClose={handleCloseModal}
            onEdit={handleEditClick}
            proyectoId={selectedRow.id} 
            
          />
        )}
      </>
    ),
    [columns, rows, anchorEl, selectedRow, openMenu, modalEditOpen, refetch, navigate]
  );
}

DataTableProyect.defaultProps = {
  columns: [],
  rows: [],
};

DataTableProyect.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    desc: PropTypes.string,
    align: PropTypes.string,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  refetch: PropTypes.func.isRequired, // Asegúrate de recibir refetch
};

export default DataTableProyect;
