import PropTypes from "prop-types";
import { Grid, InputLabel, MenuItem, Select, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ModalNewEspecie from "./components/ModalEspecie";

// Mutaciones y consultas
const SAVE_FRC_FORM = gql`
  mutation saveFrcForm($input: FrcFormInput!) {
    saveFrcForm(input: $input) {
      id
      
    }
  }
`;

const GET_ESPECIES = gql`
  query GetEspecies {
    especies {
      id
      nombre
      tipo
    }
  }
`;

export default function FormularioParasitos({ proyecto = {}, setProyecto }) {
  const [saveFrcForm] = useMutation(SAVE_FRC_FORM);
  const [openModal, setOpenModal] = useState(false);
  const { data: especiesData, loading: loadingEspecies, error: errorEspecies, refetch } = useQuery(GET_ESPECIES, {
    variables: { tipo: "ENDOP" }
  });

  const especies = especiesData?.especies.filter(especie => especie.tipo === "ENDOP") || [];

  const formatDate = (date) => {
    if (!date) return null;
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString();
  };

  // Efectos y lógica
  // useEffect(() => {
  //   if (Object.keys(proyecto).length === 0) {
  //     setProyecto((prev) => ({
  //       ...prev,
  //       pulgas: "",
  //       garrapatas: "",
  //       valorHPG: 20,
  //       intensidadPulgas: 0,
  //       intensidadGarrapatas: 0,
  //       endoparasitos: [],
  //       prueba: false,
  //       referencia: false,
  //     }));
  //   }
  // }, [setProyecto]);

  // useEffect(() => {
  //   if (proyecto.pulgas !== undefined || proyecto.garrapatas !== undefined) {
  //     const newIntensityPulgas = proyecto.pulgas > 20 ? 3 : proyecto.pulgas > 5 ? 2 : proyecto.pulgas > 0 ? 1 : 0;
  //     const newIntensityGarrapatas = proyecto.garrapatas > 10 ? 3 : proyecto.garrapatas > 3 ? 2 : proyecto.garrapatas > 0 ? 1 : 0;

  //     setProyecto((prev) => ({
  //       ...prev,
  //       intensidadPulgas: newIntensityPulgas,
  //       intensidadGarrapatas: newIntensityGarrapatas,
  //     }));
  //   }
  // }, [proyecto.pulgas, proyecto.garrapatas]);

  const handleFieldChange = (field) => {
    setProyecto((prev) => ({
      ...prev,
      endo_prueba: field === 'prueba',
      endo_referencia: field === 'referencia',
    }));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSelectEspecie = (especie) => {
    setProyecto((prev) => ({
      ...prev,
      endo_idEspecie: especie.id, 
    }));
    refetch();
    setOpenModal(false); 
  };

  const handleSave = async () => {
    const input = {
      profesional: proyecto.profesional || '',
      propietario: proyecto.propietario || '',
      contacto: proyecto.contacto || '',
      nombre: proyecto.nombre || '',
      pelaje: proyecto.pelaje || '',
      idEspecie: proyecto.idEspecie || null,
      idRaza: proyecto.idRaza || null,
      edad: proyecto.edad || null,
      sexo: proyecto.sexo || '',
      idProvincia: proyecto.idProvincia || null,
      idLocalidad: proyecto.idLocalidad || null,
      ecto_nroPulgas: Number(proyecto.pulgas) || 0,
      ecto_nroGarrapatas: Number(proyecto.garrapatas) || 0,
      endo_idEspecie: proyecto.endo_idEspecie || null,
      endo_hpg: Number(proyecto.valorHPG) || 20,
      endo_formulacionRecibida: proyecto.formulacionRecibida || '',
      endo_fechaTratamiento: formatDate(proyecto.fechaTratamiento),
      endo_fechaTratamiento1: formatDate(proyecto.extraFecha1),
      endo_fechaTratamiento2: formatDate(proyecto.extraFecha2),
      endo_tipo: proyecto.endo_tipo || '',
      endo_dosis: proyecto.endo_dosis || '',
      eog_peso: proyecto.eog_peso || null,
      eog_mucosas: proyecto.eog_mucosas || '',
      eog_linfodulos: proyecto.eog_linfodulos || '',
      eog_temperatura: proyecto.eog_temperatura || null,
      eog_sensorio: proyecto.eog_sensorio || '',
      eog_frecuenciaRespiratoria: proyecto.eog_frecuenciaRespiratoria || null,
      eog_frecuenciaCardiaca: proyecto.eog_frecuenciaCardiaca || null,
      eog_hidratacion: proyecto.eog_hidratacion || '',
      eog_consumo: proyecto.eog_consumo || '',
      eog_celo: proyecto.eog_celo || '',
      eog_fechaInicio: formatDate(proyecto.eog_fechaInicio),
      eog_fechaAlta: formatDate(proyecto.eog_fechaAlta),
      eog_obs: proyecto.eog_obs || '',
      mr_medicacionRecibida: proyecto.mr_medicacionRecibida || '',
      mr_antecedentes: proyecto.mr_antecedentes || '',
      mr_signoDermatitis: proyecto.mr_signoDermatitis || '',
      rea_trataConcomitante: proyecto.rea_trataConcomitante || '',
      rea_altaClinica: proyecto.rea_altaClinica || '',
      rea_observaciones: proyecto.rea_observaciones || '',
    };

    console.log("Datos a guardar:", input);

    try {
      const { data } = await saveFrcForm({ variables: { input } });
      console.log("Formulario guardado:", data.saveFrcForm);
      await refetch();
    } catch (error) {
      console.error("Error al guardar el formulario:", error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) => {
          console.error(message);
        });
      }
      alert("Error al guardar los datos, por favor intente nuevamente.");
    }
  };

  const valorHPG = proyecto.valorHPG !== undefined ? proyecto.valorHPG : 20;

  return (
    <SoftBox>
      <SoftTypography variant="h6" fontWeight="bold">Formulario de Parásitos</SoftTypography>
      <Grid container spacing={2} alignItems={"flex-end"}>
        {/* Ectoparasitos */}
        <Grid item xs={12}  sx={{mt:2}}>
          <SoftTypography variant="h6">ECTOPARÁSITOS</SoftTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="ecto_nroPulgas">
                <SoftTypography component="label" variant="caption" fontWeight="bold">Pulgas *</SoftTypography>
              </InputLabel>
              <SoftInput
                id="ecto_nroPulgas"
                type="number"
                value={proyecto?.ecto_nroPulgas || ""}
                onChange={(e) => {
                  let value = "" ;
                  let intensidad = 0;
                  if(e.target.value != ""){
                    value = Math.max(0, Number(e.target.value));
                    intensidad = e.target.value > 20 ? 3 : e.target.value > 5 ? 2 : e.target.value > 0 ? 1 : 0;
                  }
                  setProyecto((prev) => ({ 
                    ...prev, 
                    ecto_nroPulgas: value,
                    ecto_intensidadPulgas: intensidad, 
                  }));
                }}
                placeholder="Ingrese la cantidad de pulgas"
              />
              <InputLabel htmlFor="ecto_intensidadPulgas">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Intensidad de Infestación Pulgas
                </SoftTypography>
              </InputLabel>
              <Select
                id="ecto_intensidadPulgas"
                value={proyecto?.ecto_intensidadPulgas || 0}
                readOnly
                style={{ fontSize: '0.875rem' }}
              >
                <MenuItem value={0}>0 (Ninguna)</MenuItem>
                <MenuItem value={2}>2 (Moderada) 6-20</MenuItem>
                <MenuItem value={3}>3 (Alta) +20</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="ecto_nroGarrapatas">
                <SoftTypography component="label" variant="caption" fontWeight="bold">Garrapatas *</SoftTypography>
              </InputLabel>
              <SoftInput
                id="ecto_nroGarrapatas"
                type="number"
                value={proyecto?.ecto_nroGarrapatas || ""}
                onChange={(e) => {
                  let value = "" ;
                  let intensidad = 0;
                  if(e.target.value != ""){
                    value = Math.max(0, Number(e.target.value));
                    intensidad = e.target.value > 10 ? 3 : e.target.value > 3 ? 2 : e.target.value > 0 ? 1 : 0;
                  }
                  setProyecto((prev) => ({ 
                    ...prev, 
                    ecto_nroGarrapatas: value,
                    ecto_intensidadGarrapatas: intensidad, 
                  }));
                }}
                placeholder="Ingrese la cantidad de garrapatas"
              />
              <InputLabel htmlFor="ecto_intensidadGarrapatas">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Intensidad de Infestación
                </SoftTypography>
              </InputLabel>
              <Select
                id="ecto_intensidadGarrapatas"
                value={proyecto?.ecto_intensidadGarrapatas || 0}
                readOnly
                style={{ fontSize: '0.875rem' }}
              >
                <MenuItem value={0}>0 (Ninguna)</MenuItem>
                <MenuItem value={2}>2 (Moderada) 4-10</MenuItem>
                <MenuItem value={3}>3 (Alta) +10</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>

        {/* Endoparasitos */}
        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <SoftTypography variant="h6">ENDOPARÁSITOS</SoftTypography>
          <InputLabel htmlFor="endo_idEspecie">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Especies *
            </SoftTypography>
          </InputLabel>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={10}>
              <Select
                id="endo_idEspecie"
                value={proyecto?.endo_idEspecie || ""}
                onChange={(e) => setProyecto({ ...proyecto, endo_idEspecie: e.target.value })}
                placeholder="Seleccionar especie"
              >
                {loadingEspecies && <MenuItem value="">Cargando...</MenuItem>}
                {errorEspecies && <MenuItem value="">Error al cargar</MenuItem>}
                {especies.map((especie) => (
                  <MenuItem key={especie.id} value={especie.id}>
                    {especie.nombre}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <SoftButton variant="outlined" color="primary" onClick={handleOpenModal} sx={{ width: '100%' }}>
                Agregar
              </SoftButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Valor HPG */}
        <Grid item xs={12} sm={3} >
          <InputLabel htmlFor="endo_hpg">
            <SoftTypography component="label" variant="caption" fontWeight="bold">Valor de HPG *</SoftTypography>
          </InputLabel>
          <SoftInput
            id="endo_hpg"
            type="number"
            value={proyecto?.endo_hpg || 20}
            onChange={(e) => {
              const value = Math.max(20, Number(e.target.value));
              setProyecto({ ...proyecto, endo_hpg: value });
            }}
            required
            placeholder="Ingrese el valor de HPG"
          />
        </Grid>
        {/* Formulación recibida */}
        <Grid item xs={12} sm={3} >
          <InputLabel htmlFor="endo_formulacionRecibida">
            <SoftTypography component="label" variant="caption" fontWeight="bold">Formulación Recibida *</SoftTypography>
          </InputLabel>
          <SoftInput
            id="endo_formulacionRecibida"
            type="text"
            value={proyecto?.endo_formulacionRecibida || ''}
            onChange={(e) => setProyecto({ ...proyecto, endo_formulacionRecibida: e.target.value })}
            placeholder="Nombre genérico, lote y vto"
          />
        </Grid>

        {/* Fechas de tratamiento */}
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="endo_fechaTratamiento">
            <SoftTypography component="label" variant="caption" fontWeight="bold">Fecha de Tratamiento *</SoftTypography>
          </InputLabel>
          <SoftInput
            id="endo_fechaTratamiento"
            type="date"
            value={proyecto?.endo_fechaTratamiento || ''}
            onChange={(e) => {
              const newDate = e.target.value;
              setProyecto({ ...proyecto, endo_fechaTratamiento: newDate });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="endo_fechaTratamiento1">
            <SoftTypography component="label" variant="caption" fontWeight="bold">Fecha de Tratamiento Extra 1</SoftTypography>
          </InputLabel>
          <SoftInput
            id="endo_fechaTratamiento1"
            type="date"
            value={proyecto?.endo_fechaTratamiento1 || ''}
            onChange={(e) => {
              const newDate = e.target.value;
              if (proyecto.endo_fechaTratamiento && newDate > proyecto.endo_fechaTratamiento) {
                setProyecto({ ...proyecto, endo_fechaTratamiento1: newDate });
              }
            }}
            disabled={!proyecto.endo_fechaTratamiento}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="endo_fechaTratamiento2">
            <SoftTypography component="label" variant="caption" fontWeight="bold">Fecha de Tratamiento Extra 2</SoftTypography>
          </InputLabel>
          <SoftInput
            id="endo_fechaTratamiento2"
            type="date"
            value={proyecto?.endo_fechaTratamiento2 || ''}
            onChange={(e) => {
              const newDate = e.target.value;
              if (proyecto?.endo_fechaTratamiento1 && newDate > proyecto?.endo_fechaTratamiento1) {
                setProyecto({ ...proyecto, endo_fechaTratamiento2: newDate });
              }
            }}
            disabled={!proyecto?.endo_fechaTratamiento1}
          />
        </Grid>

        {/* Checkboxes */}
        <Grid item xs={12}>
          <SoftBox display="flex" alignItems="center" gap={2} >
            <FormControlLabel
              sx={{ml:0}}
              control={
                <Checkbox
                  checked={proyecto?.endo_prueba ?? false}
                  onChange={() => handleFieldChange('prueba')}
                />
              }
              label="Prueba"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={proyecto?.endo_referencia ?? false}
                  onChange={() => handleFieldChange('referencia')}
                />
              }
              label="Referencia"
            />
          </SoftBox>
        </Grid>

        {/* <Grid item xs={12}>
          <SoftButton variant="contained" color="primary" onClick={handleSave}>
            Guardar
          </SoftButton>
        </Grid> */}
      </Grid>
      <ModalNewEspecie open={openModal} handleClose={handleCloseModal} handleCloseCancel={handleCloseModal} handleSelectEspecie={handleSelectEspecie} refetch={refetch} />
      </SoftBox>
  );
}

FormularioParasitos.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
};
