/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const tipoDocumento = (tipo)=>{
  if(tipo === 96){
    return "DNI";
  }else if(tipo === 80){
    return "CUIT";
  }else if(tipo === 86){
    return "CUIL";
  }
}

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
    //   let sedes = [];
    //   item.sucursales.forEach((sucursal) => {
    //     if (!sedes.includes(sucursal.sede)) {
    //       sedes.push(sucursal.sede);
    //     }
    //   });
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("sistemasGris"),
                }}
                fontSize="lg"
              >
                person
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Usuario"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasGris"),
                  }}
                  fontSize="lg"
                >
                  person
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombreCompleto: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.nombre
                ? makeNiceText(item?.nombre + " " + item?.apellido, "nombre")
                : item?.username
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nombre
                ? makeNiceText(item?.nombre + " " + item?.apellido, "nombre")
                : "Sin nombre"}
            </SoftTypography>
          </SoftBox>
        ),
        documento: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.tipoDocumento + ": " + item?.documento}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {tipoDocumento(item?.tipoDocumento)} {item?.documento}
            </SoftTypography>
          </SoftBox>
        ),
        domicilio: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.domicilio}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.domicilio}
            </SoftTypography>
          </SoftBox>
        ),
        correo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.correo}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.correo}
            </SoftTypography>
          </SoftBox>
        ),
        telefono: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.telefono}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.telefono}
            </SoftTypography>
          </SoftBox>
        ),
        // sede: (
        //   <SoftBox
        //     sx={{
        //       cursor: "pointer",
        //     }}
        //     onClick={(e) => handleOpenMenu(e, item)}
        //     data={sedes.map((sede, index) => {
        //       return sede.nombre + (index < sedes.length - 1 ? ", " : "");
        //     })}
        //   >
        //     <SoftTypography
        //       verticalAlign="text-top"
        //       variant="caption"
        //       color="secondary"
        //       fontWeight="medium"
        //     >
        //       {sedes.map((sede, index) => {
        //         return sede.nombre + (index < sedes.length - 1 ? ", " : "");
        //       })}
        //     </SoftTypography>
        //   </SoftBox>
        // ),
        // email: (
        //   <SoftBox
        //     sx={{
        //       cursor: "pointer",
        //     }}
        //     onClick={(e) => handleOpenMenu(e, item)}
        //   >
        //     <SoftTypography
        //       verticalAlign="text-top"
        //       variant="caption"
        //       color="dark"
        //       fontWeight="medium"
        //     >
        //       {item?.email}
        //     </SoftTypography>
        //   </SoftBox>
        // ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombreCompleto", desc: "Nombre y Apellido", align: "left" },
  { name: "documento", desc: "Documento", align: "left" },
  { name: "domicilio", desc: "Domicilio", align: "left" },
  { name: "correo", desc: "Email", align: "left" },
  { name: "telefono", desc: "Telefono", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
  //   { name: "sede", desc: "Sede", align: "left", noOrder: true},
];

export default { columns, getRows };
